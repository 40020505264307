import { Alert, Snackbar, Grow, AlertTitle } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { sendAlert } from "../actions/alertActions/alertActions";

export default function AlertProvider(props) {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alertReducer.alert);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(sendAlert(null));
  };

  return (
    <>
      {props.children}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={!!alert}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Grow in={!!alert} style={{ transformOrigin: "0 0 0" }}>
          {/* Make sure to apply the style and ref props to the first child */}
          <div style={{ transformOrigin: "0 0 0" }} ref={props.forwardedRef}>
            {alert?.type === "success" && (
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                <AlertTitle>Éxito</AlertTitle>
                {alert?.message}
              </Alert>
            )}
            {alert?.type === "warning" && (
              <Alert
                onClose={handleClose}
                severity="warning"
                sx={{ width: "100%" }}
              >
                <AlertTitle>Cuidado</AlertTitle>
                {alert?.message}
              </Alert>
            )}
            {alert?.type === "error" && (
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                <AlertTitle>Error</AlertTitle>
                {alert?.message}
              </Alert>
            )}
          </div>
        </Grow>
      </Snackbar>
    </>
  );
}
