import { useDispatch, useSelector } from "react-redux";
import {
  removeCartItem,
  emptyCart,
  addCartSuccess,
  addCartReset,
} from "../actions/cartActions/cartActions";
import { sendAlert } from "../actions/alertActions/alertActions";
import { useEffect } from "react";

const useCart = () => {
  const dispatch = useDispatch();
  const cartFail = useSelector((state) => state.cartReducer.addCartFail);
  const removeFromCart = (advertisementNumber) => {
    dispatch(removeCartItem(advertisementNumber));
    dispatch(
      sendAlert({
        type: "success",
        message: "Se eliminado el funko del carrito",
      })
    );
  };

  const addToCart = (advertisementNumber) => {
    dispatch(addCartSuccess(advertisementNumber));
    dispatch(
      sendAlert({
        type: "success",
        message: "Funko añadido",
      })
    );

  };

  const clearCart = () => {
    dispatch(emptyCart());
    dispatch(
      sendAlert({
        type: "success",
        message: "Carrito vaciado!",
      })
    );
  };

  useEffect(() => {
    if (cartFail) {
      dispatch(
        sendAlert({
          type: "warning",
          message:
            "Este funko ya existe en su carrito o pertenece a otro vendedor",
        })
      );
    }

    return () => dispatch(addCartReset());
  }, [cartFail, dispatch]);

  return {
    addToCart,
    removeFromCart,
    clearCart,
  };
};

export default useCart;
