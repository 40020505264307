import { types } from "../../types/types";

export const latestReleasesRequest = () => {
    return {
      type: types.latestReleasesRequest,
    };
  };
  
  export const latestReleasesSuccess = (latestReleases) => {
    return {
      type: types.latestReleasesSuccess,
      payload: {
        latestReleases,
      },
    };
  };
  
  export const latestReleasesFailure = (error) => {
    return {
      type: types.llatestReleasesFailure,
      payload: {
        error,
      },
    };
  };