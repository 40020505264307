import { types } from "../../types/types";

export const topSalesRequest = () => {
    return {
      type: types.topSalesRequest,
    };
  };
  
  export const topSalesSuccess = (topSales) => {
    return {
      type: types.topSalesSuccess,
      payload: {
        topSales,
      },
    };
  };
  
  export const topSalesFailure = (error) => {
    return {
      type: types.topSalesFailure,
      payload: {
        error,
      },
    };
  };