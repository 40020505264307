import axios from "axios";
import { sendAlert } from "../actions/alertActions/alertActions";
import {
  loginFailure,
  loginRequest,
  loginSuccess,
  signUpFailure,
  signUpRequest,
  signUpSuccess,
} from "../actions/authActions/authActions";
import { baseURL } from "../constants/api";

export const login = async (user, password, dispatch, navigate) => {
  const data = `user=${user}&password=${btoa(password)}`;
  dispatch(loginRequest());
  try {
    const response = await axios.post(`${baseURL}/login`, data);
    sessionStorage.setItem("currentUser", JSON.stringify(response.data));
    dispatch(loginSuccess(response.data));
    dispatch(
      sendAlert({
        type: "success",
        message: "¡Login correcto!",
      })
    );
    navigate("/", {
      replace: true,
    });
  } catch (error) {
    dispatch(loginFailure(error.message));
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
  }
};

export const signUp = async (data, dispatch, navigate) => {
  dispatch(signUpRequest());
  try {
    const response = await axios.post(`${baseURL}/accounts/new`, data);    
    dispatch(signUpSuccess(response.data));
    dispatch(
      sendAlert({
        type: "success",
        message: "Registro correcto!",
      })
    );
    navigate("/", {
      replace: true,
    });
  } catch (error) {
    dispatch(signUpFailure(error.message));
    if(error.response.data === 'USER_EXIST'){
      dispatch(
        sendAlert({
          type: "error",
          message: 'El usuario a está registrado',
        })
      );
      
    }else{
      dispatch(
        sendAlert({
          type: "error",
          message: error.message,
        })
      );

    }
  }
};


export const forgotPassword = async (email, dispatch, navigate) => {
  try {
    await axios.get(`${baseURL}/password/${email}`);
    dispatch(
      sendAlert({
        type: "success",
        message: "¡Se ha enviado correo de recuperación!",
      })
    );
    navigate("/login", {
      replace: true,
    });
  } catch (error) {
    dispatch(
      sendAlert({
        type: "error",
        message: 'Correo incorrecto',
      })
    );
  }
};

export const changeforgotPassword = async (password, code, dispatch, navigate) => {
  try {
    await axios.get(`${baseURL}/password/${password}/${code}`);
    dispatch(
      sendAlert({
        type: "success",
        message: "¡Constraseña cambiada, ingrese de nuevo con su cuenta!",
      })
    );
    navigate("/login", {
      replace: true,
    });
  } catch (error) {
    dispatch(
      sendAlert({
        type: "error",
        message: 'Algo ha ido mal, pongase en contacto con el soporte o intentelo de nuevo mas tarde',
      })
    );
  }
};