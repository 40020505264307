import { types } from "../../types/types";

export const requestFunkoReducer = (state = {}, action) => {
    switch (action.type) {
      case types.requestFunkoSuccess:
        return {
          ...action.payload,
        };
      case types.requestFunkoFailure:
        return {
          ...action.payload,
        };
  
      default:
        return state;
    }
  };
  