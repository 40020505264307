import React from "react";
import { Provider } from "react-redux";
import { store } from "./store/store";
import AppRouter from "./routers/AppRouter";
import AlertProvider from "./providers/AlertProvider";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";


const App = () => {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <AlertProvider>
          <AppRouter />
        </AlertProvider>
      </ErrorBoundary>
    </Provider>
  );
};

export default App;
