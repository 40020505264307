import axios from "axios";

import { baseURL } from "../constants/api";

export const getVerify = async (code) => {
  try {
    await axios.get(`${baseURL}/accounts/activate/${code}`);
    //return response.data;
  } catch (error) {
    //return error ;
  }
};
