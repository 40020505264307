import axios from "axios";
import {
  publicProfileAdsFailure,
  publicProfileAdsRequest,
  publicProfileAdsSuccess,
  publicProfileFailure,
  publicProfileRequest,
  publicProfileSuccess,
} from "../actions/publicProfileActions/publicProfileActions";

import { baseURL } from "../constants/api";
// import axiosInstance from "../interceptors/axiosInstance";
import { sendAlert } from "../actions/alertActions/alertActions";

export const getpublicProfile = (user) => async (dispatch) => {
  dispatch(publicProfileRequest());
  try {
    const response = await axios.get(`${baseURL}/accounts/${user}`);
    dispatch(publicProfileSuccess(response.data));
    //return response.data;
  } catch (error) {
    dispatch(publicProfileFailure(error.message));
    //return error ;
  }
};

export const getPublicProfileAds = (userName) => async (dispatch) => {
  dispatch(publicProfileAdsRequest());

  try {
    const response = await axios.get(`${baseURL}/ad/${userName}/all`);
    dispatch(publicProfileAdsSuccess(response.data));
  } catch (error) {
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
    dispatch(publicProfileAdsFailure(error.message));
  }
};
