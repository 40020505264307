export const types = {
  alert: "[alert] - Alert Send",

  loginRequest: "[auth] - Login Request",
  loginSuccess: "[auth] - Login Success",
  loginFailure: "[auth] - Login Failure",
  logout: "[auth] - Logout",

  signUpRequest: "[auth] - signUp Request",
  signUpSuccess: "[auth] - signUp Success",
  signUpFailure: "[auth] - signUp Failure",

  latestReleasesRequest: "[latestReleases] - LatestReleases Request",
  latestReleasesSuccess: "[latestReleases] - LatestReleases Success",
  latestReleasesFailure: "[latestReleases] - LatestReleases Failure",

  topSalesRequest: "[topSales] - TopSales Request",
  topSalesSuccess: "[topSales] - TopSales Success",
  topSalesFailure: "[topSales] - TopSales Failure",

  topSearchRequest: "[topSearch] - TopSearch Request",
  topSearchSuccess: "[topSearch] - TopSearch Success",
  topSearchFailure: "[topSearch] - TopSearch Failure",

  funkoDetailRequest: "[funkoDetail] - FunkoDetail Request",
  funkoDetailSuccess: "[funkoDetail] - FunkoDetail Success",
  funkoDetailFailure: "[funkoDetail] - FunkoDetail Failure",

  funkosOnSaleRequest: "[funkosOnSale] - FunkosOnSale Request",
  funkosOnSaleSuccess: "[funkosOnSale] - FunkosOnSale Success",
  funkosOnSaleFailure: "[funkosOnSale] - FunkosOnSale Failure",

  helpRequest: "[help] - Help Request",
  helpSuccess: "[help] - Help Success",
  helpFailure: "[help] - Help Failure",

  profileInfoRequest: "[myAccount] - ProfileInfo Request",
  profileInfoSuccess: "[myAccount] - ProfileInfo Success",
  profileInfoClear: "[myAccount] - ProfileInfo Clear",
  profileInfoFailure: "[myAccount] - ProfileInfo Failure",

  profileUpdateRequest: "[myAccount] - ProfileUpdate Request",
  profileUpdateSuccess: "[myAccount] - ProfileUpdate Success",
  profileUpdateFailure: "[myAccount] - ProfileUpdate Failure",

  profileUpdatePasswordRequest: "[myAccount] - ProfilePasswordUpdate Request",
  profileUpdatePasswordSuccess: "[myAccount] - ProfilePasswordUpdate Success",
  profileUpdatePasswordFailure: "[myAccount] - ProfilePasswordUpdate Failure",

  profileHistoryRequest: "[myAccount] - ProfileHistory Request",
  profileHistorySuccess: "[myAccount] - ProfileHistory Success",
  profileHistoryFailure: "[myAccount] - ProfileHistory Failure",

  profileNotificationsRequest: "[myAccount] - ProfileNotifications Request",
  profileNotificationsSuccess: "[myAccount] - ProfileNotifications Success",
  profileNotificationsFailure: "[myAccount] - ProfileNotifications Failure",

  profileSearchAdsRequest: "[myAccount] - ProfileSearchAds Request",
  profileSearchAdsSuccess: "[myAccount] - ProfileSearchAds Success",
  profileSearchAdsClear: "[myAccount] - ProfileSearchAds Clear",
  profileSearchAdsFailure: "[myAccount] - ProfileSearchAds Failure",

  profileGetFunkoByCodeRequest: "[myAccount] - profileGetFunkoByCode Request",
  profileGetFunkoByCodeSuccess: "[myAccount] - profileGetFunkoByCode Success",
  profileGetFunkoByCodeFailure: "[myAccount] - profileGetFunkoByCode Failure",

  profileAdsRequest: "[myAccount] - ProfileAds Request",
  profileAdsSuccess: "[myAccount] - ProfileAds Success",
  profileAdsFailure: "[myAccount] - ProfileAds Failure",

  profilePublishAdsRequest: "[myAccount] - ProfilePublishAds Request",
  profilePublishAdsSuccess: "[myAccount] - ProfilePublishAds Success",
  profilePublishAdsFailure: "[myAccount] - ProfilePublishAds Failure",

  profileUpdateAdsRequest: "[myAccount] - ProfileUpdateAds Request",
  profileUpdateAdsSuccess: "[myAccount] - ProfileUpdateAds Success",
  profileUpdateAdsFailure: "[myAccount] - ProfileUpdateAds Failure",

  profileDeleteAdsRequest: "[myAccount] - ProfileDeleteAds Request",
  profileDeleteAdsSuccess: "[myAccount] - ProfileDeleteAds Success",
  profileDeleteAdsFailure: "[myAccount] - ProfileDeleteAds Failure",

  profileTransactionProcessRequest:
    "[myAccount] - ProfileTransactionProcess Request",
  profileTransactionProcessSuccess:
    "[myAccount] - ProfileTransactionProcess Success",
  profileTransactionProcessFailure:
    "[myAccount] - ProfileTransactionProcess Failure",

  profileUpdateImgRequest: "[myAccount] - ProfileUpdateImg Request",
  profileUpdateImgSuccess: "[myAccount] - ProfileUpdateImg Success",
  profileUpdateImgFailure: "[myAccount] - ProfileUpdateImg Failure",

  profileWalletRequest: "[myAccount] - ProfileWallet Request",
  profileWalletSuccess: "[myAccount] - ProfileWallet Success",
  profileWalletFailure: "[myAccount] - ProfileWallet Failure",

  profileActivateStripeRequest: "[myAccount] - ProfileActivateStripe Request",
  profileActivateStripeSuccess: "[myAccount] - ProfileActivateStripe Success",
  profileActivateStripeFailure: "[myAccount] - ProfileActivateStripe Failure",

  profileDepositStripeRequest: "[myAccount] - ProfileDepositStripe Request",
  profileDepositStripeSuccess: "[myAccount] - ProfileDepositStripe Success",
  profileDepositStripeFailure: "[myAccount] - ProfileDepositStripe Failure",

  profileDepositStripeCheckRequest: "[myAccount] - ProfileDepositStripeCheck Request",
  profileDepositStripeCheckSuccess: "[myAccount] - ProfileDepositStripeCheck Success",
  profileDepositStripeCheckFailure: "[myAccount] - ProfileDepositStripeCheck Failure",

  profileWithdrawStripeRequest: "[myAccount] - ProfileWithdrawStripe Request",
  profileWithdrawStripeSuccess: "[myAccount] - ProfileWithdrawStripe Success",
  profileWithdrawStripeFailure: "[myAccount] - ProfileWithdrawStripe Failure",

  requestFunkoRequest: "[requestFunko] - RequestFunko Request",
  requestFunkoSuccess: "[requestFunko] - RequestFunko Success",
  requestFunkoFailure: "[requestFunko] - RequestFunko Failure",
  
  addCartRequest: "[cart] - addCart Request",
  addCartSuccess: "[cart] - addCart Success",
  addCartReset: "[cart] - addCart Reset",
  addCartFailure: "[cart] - addCart Failure",
  removeCartItem: '[cart] - REMOVE_CART_ITEM',
  emptyCart: '[cart] - EMPTY_CART',

  publicProfileRequest: "[publicProfile] - PublicProfile Request",
  publicProfileSuccess: "[publicProfile] - PublicProfile Success",
  publicProfileFailure: "[publicProfile] - PublicProfile Failure",

  publicProfileAdsRequest: "[publicProfle] - PublicProfileAds Request",
  publicProfileAdsSuccess: "[publicProfle] - PublicProfileAds Success",
  publicProfileAdsFailure: "[publicProfle] - PublicProfileAds Failure",

  advancedSearchRequest: "[advancedSearch] - AdvancedSearch Request",
  advancedSearchSuccess: "[advancedSearch] - AdvancedSearch Success",
  advancedSearchFailure: "[advancedSearch] - AdvancedSearch Failure",
  advancedSearchReset: "[advancedSearch] - AdvancedSearch Reset",

  advancedSearchTotalRequest: "[advancedSearchTotal] - AdvancedSearchTotal Request",
  advancedSearchTotalSuccess: "[advancedSearchTotal] - AdvancedSearchTotal Success",
  advancedSearchTotalReset: "[advancedSearchTotal] - AdvancedSearchTotal Reset",
  advancedSearchTotalFailure: "[advancedSearchTotal] - AdvancedSearchTotal Failure",

};
