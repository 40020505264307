import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import "./styles/styles.scss";



const theme = createTheme({
  palette: {
    primary: {
      main: '#F6B036',
    },
    secondary: {
      main: '#11cb5f',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        '&:focus': {
          outlineColor: '#F6B036'
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  // <React>
  //   <ThemeProvider theme={theme}>
  //     <App />
  //   </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
