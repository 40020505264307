/* eslint-disable no-unused-vars */
import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import verifyImg from "../assets/logos/VerifyLogo.png";
import paymentImg from "../assets/logos/secure-payment.png";
import InfoIcon from "@mui/icons-material/Info";
import { imgUrl } from "../constants/api";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useCart from "../hooks/useCart";
import { useEffect } from "react";
import MondialRelay from "../components/auth/Common/MondialRelay";
import { getWalletInfo } from "../services/myAccountService";
import {
  payCreditHome,
  payCreditPickUp,
  payStripeHome,
  payStripePickUp,
} from "../services/cartService";

const Cart = () => {
  const cartItems = useSelector((state) => state.cartReducer.cartItems);
  const quantity = useSelector(
    (state) => state.myAccountReducer?.profileWallet?.quantity
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [verifiedPrice, setVerifiedPrice] = useState(0);
  const [shippmentPrice, setShippmentPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [checkedItems, setCheckedItems] = useState({});
  const [validatorCount, setValidatorCount] = useState(0);
  const [shippingAddressRadioButton, setShippingAddressRadioButton] =
    useState("home");
  const [showShippingAddressBlock, setShowShippingAddressBlock] =
    useState(false);
  const [selectedDeliveryCode, setSelectedDeliveryCode] = useState(null);
  const { removeFromCart, clearCart } = useCart();

  useEffect(() => {
    if (!quantity) {
      dispatch(getWalletInfo());
    }
    if (cartItems && cartItems.length > 0) {
      if (cartItems.length / 5 <= 1) {
        setShippmentPrice(4);
      } else if (cartItems.length / 5 >= 2) {
        setShippmentPrice(4.5);
      } else if (cartItems.length / 5 <= 3) {
        setShippmentPrice(4.5);
      } else if (cartItems.length / 5 <= 5) {
        setShippmentPrice(5.0);
      } else if (cartItems.length / 5 <= 7) {
        showShippingAddressBlock
          ? setShippmentPrice(7.5)
          : setShippmentPrice(6.0);
      } else if (cartItems.length / 5 <= 10) {
        showShippingAddressBlock
          ? setShippmentPrice(10.0)
          : setShippmentPrice(6.0);
      } else if (cartItems.length / 5 <= 15) {
        showShippingAddressBlock
          ? setShippmentPrice(14.5)
          : setShippmentPrice(8.0);
      } else if (cartItems.length / 5 <= 20) {
        setShippmentPrice(17.0);
      } else if (cartItems.length / 5 <= 30) {
        setShippmentPrice(24.0);
      }

      if (validatorCount >= 3) {
        setVerifiedPrice(17.99);
      } else if (validatorCount === 2) {
        setVerifiedPrice(13.99);
      } else if (validatorCount === 1) {
        setVerifiedPrice(9.99);
      } else if (validatorCount === 0) {
        setVerifiedPrice(0);
      }
    } else {
      setShippmentPrice(0);
    }
  }, [cartItems, dispatch, quantity, showShippingAddressBlock, validatorCount]);

  const handleCheckboxChange = (advertisementNumber, isChecked) => {
    setCheckedItems({ ...checkedItems, [advertisementNumber]: isChecked });
    if (isChecked) {
      setValidatorCount(validatorCount + 1);
    } else {
      setValidatorCount(validatorCount - 1);
    }
  };

  const handleRemove = (advertisementNumber) => {
    if (checkedItems[advertisementNumber]) {
      setValidatorCount(validatorCount - 1);
    }
    removeFromCart(advertisementNumber);
  };

  const handleShippingAddressRadioButton = (event) => {
    if (event.target.value === "home") {
      setShowShippingAddressBlock(true);
    } else {
      setShowShippingAddressBlock(false);
    }
    setShippingAddressRadioButton(event.target.value);
  };

  const handleRemoveAll = () => {
    clearCart();
    setShippmentPrice(0);
    setValidatorCount(0);
    setCheckedItems({});
    setVerifiedPrice(0);
  };

  const handleCheckout = () => {
    if (shippingAddressRadioButton === "home") {
      if (
        quantity >
        shippmentPrice + verifiedPrice + totalFunkosPrice(cartItems)
      ) {
        dispatch(payCreditHome(cartItems, navigate));
      } else {
        dispatch(
          payStripeHome(
            (
              shippmentPrice +
              verifiedPrice +
              totalFunkosPrice(cartItems) -
              quantity
            ).toFixed(2)
          )
        );
      }
    } else {
      if (
        quantity >
        shippmentPrice + verifiedPrice + totalFunkosPrice(cartItems)
      ) {
        dispatch(payCreditPickUp(cartItems, navigate, selectedDeliveryCode));
      } else {
        dispatch(
          payStripePickUp(
            (
              shippmentPrice +
              verifiedPrice +
              totalFunkosPrice(cartItems) -
              quantity
            ).toFixed(2),
            selectedDeliveryCode
          )
        );
      }
    }

  };

  const totalFunkosPrice = (array) => {
    return array?.reduce((accumulator, item) => {
      return accumulator + parseFloat(item.price);
    }, 0);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Box
          sx={{
            border: "2px solid #F6B036",
            borderRadius: "25px",
            p: 2,
            mx: 1,
            my: 2,
          }}
        >
          <Typography m={2} variant="h4" textAlign={"center"}>
            Carrito
          </Typography>
          <Divider />
          <Grid container spacing={2} mt={1}>
            {cartItems?.map((item) => (
              <Grid item xs={12} key={item.advertisementNumber}>
                <Card>
                  <CardContent>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={12} md={3}>
                        <img
                          src={`${imgUrl}/${item.funkoPhoto}`}
                          alt={item.funkoName}
                          width="100%"
                        />
                      </Grid>
                      <Grid item xs={12} md={3} sx={{ textAlign: "center" }}>
                        <Typography variant="h6">
                          {item.funkoName.toUpperCase()}
                        </Typography>
                        <Typography>
                          Vendedor:
                          <Link to={`/user/${item.userNickname}`}>
                            {" "}
                            {item.userNickname}
                          </Link>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Box sx={{ position: "relative", zIndex: 1 }}>
                            <img
                              src={verifyImg}
                              alt={item.funkoName}
                              width="70rem"
                            />
                          </Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={
                                  checkedItems[item.advertisementNumber] ||
                                  false
                                }
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    item.advertisementNumber,
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label={
                              <>
                                ¿Verificar?
                                <Tooltip title="Verifica tu funko" arrow>
                                  <IconButton
                                    edge="end"
                                    color="inherit"
                                    aria-label="Información"                                  
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Typography variant="h5" textAlign={"center"}>
                          {item.price} €
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2} sx={{ textAlign: "center" }}>
                        <Button
                          variant="contained"
                          size="large"
                          color="error"
                          onClick={() => handleRemove(item.advertisementNumber)}
                        >
                          Eliminar
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Button
            disabled={cartItems && cartItems.length <= 0}
            sx={{ marginTop: "1rem", width: "100%" }}
            variant="contained"
            color="error"
            onClick={handleRemoveAll}
          >
            Eliminar todos
          </Button>
          <Divider sx={{ marginY: "20px" }} />
          <Grid container spacing={2} mr={0}>
            {shippingAddressRadioButton === "pickup" && (
              <MondialRelay
                selectedDeliveryCode={selectedDeliveryCode}
                setSelectedDeliveryCode={setSelectedDeliveryCode}
              />
            )}
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            border: "2px solid #F6B036",
            borderRadius: "25px",
            p: 2,
            mx: 1,
            my: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <Box>
            <Typography variant="h4" textAlign={"center"} m={2}>
              Resumen
            </Typography>
            <Divider />
            <Typography
              variant="h6"
              mt={1}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Total funkos:
              <span className="ml-2" style={{ fontSize: "1.5rem" }}>
                {cartItems && cartItems.length > 0
                  ? totalFunkosPrice(cartItems).toFixed(2)
                  : 0.0}{" "}
                €
              </span>
            </Typography>
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Verificaciones:
              <span className="ml-2" style={{ fontSize: "1.5rem" }}>
                {verifiedPrice.toFixed(2)} €
              </span>
            </Typography>
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Gastos de envío:
              <span className="ml-2" style={{ fontSize: "1.5rem" }}>
                {shippmentPrice.toFixed(2)} €
              </span>
            </Typography>
            <Divider sx={{ marginY: "1rem" }} />
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Precio final:
              <span className="ml-2" style={{ fontSize: "1.5rem" }}>
                {(
                  shippmentPrice +
                  verifiedPrice +
                  totalFunkosPrice(cartItems)
                ).toFixed(2)}{" "}
                €
              </span>
            </Typography>
            <Divider sx={{ marginY: "1rem" }} />
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Cantidad en cuenta:
              <span className="ml-2" style={{ fontSize: "1.5rem" }}>
                {quantity ? quantity.toFixed(2) : 0.0} €
              </span>
            </Typography>
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Total restante:
              <span className="ml-2" style={{ fontSize: "1.5rem" }}>
                {(
                  shippmentPrice +
                  verifiedPrice +
                  totalFunkosPrice(cartItems) -
                  quantity
                ).toFixed(2)}{" "}
                €
              </span>
            </Typography>
          </Box>
          <FormControl component="fieldset">
            <Typography>Dirección de envío:</Typography>
            <RadioGroup
              aria-label="shipping"
              value={shippingAddressRadioButton}
              onChange={handleShippingAddressRadioButton}
            >
              <FormControlLabel
                value="home"
                control={<Radio />}
                label="A domicilio"
                disabled={cartItems && cartItems.length <= 0}
              />
              <FormControlLabel
                value="pickup"
                control={<Radio />}
                label={<><span>Punto de recogida</span><small> (Temporalmente fuera de servicio)</small></>}
                // disabled={cartItems && cartItems.length <= 0}
                disabled
              />
            </RadioGroup>
          </FormControl>
          <Button
            disabled={
              (cartItems && cartItems.length <= 0) ||
              (shippingAddressRadioButton === "pickup" && !selectedDeliveryCode)
            }
            variant="contained"
            color="primary"
            onClick={handleCheckout}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={paymentImg} alt="Pagar" width="70rem" />
            <span style={{ marginTop: "0.1rem" }}>Pagar</span>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Cart;
