import { useSelector } from "react-redux";

const useItemInCart = () => {
  const cartItems = useSelector((state) => state.cartReducer.cartItems);

  const isItemInCart = (advertisementNumber) => {
    return cartItems.some(
      (item) => item.advertisementNumber === advertisementNumber
    );
  };

  return {
    isItemInCart,
  };
};

export default useItemInCart;
