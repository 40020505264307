import React from "react";
import { BsHeartFill, BsHeartHalf } from "react-icons/bs";

const Health = ({ health }) => {
  let heart;
  if (health >= 100) {
    heart = (
      <>
        <span>
          <BsHeartFill className="health__icon" />
          <BsHeartFill className="health__icon" />
          <BsHeartFill className="health__icon" />
          <BsHeartFill className="health__icon" />
          <BsHeartFill className="health__icon" />
        </span>
      </>
    );
  } else if (health < 100 && health >= 75) {
    heart = (
      <>
        <span>
          <BsHeartFill className="health__icon" />
          <BsHeartFill className="health__icon" />
          <BsHeartFill className="health__icon" />
          <BsHeartFill className="health__icon" />
        </span>
      </>
    );
  } else if (health < 75 && health >= 50) {
    heart = (
      <>
        <span>
          <BsHeartFill className="health__icon" />
          <BsHeartFill className="health__icon" />
          <BsHeartHalf className="health__icon" />
        </span>
      </>
    );
  } else if (health < 50 && health >= 25) {
    heart = (
      <>
        <span>
          <BsHeartFill className="health__icon" />
          <BsHeartFill className="health__icon" />
        </span>
      </>
    );
  
  } else if (health < 25 && health >= 0) {
    heart = (
      <>
        <span>
          <BsHeartFill className="health__icon" />
        </span>
      </>
    );
  }

  return heart;
};

export default Health;
