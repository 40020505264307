import React from "react";
import { Navigate, useLocation } from "react-router-dom";

function PrivateRoute({ children }) {
  const isLogged = JSON.parse(sessionStorage.getItem("currentUser"))?.userName || false;
  const {pathname, search} = useLocation();
  localStorage.setItem('lastPath', pathname + search);
  return isLogged? children : <Navigate to="/login" />;
}

export default PrivateRoute;


