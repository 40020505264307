import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import {
  getProfileInfo,
  updateImgProfile,
  updateProfileInfo,
} from "../../services/myAccountService";
import ChangePasswordModal from "./ChangePasswordModal ";

const validationSchema = yup.object({
  username: yup.string().required("Nombre de usuario requerido"),
  email: yup
    .string()
    .email("Correo electrónico inválido")
    .required("Correo electrónico requerido"),
  fullName: yup.string().required("Nombre completo requerido"),
  phone: yup.string().required("Teléfono requerido"),
  address1: yup.string().required("Dirección requerida"),
  city1: yup.string().required("Ciudad requerida"),
  province1: yup.string().required("Provincia requerida"),
  country1: yup.string().required("País requerido"),
  postalCode1: yup.string().required("Código postal requerido"),
});

const UserProfile = () => {
  const profileInfo = useSelector(
    (state) => state.myAccountReducer.profileInfo
  );
  const profileUpdateSuccess = useSelector(
    (state) => state.myAccountReducer.profileUpdateSuccess
  );
  const profileUpdateImgSuccess = useSelector(
    (state) => state.myAccountReducer.profileUpdateImgSuccess
  );
  const dispatch = useDispatch();
  const [sameAddress, setSameAddress] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const [timestamp, setTimestamp] = useState(Date.now());

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (!profileInfo) {
      dispatch(getProfileInfo());
    }
  }, [profileInfo, dispatch, profileUpdateSuccess, profileUpdateImgSuccess]);

  useEffect(() => {
    setTimestamp(Date.now());
  }, [profileUpdateImgSuccess]);

  const formik = useFormik({
    initialValues: {
      username: profileInfo?.userName || "",
      email: profileInfo?.email || "",
      fullName: profileInfo?.completeName || "",
      phone: profileInfo?.phoneNumber || "",
      address1: profileInfo?.shippingAddress?.address || "",
      extraAddress1: profileInfo?.shippingAddress?.complementaryAddress || "",
      city1: profileInfo?.shippingAddress?.city || "",
      province1: profileInfo?.shippingAddress?.province || "",
      country1: profileInfo?.shippingAddress?.country || "",
      postalCode1: profileInfo?.shippingAddress?.postalCode || "",
      address2: profileInfo?.billingAddress?.address || "",
      extraAddress2: profileInfo?.billingAddress?.complementaryAddress || "",
      city2: profileInfo?.billingAddress?.city || "",
      province2: profileInfo?.billingAddress?.province || "",
      country2: profileInfo?.billingAddress?.country || "",
      postalCode2: profileInfo?.billingAddress?.postalCode || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Implementar la acción de guardado de datos
      const updateValues = {
        userName: values.username,
        email: values.email,
        phoneNumber: values.phone,
        completeName: values.fullName,
        billingEqualsShipping: sameAddress,
        shippingAddress: {
          address: values.address1,
          complementaryAddress: values.extraAddress1,
          city: values.city1,
          province: values.province1,
          country: values.country1,
          postalCode: values.postalCode1,
        },
        billingAddress: {
          address: values.address2,
          complementaryAddress: values.extraAddress2,
          city: values.city2,
          province: values.province2,
          country: values.country2,
          postalCode: values.postalCode2,
        },
      };
      dispatch(updateProfileInfo(updateValues));
    },
  });

  const handleFormChange = (event) => {
    formik.handleChange(event);
    setIsModified(true);
  };

  useEffect(() => {
    if (profileInfo) {
      handleSameAddressChange({
        target: { checked: profileInfo.billingEqualsShipping },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileInfo?.billingEqualsShipping]);

  useEffect(() => {
    if (profileInfo) {
      formik.setValues({
        username: profileInfo?.userName || "",
        email: profileInfo?.email || "",
        fullName: profileInfo?.completeName || "",
        phone: profileInfo?.phoneNumber || "",
        address1: profileInfo?.shippingAddress?.address || "",
        extraAddress1: profileInfo?.shippingAddress?.complementaryAddress || "",
        city1: profileInfo?.shippingAddress?.city || "",
        province1: profileInfo?.shippingAddress?.province || "",
        country1: profileInfo?.shippingAddress?.country || "",
        postalCode1: profileInfo?.shippingAddress?.postalCode || "",
        address2: profileInfo?.billingAddress?.address || "",
        extraAddress2: profileInfo?.billingAddress?.complementaryAddress || "",
        city2: profileInfo?.billingAddress?.city || "",
        province2: profileInfo?.billingAddress?.province || "",
        country2: profileInfo?.billingAddress?.country || "",
        postalCode2: profileInfo?.billingAddress?.postalCode || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileInfo]);

  const handleSameAddressChange = (event) => {
    setSameAddress(event.target.checked);
    if (event.target.checked) {
      formik.setValues({
        ...formik.values,
        address2: formik.values.address1,
        extraAddress2: formik.values.extraAddress1,
        city2: formik.values.city1,
        province2: formik.values.province1,
        country2: formik.values.country1,
        postalCode2: formik.values.postalCode1,
      });
    } else {
      formik.setValues({
        ...formik.values,
        address2: "",
        extraAddress2: "",
        city2: "",
        province2: "",
        country2: "",
        postalCode2: "",
      });
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Image = reader.result;
        const data = { imgProfile: base64Image.split("base64,")[1] };
        dispatch(updateImgProfile(data));
        dispatch(getProfileInfo());
        setPreviewImage(base64Image);
      };
      reader.readAsDataURL(file);
    }
  };

  if (!profileInfo) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          "& .MuiTextField-root": {
            m: 1,
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Avatar
                alt="User Avatar"
                src={
                  previewImage ||
                  `https://www.verypops.com//pictures/profiles/${profileInfo.userName}.png?t=${timestamp}`
                }
                sx={{
                  width: "20em",
                  height: "20em",
                  "@media (max-width:1024px)": {
                    width: "250px",
                    height: "250px",
                  },
                  "@media (max-width:900px)": {
                    width: "200px",
                    height: "200px",
                  },
                }}
              />
              <label htmlFor="icon-button-file">
                <input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  hidden
                  onChange={handleImageChange}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <PhotoCamera />
                </IconButton>
              </label>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Datos personales</Typography>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                disabled
                fullWidth
                id="username"
                name="username"
                label="Nombre de usuario"
                value={formik.values.username}
                onChange={handleFormChange}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />
              <TextField
                disabled
                fullWidth
                id="email"
                name="email"
                label="Correo electrónico"
                value={formik.values.email}
                onChange={handleFormChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                disabled
                fullWidth
                id="fullName"
                name="fullName"
                label="Nombre completo"
                value={formik.values.fullName}
                onChange={handleFormChange}
                error={
                  formik.touched.fullName && Boolean(formik.errors.fullName)
                }
                helperText={formik.touched.fullName && formik.errors.fullName}
              />
              <TextField
                fullWidth
                id="phone"
                name="phone"
                label="Teléfono"
                value={formik.values.phone}
                onChange={handleFormChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </form>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Datos de envío</Typography>
            <FormGroup>
              <TextField
                fullWidth
                id="address1"
                name="address1"
                label="Dirección"
                value={formik.values.address1}
                onChange={handleFormChange}
                error={
                  formik.touched.address1 && Boolean(formik.errors.address1)
                }
                helperText={formik.touched.address1 && formik.errors.address1}
              />
              <TextField
                fullWidth
                id="extraAddress1"
                name="extraAddress1"
                label="Datos complementarios dirección"
                value={formik.values.extraAddress1}
                onChange={handleFormChange}
              />
              <TextField
                fullWidth
                id="city1"
                name="city1"
                label="Ciudad"
                value={formik.values.city1}
                onChange={handleFormChange}
                error={formik.touched.city1 && Boolean(formik.errors.city1)}
                helperText={formik.touched.city1 && formik.errors.city1}
              />
              <TextField
                fullWidth
                id="province1"
                name="province1"
                label="Provincia"
                value={formik.values.province1}
                onChange={handleFormChange}
                error={
                  formik.touched.province1 && Boolean(formik.errors.province1)
                }
                helperText={formik.touched.province1 && formik.errors.province1}
              />
              <TextField
                fullWidth
                id="country1"
                name="country1"
                label="País"
                value={formik.values.country1}
                onChange={handleFormChange}
                error={
                  formik.touched.country1 && Boolean(formik.errors.country1)
                }
                helperText={formik.touched.country1 && formik.errors.country1}
              />
              <TextField
                fullWidth
                id="postalCode1"
                name="postalCode1"
                label="Código postal"
                value={formik.values.postalCode1}
                onChange={handleFormChange}
                error={
                  formik.touched.postalCode1 &&
                  Boolean(formik.errors.postalCode1)
                }
                helperText={
                  formik.touched.postalCode1 && formik.errors.postalCode1
                }
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Datos de facturación</Typography>

            <FormGroup>
              <TextField
                fullWidth
                id="address2"
                name="address2"
                label="Dirección"
                value={formik.values.address2}
                onChange={handleFormChange}
                disabled={sameAddress}
                error={
                  formik.touched.address2 && Boolean(formik.errors.address2)
                }
                helperText={formik.touched.address2 && formik.errors.address2}
              />
              <TextField
                fullWidth
                id="extraAddress2"
                name="extraAddress2"
                label="Datos complementarios dirección"
                value={formik.values.extraAddress2}
                onChange={handleFormChange}
                disabled={sameAddress}
              />
              <TextField
                fullWidth
                id="city2"
                name="city2"
                label="Ciudad"
                value={formik.values.city2}
                onChange={handleFormChange}
                disabled={sameAddress}
                error={formik.touched.city2 && Boolean(formik.errors.city2)}
                helperText={formik.touched.city2 && formik.errors.city2}
              />
              <TextField
                fullWidth
                id="province2"
                name="province2"
                label="Provincia"
                value={formik.values.province2}
                onChange={handleFormChange}
                disabled={sameAddress}
                error={
                  formik.touched.province2 && Boolean(formik.errors.province2)
                }
                helperText={formik.touched.province2 && formik.errors.province2}
              />
              <TextField
                fullWidth
                id="country2"
                name="country2"
                label="País"
                value={formik.values.country2}
                onChange={handleFormChange}
                disabled={sameAddress}
                error={
                  formik.touched.country2 && Boolean(formik.errors.country2)
                }
                helperText={formik.touched.country2 && formik.errors.country2}
              />
              <TextField
                fullWidth
                id="postalCode2"
                name="postalCode2"
                label="Código postal"
                value={formik.values.postalCode2}
                onChange={handleFormChange}
                disabled={sameAddress}
                error={
                  formik.touched.postalCode2 &&
                  Boolean(formik.errors.postalCode2)
                }
                helperText={
                  formik.touched.postalCode2 && formik.errors.postalCode2
                }
              />
            </FormGroup>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sameAddress}
                    onChange={handleSameAddressChange}
                    name="sameAddress"
                    color="primary"
                  />
                }
                label="Usar los mismos datos de envío para facturación"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              disabled={!isModified}
              color="primary"
              variant="contained"
              onClick={formik.handleSubmit}
            >
              Guardar
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button color="secondary" variant="contained" onClick={handleOpen}>
              Cambiar contraseña
            </Button>
          </Grid>
        </Grid>
        <ChangePasswordModal open={modalOpen} handleClose={handleClose} />
      </Box>
    );
  }
};

export default UserProfile;
