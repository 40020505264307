import { types } from "../../types/types";

export const publicProfileRequest = () => {
    return {
      type: types.publicProfileRequest,
    };
  };
  
  export const publicProfileSuccess = (publicProfile) => {
    return {
      type: types.publicProfileSuccess,
      payload: {
        publicProfile,
      },
    };
  };
  
  export const publicProfileFailure = (error) => {
    return {
      type: types.publicProfileFailure,
      payload: {
        error,
      },
    };
  };

  export const publicProfileAdsRequest = () => {
    return {
      type: types.publicProfileAdsRequest,
    };
  };
  
  export const publicProfileAdsSuccess = (publicProfileAds) => {
    return {
      type: types.publicProfileAdsSuccess,
      payload: {
        publicProfileAds,
      },
    };
  };
  
  export const publicProfileAdsFailure = (error) => {
    return {
      type: types.publicProfileAdsFailure,
      payload: {
        error,
      },
    };
  };