import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { imgUrl } from "../constants/api";
import { getFunkoDetail } from "../services/funkoDetailService";
import { BsWhatsapp, BsFacebook, BsFillInfoCircleFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import TableSmall from "../components/auth/Common/TableSmall";
import { getTopSales } from "../services/topSalesService";
import { getTopSearch } from "../services/topSearchService";
import CustomTable from "../components/auth/Common/CustomTable";
import { getFunkosOnSale } from "../services/funkosOnSaleService";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { CardMedia } from "@mui/material";
const FunkoDetail = () => {
  const funko = useSelector((state) => state?.funkoDetailReducer?.funkoDetail);
  const funkosOnSale = useSelector(
    (state) => state?.funkosOnSaleReducer?.funkosOnSale
  );
  const funkosTopSales = useSelector(
    (state) => state?.topSalesReducer?.topSales
  );
  const funkosTopSearch = useSelector(
    (state) => state?.topSearchReducer?.topSearch
  );

  //const { img } = funko;
  const { id } = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [imageAd, setImageAd] = useState("");

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  let columns = [];
  let rows = [];

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Precio medio",
        data: labels,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      // {
      //   label: "Dataset 2",
      //   data: labels,
      //   backgroundColor: "rgba(53, 162, 235, 0.5)",
      // },
    ],
  };

  useEffect(() => {
    getFunkoDetail(id, dispatch);
    getFunkosOnSale(id, dispatch);
    if (
      !funkosTopSales ||
      !funkosTopSearch ||
      funkosTopSales?.lengh <= 0 ||
      funkosTopSearch?.lengh <= 0
    ) {
      getTopSales(dispatch);
      getTopSearch(dispatch);
    }
  }, [dispatch, funkosTopSales, funkosTopSearch, id]);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  if (funkosOnSale && funkosOnSale?.length > 0) {
    columns = [
      { id: "userNickname", label: "USUARIO" },
      { id: "health", label: "SALUD" },
      { id: "description", label: "DESCRIPCIÓN" },
      { id: "publishDate", label: "PUBLICADO" },
      { id: "price", label: "PRECIO" },
    ];

    rows = funkosOnSale.map((f, index) => ({
      ...f,
      id: index,
      price: f.price.toFixed(2),
      // userNickname: f.userNickname,
      // health: f.health,
      // description: f.description,
      // publishDate: f.publishDate,
      // images: f.images,
    }));
  }

  const getCollapsibleContent = (row) => {
    return (
      <Grid container>
        <Grid item xs={12} sm={4} sx={{ whiteSpace: "break-spaces" }}>
          <List>
            <ListItem>
              <BsFillInfoCircleFill className="funkoDetail__icon-info mr-1" />
              <ListItemText primary="Información:" />
            </ListItem>
            <ListItem dense>
              <ListItemText primary={`Salud: ${row.health}`} />
            </ListItem>
            <ListItem dense>
              <ListItemText primary={`Descripción: ${row.description}`} />
            </ListItem>
            <ListItem dense>
              <ListItemText primary={`Publicado: ${row.publishDate}`} />
            </ListItem>
            <ListItem dense>
              <ListItemText primary={`Precio: ${row.price} €`} />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={8}>
          {row.images && row.images.length > 0 && (
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2} m={2}>
              {row.images.map((i,index) => (
                <CardMedia
                  key={index}
                  component="img"
                  style={{ objectFit: "contain" }}
                  alt=""
                  src={`${imgUrl}${i}`}
                  width={200}
                  height={200}
                  onClick={() => {
                    setImageAd(`${imgUrl}${i}`);
                    setOpen(true);
                  }}
                />
              ))}
            </Stack>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="container-fluid text-center">
      {funko && (
        <div className="row d-flex justify-content-center m-5">
          <div className="col-md-4 col-xs-4 funkoDetail__container">
            <div>
              <img
                className="funkoDetail__img"
                src={`${imgUrl}${funko.img}`}
                alt="funko"
              />
            </div>
            <div className="container text-center">
              <div className="row my-3">
                <div className="col-4">
                  <Link
                    data-action="share/whatsapp/share"
                    target="_blank"
                    to="https://wa.me/?text=https://www.verypops.com/funko/1000000081"
                    rel="noreferrer"
                  >
                    <div className="funkoDetail__social-whatsapp-container">
                      <BsWhatsapp
                        style={{ fontSize: "1.3rem", color: "#fff" }}
                      />
                    </div>
                  </Link>
                </div>
                <div className="col-4">
                  <Link
                    target="_blank"
                    to="https://www.facebook.com/sharer/sharer.php?u=https://www.verypops.com/funko/1000000081"
                    rel="noreferrer"
                  >
                    <div className="funkoDetail__social-facebook-container">
                      <BsFacebook
                        style={{ fontSize: "1.3rem", color: "#fff" }}
                      />
                    </div>
                  </Link>
                </div>
                <div className="col-4">
                  <Link
                    target="_blank"
                    to="mailto:?subject= &amp;body=https://www.verypops.com/funko/1000000081"
                    rel="noreferrer"
                  >
                    <div className="funkoDetail__social-mail-container">
                      <MdEmail style={{ fontSize: "1.3rem", color: "#fff" }} />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-xs-4 funkoDetail__container-information text-left">
            <div className="col-md-12 col-sm-11 container funkoInformation holder text-left">
              <div className="generalInfo">
                <p className="name text-overflow">
                  {funko?.name?.toUpperCase()}{" "}
                </p>
                {windowSize?.width <= 768 ? (
                  <>
                    <p className="text-overflow">
                      <span>
                        Nº
                        <span className="number">{funko?.number}</span>
                      </span>
                    </p>
                    <p className="text-overflow">
                      <span>
                        {" "}
                        LICENCIA:
                        <span className="licence">
                          {funko?.license.toUpperCase()}
                        </span>
                      </span>
                    </p>
                  </>
                ) : (
                  <>
                    <p className="text-overflow">
                      <span>
                        Nº
                        <span className="number">{funko?.number}</span>
                      </span>
                    </p>
                    <p className="text-overflow">
                      <span>
                        {" "}
                        LICENCIA:
                        <span className="licence">
                          {funko?.license.toUpperCase()}
                        </span>
                      </span>
                    </p>
                  </>
                )}
                <p className="text-overflow">
                  <span>
                    SERIE:{" "}
                    <span className="serie">
                      {funko?.series?.name?.toUpperCase()}
                    </span>
                  </span>
                </p>
                <p className="text-overflow">
                  <span>
                    EDICIÓN:{" "}
                    <span className="edition">
                      {funko?.edition?.toUpperCase()}
                    </span>
                  </span>
                </p>
              </div>
              <div className="container priceInformation">
                <div className="chartContainer" style={{maxHeight: '175px'}}>
                  <Bar options={options} data={data} />
                </div>
              </div>
              <div className="priceBox">
                <div className="priceNumber">
                  <span className="priceLabel">PRECIOS: </span>
                  <div className="avg">
                    <p className="label">MÍNIMO</p>
                    <p className="number">
                      {funko?.funkoExtendedInfo?.minPrice.toFixed(2)} €
                    </p>
                  </div>
                  <div className="min">
                    <p className="label">MEDIO</p>
                    <p className="number">
                      {funko?.funkoExtendedInfo?.avgPrice.toFixed(2)} €
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="delimiter my-5">
              <div className="delimiterRounder mt-2">
                <div className="vDivider"></div>
              </div>
              <div className="textBetweenDelimiter">
                <span>Funkos Disponibles</span>
              </div>
              <div className="delimiterRounder mt-2">
                <div className="vDivider"></div>
              </div>
            </div>
          </div>

          <div className="col-md-8 col-xs-4 funkoDetail__container-table ">
            {funkosOnSale && funkosOnSale.length > 0 ? (
              <CustomTable
                funkosOnSale={funkosOnSale}
                columns={columns}
                rows={rows}
                collapsibleContent={getCollapsibleContent}
              />
            ) : (
              <Typography className="my-5" color="primary">
                ¡Este funko pop aún no está en venta, se el primero en venderlo!
              </Typography>
            )}
          </div>
        </div>
      )}
      <div className="container">
        <div className="delimiter mt-5">
          <div className="delimiterRounder">
            <div className="vDivider"></div>
          </div>
          <div className="textBetweenDelimiter">
            <span>Los más populares</span>
          </div>
          <div className="delimiterRounder">
            <div className="vDivider"></div>
          </div>
        </div>
        <div className="container d-flex justify-content-center">
          <div className="row ">
            <div className="col">
              <TableSmall funkosTopSales={funkosTopSales} />
            </div>
            <div className="col">
              <TableSmall funkosTopSales={funkosTopSearch} />
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={handleClose} onBackdropClick={handleClose}>
        <img
          onClick={() => setOpen(false)}
          src={imageAd}
          alt=""
          style={{ maxWidth: "100%", maxHeight: "100vh" }}
        />
      </Modal>
    </div>
  );
};

export default FunkoDetail;
