import { types } from "../../types/types";

export const funkoDetailReducer = (state = {}, action) => {
    switch (action.type) {
      case types.funkoDetailSuccess:
        return {
          ...action.payload,
        };
      case types.funkoDetailFailure:
        return {
          ...action.payload,
        };
  
      default:
        return state;
    }
  };
  