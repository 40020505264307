import axios from "axios";
import {
  funkoDetailFailure,
  funkoDetailRequest,
  funkoDetailSuccess,
} from "../actions/funkoDetailActions/funkoDetailActions";
import { baseURL } from "../constants/api";

export const getFunkoDetail = async (id, dispatch) => {
  dispatch(funkoDetailRequest());
  try {
    const response = await axios.get(`${baseURL}/funko/code/${id}`);
    dispatch(funkoDetailSuccess(response.data));
    //return response.data;
  } catch (error) {
    dispatch(funkoDetailFailure(error.message));
    //return error ;
  }
};
