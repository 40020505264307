import { types } from "../../types/types";

const initialState = {
  cartItems: JSON.parse(localStorage.getItem("cartItems")) || [],
  addCartFail: null,
  addCartSuccess: null,
};

const isItemInCart = (cartItems, newItem) => {
  return cartItems.some(
    (item) =>
      (item.advertisementNumber === newItem.advertisementNumber &&
        item.userNickname === newItem.userNickname) ||
      item.userNickname !== newItem.userNickname
  );
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.addCartSuccess:
      if (isItemInCart(state.cartItems, action.payload)) {
        // Si el elemento ya está en el carrito, no lo agregamos
        return {
          ...state,
          addCartFail: true,
        };
      } else {
        // Si el elemento no está en el carrito, lo agregamos
        localStorage.setItem(
          "cartItems",
          JSON.stringify([...state.cartItems, action.payload])
        );
        return {
          ...state,
          cartItems: [...state.cartItems, action.payload],
          addCartSuccess: true,
        };
      }
    case types.addCartFailure:
      return {
        ...action.payload,
      };
    case types.addCartReset:
      // localStorage.removeItem("cartItems");
      return {
        ...state,
        addCartFail: null,
        addCartSuccess: null,
      };

    case types.removeCartItem:
      localStorage.setItem(
        "cartItems",
        JSON.stringify(
          state.cartItems.filter(
            (item) =>
              item.advertisementNumber !== action.payload.advertisementNumber
          )
        )
      );
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (item) =>
            item.advertisementNumber !== action.payload.advertisementNumber
        ),
      };
    case types.emptyCart:
      // localStorage.removeItem("cartItems");
      localStorage.setItem("cartItems", JSON.stringify([]));

      return {
        ...state,
        cartItems: [],
      };

    default:
      return state;
  }
};
