import React from "react";
import { BsInstagram, BsFacebook, BsTiktok } from "react-icons/bs";
import logo from "../../../assets/logos/logo-2.png";
import logoEU from "../../../assets/logos/Fondo_EU_Logo.jpg";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

export const Footer = () => {
  return (
    <footer className="footer mt-5">
      <Grid container spacing={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item xs={12} sm={4} md={3}>
          <div>
            <img src={logo} alt="logo" width={300} />
          </div>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <div>
            <ul>
              <li>
                <Link className="footer__anchor" to="/help/faqs">
                  Faqs
                </Link>
              </li>
              <li>
                <Link className="footer__anchor" to="mailto:info@verypops.com">
                  Contacta con nosotros
                </Link>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <div>
            <ul>
              <li>
                <Link
                  className="footer__anchor"
                  to="/help/Politica%20de%20privacidad"
                >
                  Política de privacidad
                </Link>
              </li>
              <li>
                <Link
                  className="footer__anchor"
                  to="/help/Terminos%20y%20condiciones"
                >
                  Terminos y condiciones
                </Link>
              </li>
              <li>
                <Link className="footer__anchor" to="/help/Ventas">
                  Politica de ventas
                </Link>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={6} sm={6} md={2}  display={'flex'} justifyContent={'center'}>
          <div>
            <Link to="/fondo-eu">
              <img src={logoEU} alt="logo" width={100} />
            </Link>
          </div>
        </Grid>
        <Grid item xs={6} sm={6} md={2}>
          <div>
            <Link to="https://www.instagram.com/verypops/">
              <BsInstagram className="footer__icons-social" />{" "}
            </Link>
            <Link to="https://www.facebook.com/verypopsEs">
              <BsFacebook className="footer__icons-social" />{" "}
            </Link>
            <Link to="https://www.tiktok.com/@verypopses">
              <BsTiktok className="footer__icons-social" />
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
        <Typography variant="p" color={'black'}> ©Verypops 2022 Todos los derechos reservados.</Typography>
      </Grid>
      </Grid>
    </footer>
  );
};
