import { types } from "../../types/types";

const initialState = {
  alert: null,
};

export const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.alert:
      return {
        ...state,
        alert: action.payload,
      };
    default:
      return state;
  }
};
