import axios from "axios";
import { topSearchFailure, topSearchRequest, topSearchSuccess } from "../actions/topSearchActions/topSearchActions";
import { baseURL } from "../constants/api";

export const getTopSearch = async (dispatch) => {
  dispatch(topSearchRequest());
  try {
    const response = await axios.get(`${baseURL}/cache/top10search`);
    dispatch(topSearchSuccess(response.data));
    //return response.data;
  } catch (error) {
    dispatch(topSearchFailure(error.message));
    //return error ;
  }
};
