/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Input,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import imgStripe from "../../assets/img/PAGO-SEGURO-STRIPE.png";
import styled from "@emotion/styled";
import {
  depositStripe,
  depositStripeCheck,
  getActivateStripe,
  getWalletInfo,
  withdrawStripe,
} from "../../services/myAccountService";

const CoinContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "90px",
  height: "90px",
  position: "relative",
}));

const CoinText = styled(Typography)(({ theme }) => ({
  position: "absolute",
  color: theme.palette.common.white,
  fontWeight: "bold",
  fontSize: "1.2rem",
}));

const DividerCoin = styled(Divider)(({ theme }) => ({
  background: "rgb(254, 215, 0)",
  borderWidth: "3px",
  borderStyle: "double",
  //margin: '10px'
}));

const Moneda = styled.div`
  width: 100px;
  height: 100px;
  background-color: #ffd700;
  border-radius: 50%;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;

  &::before {
    content: "";
    width: 50%;
    height: 50%;
    background: radial-gradient(
      circle at 50% 50%,
      #ffd700 0%,
      #f6b036 60%,
      #f6b036 100%
    );
    position: absolute;
    top: 25%;
    left: 25%;
    border-radius: 50%;
  }
`;

const WalletComponent = () => {
  const profileWallet = useSelector(
    (state) => state.myAccountReducer.profileWallet
  );
  const profileDepositStripeSuccess = useSelector(
    (state) => state.myAccountReducer.profileDepositStripeSuccess
  );
  const dispatch = useDispatch();
  const [rechargeValue, setRechargeValue] = useState(0);
  const [withdrawalValue, setWithdrawalValue] = useState(null);
  const [searchParams] = useSearchParams();
  // console.log("cartItems", JSON.stringify(cartItems));

  useEffect(() => {
    dispatch(getWalletInfo());
  }, [dispatch, profileDepositStripeSuccess]);

  // useEffect(() => {
  //   const isSCheckURL = searchParams.has("check");
  //   if (isSCheckURL) {
  //     dispatch(depositStripeCheck("complete"));
  //   }
  // }, [dispatch, searchParams]);

  // Stripe API Key
  // const stripeAPIKey =
  //   "pk_test_51LgPx8Cl3AMrjsVDZrbQjHkq43kqCa37u4MYSoAel0LA7qzIzpfaSPhfqDgP3cFYVlieT6e5a7NHUC1jTMoG7R5R00q5IzxxNe";

  // eslint-disable-next-line no-unused-vars
  const makePayment = () => {
    dispatch(depositStripe(rechargeValue * 100));
  };

  const withdrawal = () => {
    dispatch(
      withdrawStripe(withdrawalValue * 100, profileWallet.stripeAccount)
    );
  };

  const redirect = () => {
    dispatch(getActivateStripe());
  };

  return (
    <>
      <Grid container display={"flex"} alignItems={"center"} xs={12} md={12}>
        <Grid item xs={4}>
          <DividerCoin />
        </Grid>
        <Grid item xs={4}>
          <Box
            display="flex"
            flexDirection="column"
            mb={2}
            alignItems={"center"}
          >
            {/* <Typography variant="h6">Mi saldo</Typography> */}
            <CoinContainer>
              <Moneda />
              <CoinText>{profileWallet?.quantity}€</CoinText>
            </CoinContainer>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <DividerCoin />
        </Grid>
      </Grid>
      <Divider className="my-4" />
      <Box>
        <Grid container spacing={2}>
          {/* Recargar cuenta */}
          {/* <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h4">Recargar cuenta</Typography>
              <Typography variant="body2">
                1º Introduce la cantidad que quieras recargar en tu monedero.
                <br />
                2º Accede a la pasarela de pago Stripe pulsando recargar.
              </Typography>
              <FormControl className="mt-3">
                <InputLabel htmlFor="charge">Cantidad</InputLabel>
                <Input
                  id="charge"
                  type="number"
                  value={rechargeValue}
                  onChange={(e) => setRechargeValue(e.target.value)}
                />
              </FormControl>
              <Box marginTop={3}>
                <Button
                  variant="contained"
                  onClick={() => makePayment(rechargeValue)}
                >
                  Recargar
                </Button>
              </Box>
            </Box>
          </Grid> */}

          {/* Retirar */}
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h4">Retirar</Typography>
              <Typography variant="body2">
                1º Introduce la cantidad y asegurate detener activa tu cuenta en
                Stripe <br />
                2º Pulsa en retirar y será redirigido a la plataforma Stripe
                para pagos seguros.
              </Typography>
              <FormControl className="mt-3">
                <InputLabel htmlFor="withdrawal">Cantidad</InputLabel>
                <Input
                  id="withdrawal"
                  type="number"
                  value={withdrawalValue}
                  onChange={(e) => setWithdrawalValue(e.target.value)}
                />
              </FormControl>
              <Box marginTop={3}>
                <Button
                  disabled={
                    profileWallet?.quantity <= 0 ||
                    !profileWallet?.stripeAccount
                  }
                  variant="contained"
                  onClick={() => withdrawal()}
                >
                  Retirar
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Divider className="my-4" />
        <Grid container spacing={2}>
          {/* Recargar cuenta */}
          <Grid item xs={12} md={6}>
            <Box role="status">
              <Box>
                <Button
                  onClick={redirect}
                  variant="contained"
                  color="info"
                  disabled={
                    profileWallet &&
                    profileWallet?.stripeAccount &&
                    profileWallet?.stripeAccount.length > 0
                  }
                >
                  Activar cuenta stripe
                </Button>
              </Box>
              <Box mt={5}>
                <Typography variant="h4">¿Qué es Stripe?</Typography>
                <Typography variant="body2">
                  Stripe es una compañia tecnologica. <br />
                  Su software permite a individuos y negocios recibir pagos por
                  internet. <br />
                  Proporciona la infraestructura técnica, de prevención de
                  fraude y bancaria <br />
                  necesaria para operar sistemas de pago en linea.
                </Typography>
                <Link href="https://stripe.com/es" target="_blank">
                  ¿Quieres saber más? Entra en su web https://stripe.com
                </Link>
              </Box>
            </Box>
          </Grid>
          {/* Retirar */}
          <Grid item xs={12} md={6}>
            <Box>
              <img src={imgStripe} alt="" style={{ width: "100%" }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default WalletComponent;
