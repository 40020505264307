import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getHistory } from "../../services/myAccountService";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.black,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6b03669",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function History() {
  const profileHistory = useSelector(
    (state) => state?.myAccountReducer?.profileHistory
  );
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Reemplaza esto con datos reales de Redux
  useEffect(() => {
    if (!profileHistory) dispatch(getHistory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileHistory]);

  return (
    <Grid container spacing={isSmallScreen ? 2 : 4}>
      <Grid item xs={12} md={6}>
        <Box marginBottom={2}>
          <Typography variant="h5" textAlign={"center"}>
            Mis ventas
          </Typography>
          <Divider className="my-2" />
          {profileHistory?.sold && profileHistory?.sold.length > 0 ? (
            <TableContainer className="table-container" component={Paper} style={{ maxHeight: 400, overflow: 'auto' }}>
              <Table>
                <TableHead className="hide-mobile-thead">
                  <StyledTableRow>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      Código
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      Fecha
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      Estado
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      Precio
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      Verificado
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {profileHistory?.sold &&
                    profileHistory?.sold.length > 0 &&
                    profileHistory?.sold.map((s, index) => (
                      <StyledTableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          data-label="CÓDIGO:"
                          sx={{ textAlign: "center" }}
                          component="th"
                          scope="row"
                        >
                          <Box display="flex" alignItems="center">
                            <Link
                              to={`/transactions/${s.codeTransaction}.pdf`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Box
                                marginTop={1}
                                marginBottom={1}
                                display="flex"
                                alignItems="center"
                              >
                                <VisibilityIcon />
                              </Box>
                            </Link>
                            <Box
                              marginLeft={1}
                              display="flex"
                              alignItems="center"
                            >
                              {s.codeTransaction}
                            </Box>
                          </Box>
                        </TableCell>

                        <TableCell
                          data-label="FECHA:"
                          sx={{ textAlign: "center" }}
                          component="th"
                          scope="row"
                        >
                          {s.transactionDate}
                        </TableCell>
                        <TableCell
                          data-label="ESTADO:"
                          sx={{ textAlign: "center" }}
                          component="th"
                          scope="row"
                        >
                          {s.status === "ACCEPTED"
                            ? "ACEPTADO"
                            : s.status === "WAITING"
                            ? "PENDIENTE"
                            : s.status === "CANCELED"
                            ? "CANCELADO"
                            : "FINALIZADO"}
                        </TableCell>
                        <TableCell
                          data-label="PRECIO:"
                          sx={{ textAlign: "center" }}
                          component="th"
                          scope="row"
                        >
                          {s.price} €
                        </TableCell>
                        <TableCell
                          data-label="VERIFIADO:"
                          sx={{ textAlign: "center" }}
                          component="th"
                          scope="row"
                        >
                          {s.verificationPrice > 0 ? "SI" : "NO"}
                        </TableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Alert
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              icon={false}
              severity="warning"
            >
              {" "}
              No tiene ventas realizadas
            </Alert>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box marginBottom={2}>
          <Typography variant="h5" textAlign={"center"}>
            Mis Compras
          </Typography>
          <Divider className="my-2" />
          {profileHistory?.bought && profileHistory?.bought.length > 0 ? (
            <TableContainer className="table-container" component={Paper} style={{ maxHeight: 400, overflow: 'auto' }}>
              <Table>
                <TableHead className="hide-mobile-thead">
                  <StyledTableRow>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      Código
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      Fecha
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      Estado
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      Precio
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      Verificado
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {profileHistory?.bought &&
                    profileHistory?.bought.length > 0 &&
                    profileHistory?.bought.map((b, index) => (
                      <StyledTableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          data-label="CÓDIGO:"
                          sx={{ textAlign: "center" }}
                          component="th"
                          scope="row"
                        >
                          <Box display="flex" alignItems="center">
                            <Link
                              to={`/transactions/${b.codeTransaction}.pdf`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Box
                                marginTop={1}
                                marginBottom={1}
                                display="flex"
                                alignItems="center"
                              >
                                <VisibilityIcon />
                              </Box>
                            </Link>
                            <Box
                              marginLeft={1}
                              display="flex"
                              alignItems="center"
                            >
                              {b.codeTransaction}
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell
                          data-label="FECHA:"
                          sx={{ textAlign: "center" }}
                          component="th"
                          scope="row"
                        >
                          {b.transactionDate}
                        </TableCell>
                        <TableCell
                          data-label="ESTADO:"
                          sx={{ textAlign: "center" }}
                          component="th"
                          scope="row"
                        >
                          {b.status === "ACCEPTED"
                            ? "ACEPTADO"
                            : b.status === "WAITING"
                            ? "PENDIENTE"
                            : b.status === "CANCELED"
                            ? "CANCELADO"
                            : "FINALIZADO"}
                        </TableCell>
                        <TableCell
                          data-label="PRECIO:"
                          sx={{ textAlign: "center" }}
                          component="th"
                          scope="row"
                        >
                          {b.price} €
                        </TableCell>
                        <TableCell
                          data-label="VERIFIADO:"
                          sx={{ textAlign: "center" }}
                          component="th"
                          scope="row"
                        >
                          {b.verificationPrice > 0 ? "SI" : "NO"}
                        </TableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Alert
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              icon={false}
              severity="warning"
            >
              {" "}
              No tiene compras realizadas
            </Alert>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" textAlign={"center"}>
          Estadísticas
        </Typography>
        <Divider className="my-2" />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell sx={{ textAlign: "center" }}>
                  Total vendidos
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: "center" }}>
                  Total comprados
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow
                key="totales"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{ textAlign: "center" }}
                  component="th"
                  scope="row"
                >
                  {profileHistory?.totalSold}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center" }}
                  component="th"
                  scope="row"
                >
                  {profileHistory?.totalBought}
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default History;
