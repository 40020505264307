import { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para que el siguiente renderizado muestre la interfaz de usuario de "renderizado de error".
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Aquí puedes registrar el error o notificar al usuario de que se ha producido un error.
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      // Si se ha producido un error, muestra un mensaje de error o una pantalla de error personalizada.
      //return <h1>Lo siento, se ha producido un error.</h1>;
      return this.props.children;
    }

    // Si no se ha producido ningún error, renderiza el contenido del componente.
    return this.props.children;
  }
}

export default ErrorBoundary;
