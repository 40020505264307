import { emptyCart } from "../actions/cartActions/cartActions";
import axiosInstance from "../interceptors/axiosInstance";

export const payCreditHome = (funkos, navigate) => async (dispatch) => {
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;
  try {
    const response = await axiosInstance.post(
      `/transaction/${userName}/false`,
      funkos
    );
    if (response) {
      navigate("/checkout/payment-confirmation-page", {
        replace: true,
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const payCreditPickUp = (funkos, navigate, code) => async (dispatch) => {
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;
  try {
    const response = await axiosInstance.post(
      `/transaction/${userName}/false/${code}`,
      funkos
    );
    if (response) {
      navigate("/checkout/payment-confirmation-page", {
        replace: true,
      });
    }
  } catch (error) {
    console.error(error);
  }
};
export const payStripeHome = (amount) => async (dispatch) => {
  //const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;
  const email = JSON.parse(sessionStorage.getItem("currentUser")).email;

  const data = {
    email,
    amount: amount * 100,
  };
  try {
    const response = await axiosInstance.post(
      `/payment/pay`,
      data
    );
    localStorage.setItem("idSession", response.data.checkoutSessionId)
    localStorage.setItem("codeMonday", response.data.checkoutSessionId)
    window.location.assign(response.data.checkOutURL);

  } catch (error) {
    console.error(error);
  }
};

export const payStripePickUp = (amount, code) => async (dispatch) => {
  // const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;
  const email = JSON.parse(sessionStorage.getItem("currentUser")).email;

  const data = {
    email,
    amount: amount * 100,
  };
  try {
    const response = await axiosInstance.post(
      `/payment/pay`,
      data
    );
    localStorage.setItem("idSession", response.data.checkoutSessionId)
    localStorage.setItem("codeMonday", code)
    window.location.assign(response.data.checkOutURL);
  } catch (error) {
    console.error(error);
  }
};
export const checkoutWithApitrue = (funkos, code) => async (dispatch) => {
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;
  try {
    await axiosInstance.post(
      `/transaction/${userName}/true/${code}`,
      funkos
    );
    dispatch(emptyCart());
  } catch (error) {
    console.error(error);
  }
};
export const checkoutWithtrue = (funkos) => async (dispatch) => {
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;
  try {
    await axiosInstance.post(
      `/transaction/${userName}/true`,
      funkos
    );
    dispatch(emptyCart());
  } catch (error) {
    console.error(error);
  }
};

// export const payHome = (amount) => async (dispatch) => {
//     console.log("entra")
//     // const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;
//     const email = JSON.parse(sessionStorage.getItem("currentUser")).email;

//     const data = {
//         email,
//         amount: amount * 100,
//     };
//   //dispatch(profileWithdrawStripeRequest());
//   try {
//       const response = await axiosInstance.post(`/payment/pay`, data);
//       console.log("response", response)
//     localStorage.setItem("idSession", response.data.checkoutSessionId)
//     localStorage.setItem("codeMonday", response.data.checkoutSessionId)
//     //dispatch(profileWithdrawStripeSuccess(response.data));
//     // console.log(response.data);
//     window.open(response.data.checkOutURL);
//   } catch (error) {
//     console.error(error);
//     //  dispatch(
//     //    sendAlert({
//     //      type: "error",
//     //      message: error.message,
//     //    })
//     //  );
//     //  dispatch(profileWithdrawStripeFailure(error.message));
//   }
// };
