import { types } from "../../types/types";

export const publicProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case types.publicProfileSuccess:
      return {
        ...action.payload,
      };
    case types.publicProfileFailure:
      return {
        ...action.payload,
      };
    case types.publicProfileAdsSuccess:
      return {
        ...action.payload,
        ...state,
        publicProfileAdsSuccess: true,
      };
    case types.publicProfileAdsFailure:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
