import { types } from "../../types/types";

export const helpRequest = () => {
    return {
      type: types.helpRequest,
    };
  };
  
  export const helpSuccess = (help) => {
    return {
      type: types.helpSuccess,
      payload: {
        help,
      },
    };
  };
  
  export const helpFailure = (error) => {
    return {
      type: types.helpFailure,
      payload: {
        error,
      },
    };
  };