import axios from "axios";

import { baseURL } from "../constants/api";
import {
  advancedSearchFailure,
  advancedSearchRequest,
  advancedSearchSuccess,
  advancedSearchTotalFailure,
  // advancedSearchTotalRequest,
  advancedSearchTotalSuccess,
} from "../actions/advancedSearchActions/advancedSearchActions";

let cancelToken1;
let cancelToken2;

export const getAdvancedSearchName = (funko) => async (dispatch) => {
  dispatch(advancedSearchRequest());

  if (typeof cancelToken1 != typeof undefined) {
    cancelToken1.cancel("Canceling the previous request");
  }

  cancelToken1 = axios.CancelToken.source();

  try {
    const response = await axios.get(`${baseURL}/funko/name/${funko}`, {
      cancelToken: cancelToken1.token,
    });
    dispatch(advancedSearchSuccess(response.data));
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled", error.message);
    } else {
      dispatch(advancedSearchFailure(error.message));
    }
  }
};

export const searchByFilters =
  (name, series, license, edition, page, size) => async (dispatch) => {
    dispatch(advancedSearchRequest());

    if (typeof cancelToken1 !== "undefined") {
      cancelToken1.cancel("Canceling the previous request");
    }

    cancelToken1 = axios.CancelToken.source();

    const params = {
      name: name,
      series: series,
      license: license,
      edition: edition,
      page: page,
      size: size,
    };

    try {
      const response = await axios.get(`${baseURL}/funko/funkos/filter`, {
        params: params,
        cancelToken: cancelToken1.token,
      });

      dispatch(
        advancedSearchSuccess(
          response.data.content,
          response.data.totalPages,
          response.data.totalElements
        )
      );
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        dispatch(advancedSearchFailure(error.message));
      }
    }
  };

export const getAdvancedSearchNameTotal = (funko) => async (dispatch) => {
  if (typeof cancelToken2 != typeof undefined) {
    cancelToken2.cancel("Canceling the previous request");
  }

  cancelToken2 = axios.CancelToken.source();

  try {
    const response = await axios.get(`${baseURL}/funko/name/${funko}/total`, {
      cancelToken: cancelToken2.token,
    });
    dispatch(advancedSearchTotalSuccess(response.data));
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled", error.message);
    } else {
      dispatch(advancedSearchTotalFailure(error.message));
    }
  }
};
