import { types } from "../../types/types";

const initialState = {
  profileWallet: null,
  profileInfo: null,
  profileUpdateSuccess: null,
  profileAds: [],
};

export const myAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.profileInfoSuccess:
      return {
        profileInfo: action.payload.profileInfo,
      };
    case types.profileInfoClear:
      return {
        ...action.payload,
        profileInfo: null,
      };
    case types.profileInfoFailure:
      return {
        ...action.payload,
      };
    case types.profileUpdateSuccess:
      return {
        ...state,
        ...action.payload,
        profileUpdateSuccess: true,
      };
    case types.profileUpdateFailure:
      return {
        ...action.payload,
      };

    case types.resetProfileUpdate:
      return {
        ...action.payload,
        profileUpdateSuccess: null,
      };

    case types.profileUpdatePasswordSuccess:
      return {
        ...state,
        ...action.payload,
        profileUpdatePasswordSuccess: true,
      };
    case types.profileUpdatePasswordFailure:
      return {
        ...action.payload,
      };
    case types.profileHistorySuccess:
      return {
        ...state,
        ...action.payload,
        profileHistorySuccess: true,
      };
    case types.profileHistoryFailure:
      return {
        ...action.payload,
      };
    case types.profileNotificationsSuccess:
      return {
        ...state,
        ...action.payload,
        profileNotificationsSuccess: true,
      };
    case types.profileNotificationsFailure:
      return {
        ...action.payload,
      };
    case types.profileSearchAdsSuccess:
      return {
        ...state,
        ...action.payload,
        profileSearchAdsSuccess: true,
      };
    case types.profileSearchAdsClear:
      return {
        ...action.payload,
        profileSearchAdsSuccess: null,
      };
    case types.profileSearchAdsFailure:
      return {
        ...state,
        ...action.payload,
      };
    case types.profileGetFunkoByCodeSuccess:
      return {
        ...state,
        ...action.payload,
        profileGetFunkoByCodeSuccess: true,
      };
    case types.profileGetFunkoByCodeFailure:
      return {
        ...action.payload,
      };
    case types.profileAdsSuccess:
      return {
        ...state,
        profileAds: action.payload.profileAds,
        profileAdsSuccess: true,
      };
    case types.profileAdsFailure:
      return {
        ...action.payload,
      };
    case types.profilePublishAdsSuccess:
      return {
        ...state,
        ...action.payload,
        profilePublishAdsSuccess: true,
      };
    case types.profilePublishAdsFailure:
      return {
        ...action.payload,
      };
    case types.profileUpdateAdsSuccess:
      return {
        ...action.payload,
        ...state,
        profileUpdateAdsSuccess: true,
      };
    case types.profileUpdateAdsFailure:
      return {
        ...action.payload,
      };
    case types.profileDeleteAdsSuccess:
      return {
        ...state,
        ...action.payload,
        profileDeleteAdsSuccess: true,
      };
    case types.profileDeleteAdsFailure:
      return {
        ...action.payload,
      };
    case types.profileTransactionProcessSuccess:
      return {
        ...state,
        ...action.payload,
        profileTransactionProcessSuccess: true,
      };
    case types.profileTransactionProcessFailure:
      return {
        ...action.payload,
      };
    case types.profileUpdateImgSuccess:
      return {
        ...state,
        ...action.payload,

        profileUpdateImgSuccess: true,
      };
    case types.profileUpdateImgFailure:
      return {
        ...action.payload,
      };
    case types.profileWalletSuccess:
      return {
        ...state,
        profileWallet: action.payload.profileWallet,
        profileWalletSuccess: true,
      };
    case types.profileWalletFailure:
      return {
        ...state,
        ...action.payload,
      };
    case types.profileActivateStripeSuccess:
      return {
        ...state,
        ...action.payload,
        profileActivateStripeSuccess: true,
      };
    case types.profileActivateStripeFailure:
      return {
        ...state,
        ...action.payload,
      };
    case types.profileDepositStripeSuccess:
      return {
        ...action.payload,
        ...state,
        profileDepositStripeSuccess: true,
      };
    case types.profileDepositStripeFailure:
      return {
        ...state,
        ...action.payload,
      };
    case types.profileDepositStripeCheckSuccess:
      return {
        ...action.payload,
        ...state,
        profileDepositStripeCheckSuccess: true,
      };
    case types.profileDepositStripeCheckFailure:
      return {
        ...state,
        ...action.payload,
      };
    case types.profileWithdrawStripeSuccess:
      return {
        ...action.payload,
        ...state,
        profileWithdrawStripeSuccess: true,
      };
    case types.profileWithdrawStripeFailure:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
