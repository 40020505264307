import axios from "axios";
import { helpFailure, helpRequest, helpSuccess } from "../actions/helpActions/helpActions";


import { baseURL } from "../constants/api";

export const getHelp = async (dispatch) => {
  dispatch(helpRequest());
  try {
    const response = await axios.get(`${baseURL}/help`);
    dispatch(helpSuccess(response.data));
    //return response.data;
  } catch (error) {
    dispatch(helpFailure(error.message));
    //return error ;
  }
};
