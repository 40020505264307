import { React, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BsBellFill, BsFillCartFill } from "react-icons/bs";
import { FaSignOutAlt } from "react-icons/fa";
import { profileInfoClear } from "../../../actions/myAccountActions/myAccountActions";
import { logout } from "../../../actions/authActions/authActions";

const CustomMenu = () => {
  const cartItems = useSelector((state) => state.cartReducer.cartItems);
  const profileNotifications = useSelector(
    (state) => state.myAccountReducer.profileNotifications
  );
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  const isLogged = currentUser && currentUser.userName ? true : false;

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleNavigate = (route) => {
    navigate(`/${route}`, {
      replace: true,
    });
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="large"
        edge="end"
        color="inherit"
        aria-label="open drawer"
        sx={{
          mr: 0,
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <MenuIcon className="customMenu__icon" />
      </IconButton>
      {isLogged ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleNavigate("")}>Inicio</MenuItem>
          <MenuItem onClick={() => handleNavigate("profile")}>
            Mi cuenta
          </MenuItem>

          <MenuItem onClick={() => handleNavigate("profile/notificaciones")}>
            <BsBellFill className="mr-1" />{profileNotifications && profileNotifications.length > 0 && profileNotifications.length} Alerta
          </MenuItem>
          <MenuItem onClick={() => handleNavigate("checkout")}>
            <BsFillCartFill className="mr-1 mb-1" />{cartItems && cartItems.length > 0 && cartItems.length} Carrito
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(logout());
              dispatch(profileInfoClear());
              handleNavigate("login");
            }}
          >
            <FaSignOutAlt className="mr-1" /> Cerrar sesión
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleNavigate("")}>Inicio</MenuItem>

          <MenuItem onClick={() => handleNavigate("login")}>
            Iniciar sesion
          </MenuItem>
          <MenuItem onClick={() => handleNavigate("signup")}>
            Registrate ya
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default CustomMenu;
