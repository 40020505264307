import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { authReducer } from "../reducers/authReducer/authReducer";
import { latestReleasesReducer } from "../reducers/latestReleasesReducer/latestReleasesReducer";
import { topSalesReducer } from "../reducers/topSalesReducer/topSalesReducer";
import { topSearchReducer } from "../reducers/topSearchReducer/topSearchReducer";
import { funkoDetailReducer } from "../reducers/funkoDetailReducer/funkoDetailReducer";
import { funkosOnSaleReducer } from "../reducers/funkosOnSaleReducer/funkosOnSaleReducer";
import { alertReducer } from "../reducers/alertReducer/alertReducer";
import { helpReducer } from "../reducers/helpReducer/helpReducer";
import { myAccountReducer } from "../reducers/myAccountReducer/myAccountReducer";
import { requestFunkoReducer } from "../reducers/requestFunkoReducer/requestFunkoReducer";
import { cartReducer } from "../reducers/cartReducer/cartReducer";
import { publicProfileReducer } from "../reducers/publicProfileReducer/publicProfileReducer";
import { advancedSearchReducer } from "../reducers/advancedSearchReducer/advancedSearchReducer";

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const reducers = combineReducers({
  login: authReducer,
  latestReleasesReducer,
  topSalesReducer,
  topSearchReducer,
  funkoDetailReducer,
  funkosOnSaleReducer,
  alertReducer,
  helpReducer,
  myAccountReducer,
  requestFunkoReducer,
  cartReducer,
  publicProfileReducer,
  advancedSearchReducer,
});

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);
