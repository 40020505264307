/* eslint-disable no-unused-vars */
import * as React from "react";
import { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import logo from "../../../assets/logos/logo-2.png";
import logoMobile from "../../../assets/logos/funko-card-logo.png";
import CustomMenu from "./CustomMenu";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Tooltip, useMediaQuery } from "@mui/material";
import Select from "react-select";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdvancedSearchNameTotal,
  searchByFilters,
} from "../../../services/advancedSearchService";
import {
  advancedSearchReset,
  advancedSearchTotalReset,
} from "../../../actions/advancedSearchActions/advancedSearchActions";
//test
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  flexGrow: 1,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

export default function Header() {
  const searchTotalItems = useSelector(
    (state) => state.advancedSearchReducer?.searchTotalItems
  );
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  const isLogged = currentUser && currentUser.userName ? true : false;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchValueRef = React.useRef("");

  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("852"));
  const enterPressedRef = React.useRef(false);

  const handleNavigate = () => {
    navigate(`/`, {
      replace: true,
    });
  };

  const handleSelectChange = (value, { action }) => {
    if (enterPressedRef.current) {
      enterPressedRef.current = false;
      return;
    }

    if (action === "select-option") {
      setSelectedOption(value);
      if (value && value.value) {
        navigate(`/funko/${value?.value}`, {
          replace: true,
        });
        dispatch(advancedSearchTotalReset());
      }
      setSelectedOption("");
    }
  };

  const handleButtonClick = () => {
    dispatch(advancedSearchReset());
    if (getSearchValue()?.length > 0) {
      //dispatch(getAdvancedSearchName(getSearchValue()));
      dispatch(searchByFilters(getSearchValue(), null, null, null, 0, 12));
    }

    navigate(`/search`, {
      replace: true,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      enterPressedRef.current = true;

      if (searchValueRef?.current?.length > 0) {
        // dispatch(getAdvancedSearchName(searchValueRef.current));
        dispatch(
          searchByFilters(searchValueRef.current, null, null, null, 0, 12)
        );
      }
      navigate(`/search`, {
        replace: true,
      });
      setSelectedOption("");
    }
  };

  const handleInputChange = (value, { action }) => {
    if (action === "input-change") {
      searchValueRef.current = value;
      if (value && value.length > 0) {
        dispatch(getAdvancedSearchNameTotal(value));
      }
    }
  };

  const getSearchValue = () => {
    const inputElement = document.getElementById("search-input");
    return inputElement ? inputElement.value : "";
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      borderColor: "transparent",
      "&:hover": {
        borderColor: "transparent",
      },
    }),
    input: (provided) => ({
      ...provided,
      color: "black",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
  };
  if (isSmallScreen || isTabletScreen) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar sx={{ display: "flex" }}>
            {isSmallScreen || isTabletScreen ? (
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                <img
                  onClick={handleNavigate}
                  style={{ cursor: "pointer" }}
                  src={logoMobile}
                  alt="logo"
                  width={75}
                  height={75}
                />
              </Typography>
            ) : (
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                <img
                  onClick={handleNavigate}
                  style={{ cursor: "pointer" }}
                  src={logo}
                  alt="logo"
                  width={300}
                />
              </Typography>
            )}

            <Search>
              <Select
                id="search-input"
                styles={customStyles}
                placeholder=""
                value={selectedOption}
                onChange={handleSelectChange}
                onBlur={() => {
                  searchValueRef.current = inputValue;
                }}
                onKeyDown={handleKeyDown}
                onInputChange={handleInputChange}
                isSearchable
                //isClearable
                options={searchTotalItems}
              />
            </Search>
            <Tooltip title="Búsqueda Avanzada" arrow>
              <Button
                className={isSmallScreen ? "mr-1 ml-1" : "mr-5 ml-2"}
                sx={{
                  "&:focus": {
                    outline: "none",
                  },
                }}
                variant="contained"
                onClick={handleButtonClick}
              >
                <SearchIcon />
              </Button>
            </Tooltip>
            {!isLogged && !isSmallScreen && (
              <Button
                className={isSmallScreen ? "mr-1 ml-1" : "mr-5 ml-2"}
                sx={{
                  "&:focus": {
                    outline: "none",
                  },
                }}
                variant="contained"
                onClick={() =>
                  navigate(`/signup`, {
                    replace: true,
                  })
                }
              >
                ¡Registrate ya!
              </Button>
            )}
            <CustomMenu />
          </Toolbar>
        </AppBar>
      </Box>
    );
  } else {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Grid
              container
              display={"flex"}
              justifyContent={"space-between"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Grid item xs={4}>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{
                    flexGrow: 1,
                    display: { xs: "none", sm: "block" },
                    marginRight: "auto",
                  }}
                >
                  <img
                    onClick={handleNavigate}
                    style={{ cursor: "pointer" }}
                    src={logo}
                    alt="logo"
                    width={300}
                  />
                </Typography>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{
                    flexGrow: 1,
                    display: { xs: "block", sm: "none" },
                    marginRight: "auto",
                  }}
                >
                  <img
                    onClick={handleNavigate}
                    style={{ cursor: "pointer" }}
                    src={logoMobile}
                    alt="logo"
                    width={75}
                    height={75}
                  />
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Search>
                  <Select
                    id="search-input"
                    styles={customStyles}
                    placeholder=""
                    value={selectedOption}
                    onChange={handleSelectChange}
                    onBlur={() => {
                      searchValueRef.current = inputValue;
                    }}
                    onKeyDown={handleKeyDown}
                    onInputChange={handleInputChange}
                    isSearchable
                    //isClearable
                    options={searchTotalItems}
                  />
                </Search>
              </Grid>
              <Grid item xs={1}>
                <Tooltip title="Búsqueda Avanzada" arrow>
                  <Button
                    className={isSmallScreen ? "mr-1 ml-1" : "mr-5 ml-2"}
                    sx={{
                      "&:focus": {
                        outline: "none",
                      },
                    }}
                    variant="contained"
                    onClick={handleButtonClick}
                  >
                    <SearchIcon />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={2}>
                {!isLogged && !isSmallScreen && (
                  <Button
                    className={isSmallScreen ? "mr-1 ml-1" : "mr-5 ml-2"}
                    sx={{
                      "&:focus": {
                        outline: "none",
                      },
                    }}
                    variant="contained"
                    onClick={() =>
                      navigate(`/signup`, {
                        replace: true,
                      })
                    }
                  >
                    ¡Registrate ya!
                  </Button>
                )}
              </Grid>
              <Grid item xs={1}>
                <CustomMenu />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
}
