import { types } from "../../types/types";

export const topSearchRequest = () => {
    return {
      type: types.topSearchRequest,
    };
  };
  
  export const topSearchSuccess = (topSearch) => {
    return {
      type: types.topSearchSuccess,
      payload: {
        topSearch,
      },
    };
  };
  
  export const topSearchFailure = (error) => {
    return {
      type: types.topSearchFailure,
      payload: {
        error,
      },
    };
  };