// import axios from "axios";
import { sendAlert } from "../actions/alertActions/alertActions";
import {
  profileActivateStripeFailure,
  profileActivateStripeRequest,
  profileActivateStripeSuccess,
  profileAdsFailure,
  profileAdsRequest,
  profileAdsSuccess,
  profileDeleteAdsFailure,
  profileDeleteAdsRequest,
  profileDeleteAdsSuccess,
  profileDepositStripeCheckFailure,
  profileDepositStripeCheckRequest,
  profileDepositStripeCheckSuccess,
  profileDepositStripeFailure,
  profileDepositStripeRequest,
  profileDepositStripeSuccess,
  profileGetFunkoByCodeFailure,
  profileGetFunkoByCodeRequest,
  profileGetFunkoByCodeSuccess,
  profileHistoryFailure,
  profileHistoryRequest,
  profileHistorySuccess,
  profileInfoFailure,
  profileInfoRequest,
  profileInfoSuccess,
  profileNotificationsFailure,
  profileNotificationsRequest,
  profileNotificationsSuccess,
  profilePublishAdsFailure,
  profilePublishAdsRequest,
  profilePublishAdsSuccess,
  profileSearchAdsFailure,
  profileSearchAdsRequest,
  profileSearchAdsSuccess,
  profileTransactionProcessFailure,
  profileTransactionProcessRequest,
  profileTransactionProcessSuccess,
  profileUpdateAdsFailure,
  profileUpdateAdsRequest,
  profileUpdateAdsSuccess,
  profileUpdateFailure,
  profileUpdateImgFailure,
  profileUpdateImgRequest,
  profileUpdateImgSuccess,
  profileUpdatePasswordFailure,
  profileUpdatePasswordRequest,
  profileUpdatePasswordSuccess,
  profileUpdateRequest,
  profileUpdateSuccess,
  profileWalletFailure,
  profileWalletRequest,
  profileWalletSuccess,
  profileWithdrawStripeFailure,
  profileWithdrawStripeRequest,
  profileWithdrawStripeSuccess,
} from "../actions/myAccountActions/myAccountActions";

import axiosInstance from "../interceptors/axiosInstance";

export const getProfileInfo = () => async (dispatch) => {
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;
  dispatch(profileInfoRequest());

  try {
    const response = await axiosInstance.get(`/backoffice/${userName}/info`);
    dispatch(profileInfoSuccess(response.data));
  } catch (error) {
    dispatch(profileInfoFailure(error.message));
  }
};

export const updateProfileInfo = (data) => async (dispatch) => {
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;

  dispatch(profileUpdateRequest());

  try {
    const response = await axiosInstance.put(
      `/backoffice/${userName}/update`,
      data
    );
    dispatch(profileUpdateSuccess(response.data));
    dispatch(
      sendAlert({
        type: "success",
        message: "Usario actualizado!",
      })
    );
    //return response.data;
  } catch (error) {
    dispatch(profileUpdateFailure(error.message));
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
    //return error ;
  }
};
export const updatePassword = (newPass, oldPass) => async (dispatch) => {
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;
  const data = { headers: { normalizedNames: {}, lazyUpdate: null } };
  dispatch(profileUpdatePasswordRequest());

  try {
    const response = await axiosInstance.put(
      `/backoffice/${userName}/password?newPass=${newPass}&oldPass=${oldPass}`,
      data
    );
    dispatch(profileUpdatePasswordSuccess(response.data));
    dispatch(
      sendAlert({
        type: "success",
        message: "Contraseña actualizada!",
      })
    );
    //return response.data;
  } catch (error) {
    dispatch(profileUpdatePasswordFailure(error.message));
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
    //return error ;
  }
};

export const getHistory = () => async (dispatch) => {
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;

  dispatch(profileHistoryRequest());

  try {
    const response = await axiosInstance.get(`/backoffice/${userName}/history`);
    dispatch(profileHistorySuccess(response.data));
  } catch (error) {
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
    dispatch(profileHistoryFailure(error.message));
  }
};

export const getNotifications = () => async (dispatch) => {
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;

  dispatch(profileNotificationsRequest());

  try {
    const response = await axiosInstance.get(
      `/accounts/notification/${userName}`
    );
    dispatch(profileNotificationsSuccess(response.data));
  } catch (error) {
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
    dispatch(profileNotificationsFailure(error.message));
  }
};

export const getSearchFunkosAds = (value) => async (dispatch) => {
  dispatch(profileSearchAdsRequest());

  try {
    const response = await axiosInstance.get(`/funko/name/${value}/total`);
    dispatch(profileSearchAdsSuccess(response.data));
  } catch (error) {
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
    dispatch(profileSearchAdsFailure(error.message));
  }
};

export const getProfileFunkoByCode = (value) => async (dispatch) => {
  dispatch(profileGetFunkoByCodeRequest());

  try {
    const response = await axiosInstance.get(`/funko/code/${value}`);
    dispatch(profileGetFunkoByCodeSuccess(response.data));
  } catch (error) {
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
    dispatch(profileGetFunkoByCodeFailure(error.message));
  }
};

export const getProfileAds = () => async (dispatch) => {
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;
  dispatch(profileAdsRequest());

  try {
    const response = await axiosInstance.get(`/ad/${userName}/all`);
    dispatch(profileAdsSuccess(response.data));
  } catch (error) {
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
    dispatch(profileAdsFailure(error.message));
  }
};

export const publishAds = (data) => async (dispatch) => {
  dispatch(profilePublishAdsRequest());

  try {
    const response = await axiosInstance.post(`/ad/publish`, data);
    dispatch(profilePublishAdsSuccess(response.data));
    dispatch(
      sendAlert({
        type: "success",
        message: "Anuncio publicado!",
      })
    );
  } catch (error) {
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
    dispatch(profilePublishAdsFailure(error.message));
  }
};

export const updateAds = (data) => async (dispatch) => {
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;
  dispatch(profileUpdateAdsRequest());

  try {
    const response = await axiosInstance.post(
      `/backoffice/${userName}/updateAd`,
      data
    );
    dispatch(profileUpdateAdsSuccess(response.data));
    dispatch(
      sendAlert({
        type: "success",
        message: "Anuncio actualizado!",
      })
    );
  } catch (error) {
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
    dispatch(profileUpdateAdsFailure(error.message));
  }
};

export const deleteAds = (code) => async (dispatch) => {
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;

  dispatch(profileDeleteAdsRequest());

  try {
    const response = await axiosInstance.delete(
      `/backoffice/${userName}/delete/${code}`
    );
    dispatch(profileDeleteAdsSuccess(response.data));
    dispatch(
      sendAlert({
        type: "success",
        message: "Anuncio eliminado!",
      })
    );
  } catch (error) {
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
    dispatch(profileDeleteAdsFailure(error.message));
  }
};

export const processAds = (code) => async (dispatch) => {
  dispatch(profileTransactionProcessRequest());

  try {
    const response = await axiosInstance.post(
      `/transaction/process/${code}`,
      {}
    );
    dispatch(profileTransactionProcessSuccess(response.data));
    dispatch(
      sendAlert({
        type: "success",
        message: "Anuncio procesado!",
      })
    );
  } catch (error) {
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
    dispatch(profileTransactionProcessFailure(error.message));
  }
};

export const updateImgProfile = (data) => async (dispatch) => {
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;
  dispatch(profileUpdateImgRequest());

  try {
    const response = await axiosInstance.put(
      `/backoffice/${userName}/updateImg`,
      data
    );
    dispatch(profileUpdateImgSuccess(response.data));
    dispatch(
      sendAlert({
        type: "success",
        message: "Imagen de perfil actualizada!",
      })
    );
  } catch (error) {
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
    dispatch(profileUpdateImgFailure(error.message));
  }
};

export const getWalletInfo = () => async (dispatch) => {
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;
  dispatch(profileWalletRequest());

  try {
    const response = await axiosInstance.get(`/backoffice/${userName}/wallet`);
    dispatch(profileWalletSuccess(response.data));
  } catch (error) {
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
    dispatch(profileWalletFailure(error.message));
  }
};

export const getActivateStripe = () => async (dispatch) => {
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;
  dispatch(profileActivateStripeRequest());

  try {
    const response = await axiosInstance.post(
      `/payment/createAccount/${userName}`,
      {}
    );
    dispatch(profileActivateStripeSuccess(response.data));
    window.open(response.data.url);
  } catch (error) {
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
    dispatch(profileActivateStripeFailure(error.message));
  }
};

export const depositStripe = (amount) => async (dispatch) => {
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;
  const email = JSON.parse(sessionStorage.getItem("currentUser")).email;
  const data = {
    email,
    amount,
  };
  dispatch(profileDepositStripeRequest());
  try {
    const response = await axiosInstance.post(
      `/backoffice/${userName}/wallet/deposit`,
      data
    );
    dispatch(profileDepositStripeSuccess(response));
    // console.log(response.data);
    // window.open(response.data.checkOutURL);
  } catch (error) {
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
    dispatch(profileDepositStripeFailure(error.message));
  }
};

export const depositStripeCheck = (sessionStatus) => async (dispatch) => {
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;
  const idSession = JSON.parse(localStorage.getItem("idSession"));
  const data = {
    idSession,
    sessionStatus,
  };
  dispatch(profileDepositStripeCheckRequest());
  try {
    const response = await axiosInstance.post(
      `/backoffice/${userName}/wallet/deposit/check`,
      data
    );
    dispatch(profileDepositStripeCheckSuccess(response));
    // console.log(response.data);
    window.open(response.data.checkOutURL);
  } catch (error) {
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
    dispatch(profileDepositStripeCheckFailure(error.message));
  }
};

//TODO: para las pruebas

export const withdrawStripe = (amount, stripeAccount) => async (dispatch) => {
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;

  const data = {
    amount,
    stripeAccount,
  };
  dispatch(profileWithdrawStripeRequest());
  try {
    const response = await axiosInstance.post(
      `/backoffice/${userName}/wallet/withdraw`,
      data
    );
    dispatch(profileWithdrawStripeSuccess(response.data));
    // console.log(response.data);
    window.open(response.data.checkOutURL);
  } catch (error) {
    dispatch(
      sendAlert({
        type: "error",
        message: error.message,
      })
    );
    dispatch(profileWithdrawStripeFailure(error.message));
  }
};

// export const depositStripe = (amount) => async (dispatch) => {
//   const email = JSON.parse(sessionStorage.getItem("currentUser")).email;
//   const data = {
//     email,
//     amount,
//   };

//   const headers = {
//     Accept: "application/json, text/plain, */*",
//     "Accept-Language": "es-ES,es;q=0.9",
//     Authorization:
//       "Bearer Bearer eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJzb2Z0dGVrSldUIiwic3ViIjoiZGFuaSIsImF1dGhvcml0aWVzIjpbIlBFUlNPTkFMIl0sImlhdCI6MTY3OTY1NTU4MCwiZXhwIjoxNjc5NjY1NTgwfQ.X5CoRouIHarqT3pL2ubFVNvpq6yv-z6T14cuMp66bDtjZz036BCU_86vfyKRc66V6R-2a9fPVJ-vsRJ0X657YA",
//     Connection: "keep-alive",
//     "Content-Type": "application/json",
//     Origin: "https://www.verypops.com",
//     Referer: "https://www.verypops.com/",
//     "Sec-Fetch-Dest": "empty",
//     "Sec-Fetch-Mode": "cors",
//     "Sec-Fetch-Site": "same-site",
//     "User-Agent":
//       "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36",
//     "sec-ch-ua":
//       '^"^"Google Chrome^";v=^"113^", ^"Chromium^";v=^"113^", ^"Not-A.Brand^";v=^"24^""',
//     "sec-ch-ua-mobile": "?0",
//     "sec-ch-ua-platform": '^"^"Windows^"^"',
//   };

//   dispatch(profileDepositStripeRequest());

//   try {
//     const response = await axios.post(
//       "https://www.verypops.com:9191/backoffice/undefined/wallet/deposit",
//       data,
//       { headers: headers }
//     );

//     dispatch(profileDepositStripeSuccess(response));
//     console.log(response.data);
//     window.open(response.data.checkOutURL);
//   } catch (error) {
//     dispatch(
//       sendAlert({
//         type: "error",
//         message: error.message,
//       })
//     );
//     dispatch(profileDepositStripeFailure(error.message));
//   }
// };
