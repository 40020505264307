import { Button, Tooltip, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  BsFillArrowUpCircleFill,
  BsFillCartDashFill,
  BsFillCartPlusFill,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logos/funko-card-logo.png";
import funko404 from "../../assets/img/404_funko.jpg";
import { imgUrl } from "../../constants/api";
import useItemInCart from "../../hooks/useItemInCart";
import useCart from "../../hooks/useCart";
import { useTheme } from "@emotion/react";

const FunkoCard = ({
  funko = {},
  creationAd = false,
  publicProfileAd = false,
  advancedSearch = false,
}) => {
  const {
    name,
    series,
    license,
    number,
    price,
    img,
    codeFunko,
    funkoName,
    funkoPhoto,
    funkoCode,
    advertisementNumber,
  } = funko;

  const navigate = useNavigate();
  const { isItemInCart } = useItemInCart();
  const { addToCart, removeFromCart } = useCart();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleFunkoDetail = (code) => {
    navigate(`/funko/${code}`, {
      replace: true,
    });
  };

  return (
    <div
      onClick={(e) => {
        if (!creationAd && !e.target.closest("button")) {
          handleFunkoDetail(codeFunko || funkoCode);
        }
      }}
      className={
        (publicProfileAd || advancedSearch) && isSmallScreen
          ? "funkoCard__card-ads-mobile"
          : "funkoCard__card"
      }
    >
      {/* El resto del contenido del componente */}
      {number && !publicProfileAd ? (
        <span
          _ngcontent-mbi-c126=""
          className="badge number funkoCard__logo-top-left"
        >
          {number}
        </span>
      ) : (
        <span
          _ngcontent-mbi-c126=""
          className="badge number funkoCard__logo-top-left"
        >
          {!publicProfileAd && 0}
        </span>
      )}

      <img src={logo} className="funkoCard__logo-top-right" alt="Logo" />
      {name || funkoName ? (
        <div className="funkoCard__title">
          <Tooltip
            arrow
            placement="top"
            title={
              !publicProfileAd ? name.toUpperCase() : funkoName.toUpperCase()
            }
            variant="solid"
          >
            <h5>
            {name?.slice(0, 26).toUpperCase()}
            {funkoName?.slice(0, 26).toUpperCase()}
              {!publicProfileAd ? name.length > 26 ? "..." : "" : funkoName.length > 26 ? "..." : ""}
            </h5>
          </Tooltip>
        </div>
      ) : (
        <div className="funkoCard__title">
          <Tooltip arrow placement="top" title="NOMBRE" variant="solid">
            <h5>NOMBRE</h5>
          </Tooltip>
        </div>
      )}
      <div className="funkoCard__centered">
        {img || funkoPhoto ? (
          <img
            className="funkoCard__img"
            src={
              !publicProfileAd ? `${imgUrl}/${img}` : `${imgUrl}${funkoPhoto}`
            }
            alt="Imagen"
          />
        ) : (
          <img className="funkoCard__img" src={funko404} alt="Imagen" />
        )}
      </div>
      {!publicProfileAd ? (
        <div className="funkoCard__bottom-row">
          <div className="center-col">
            {series ? (
              <span className="badge funkoCard__serie">
                {creationAd ? series.name.toUpperCase() : series?.toUpperCase()}
              </span>
            ) : (
              <span className="badge funkoCard__serie">SERIE</span>
            )}
          </div>
          <div className="funkoCard__center-col">
            {license ? (
              <span className="badge funkoCard__license">
                {license.toUpperCase()}
              </span>
            ) : (
              <span className="badge funkoCard__license">LICENCIA</span>
            )}
          </div>
          <div className="funkoCard__center-col">
            {!creationAd ? (
              <span className="">
                <BsFillArrowUpCircleFill className="funkoCard__arrow-icon" />
                <span className="funkoCard__price text-right pl-2">
                  {price}€
                </span>
              </span>
            ) : (
              <>
                {funko.edition ? (
                  <span className="badge funkoCard__edition">
                    {funko.edition.toUpperCase()}
                  </span>
                ) : (
                  <span className="badge funkoCard__edition">EDICIÓN</span>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="funkoCard__bottom-row mb-2">
          <div
            className={
              isSmallScreen
                ? "center-col text-center mb-3"
                : "center-col text-center mb-4"
            }
          >
            <Typography variant={isSmallScreen ? "h5" : "h4"}>
              {price.toFixed(2)} €
            </Typography>
          </div>
          <div className="center-col mb-2">
            {isItemInCart(advertisementNumber) ? (
              <Button
                variant="contained"
                color="error"
                sx={{ width: "100%" }}
                onClick={() => removeFromCart(advertisementNumber)}
              >
                {!isSmallScreen ? "Eliminar del carrito" : ""}
                <BsFillCartDashFill className="ml-2 mb-1" />
              </Button>
            ) : (
              <Button
                variant="contained"
                color="success"
                sx={{ width: "100%" }}
                onClick={() => addToCart(funko)}
              >
                {!isSmallScreen ? "Añadir al carrito" : ""}
                <BsFillCartPlusFill className="ml-2 mb-1" />
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FunkoCard;
