import React from "react";
import { Button, Grid, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/img/pop-cabecera.png";
import { changeforgotPassword } from "../../services/authServices";

const validationSchema = yup.object({
  newPassword: yup
    .string("Ingresa tu nueva contraseña")
    .required("La nueva contraseña es obligatoria")
    .min(8, "La contraseña debe tener al menos 8 caracteres"),
  repeatPassword: yup
    .string("Repite tu nueva contraseña")
    .oneOf([yup.ref("newPassword"), null], "Las contraseñas no coinciden")
    .required("Repetir contraseña es obligatorio"),
});

const encodeBase64 = (data) => {
  return btoa(data);
};

export const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { code } = useParams();

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      repeatPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const encodedNewPassword = encodeBase64(values.newPassword);

      // const encodedValues = {
      //   newPassword: encodedNewPassword,
      // };

      changeforgotPassword(encodedNewPassword, code, dispatch, navigate);
    },
  });

  return (
    <div className="login__portada">
      <Grid item display={"flex"} justifyContent={"center"}>
        <form
          onSubmit={formik.handleSubmit}
          className="login__formBox text-center"
        >
          <div className="mb-3 text-center">
            <img src={logo} alt="logo" width={300} height={100} />
          </div>

          <p className="h4">Cambiar contraseña</p>
          <div className="my-3">
            <TextField
              fullWidth
              id="newPassword"
              name="newPassword"
              required
              type="password"
              label="Contraseña"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.newPassword && Boolean(formik.errors.newPassword)
              }
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
            />
          </div>
          <div className="my-3">
            <TextField
              fullWidth
              id="repeatPassword"
              name="repeatPassword"
              required
              type="password"
              label="Repetir contraseña"
              value={formik.values.repeatPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.repeatPassword &&
                Boolean(formik.errors.repeatPassword)
              }
              helperText={
                formik.touched.repeatPassword && formik.errors.repeatPassword
              }
            />
          </div>

          <div className="d-flex justify-content-center">
            <Button type="submit" variant="contained">
              Cambiar contraseña
            </Button>
          </div>
          <hr />

          <div className="d-flex justify-content-center mt-3">
            <div>
              <p>¿No tienes cuenta?</p>
              <Link className="login__anchor" to="/signup">
                REGISTRATE AQUI
              </Link>
            </div>
          </div>
        </form>
      </Grid>
    </div>
  );
};
