import {
  Avatar,
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPublicProfileAds,
  getpublicProfile,
} from "../services/publicProfileService";
import { imgUrl } from "../constants/api";
import { FaUsers } from "react-icons/fa";
import FunkoCard from "../components/funkocard/FunkoCard";
import { useParams } from "react-router-dom";
import { BsFillStarFill } from "react-icons/bs";
import { useTheme } from "@emotion/react";

const PublicProfile = () => {
  const publicProfile = useSelector(
    (state) => state.publicProfileReducer.publicProfile
  );
  const publicProfileAds = useSelector(
    (state) => state.publicProfileReducer.publicProfileAds
  );
  const dispatch = useDispatch();
  const { id } = useParams();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(getpublicProfile(id));
    dispatch(getPublicProfileAds(id));
  }, [dispatch, id]);

  const getStarsRating = (value) => {
    if (value === 100) {
      return (
        <>
          <BsFillStarFill color="#F6B036" />
          <BsFillStarFill color="#F6B036" />
          <BsFillStarFill color="#F6B036" />
          <BsFillStarFill color="#F6B036" />
          <BsFillStarFill color="#F6B036" />
        </>
      );
    } else if (value >= 75 && value < 100) {
      return (
        <>
          <BsFillStarFill color="#F6B036" />
          <BsFillStarFill color="#F6B036" />
          <BsFillStarFill color="#F6B036" />
          <BsFillStarFill color="#F6B036" />
        </>
      );
    } else if (value >= 50 && value < 75) {
      return (
        <>
          <BsFillStarFill color="#F6B036" />
          <BsFillStarFill color="#F6B036" />
          <BsFillStarFill color="#F6B036" />
        </>
      );
    } else if (value >= 25 && value < 50) {
      return (
        <>
          <BsFillStarFill color="#F6B036" />
          <BsFillStarFill color="#F6B036" />
        </>
      );
    } else {
      return (
        <>
          <BsFillStarFill color="#F6B036" />
        </>
      );
    }
  };

  return (
    <Box display="flex" justifyContent="center" width="100%">
      <Box m={4}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                display="flex"
                justifyContent={isSmallScreen ? "center" : "end"}
              >
                <Avatar
                  alt={publicProfile?.userName}
                  src={`${imgUrl}/${publicProfile?.img}`}
                  sx={{ width: 200, height: 200 }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="h3">{publicProfile?.userName}</Typography>
                <Typography variant="h5">
                  <FaUsers style={{ fontSize: "19px" }} />{" "}
                  {publicProfile?.accountType}
                </Typography>
                <Typography variant="h5">
                  Miembro dede {publicProfile?.created} ,{" "}
                  {publicProfile?.country}
                </Typography>
                <Typography variant="h6">
                  Valoración general: {getStarsRating(publicProfile?.rating)}{" "}
                  {publicProfile?.rating} %
                </Typography>
                <Typography variant="h6">
                  Artículos vendidos: {publicProfile?.salesCount}
                </Typography>
                <Typography variant="h6">
                  Artículos comprados: {publicProfile?.ordersCount}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Divider />
            <Typography my={2} variant="h4" textAlign={"center"}>
              Productos en venta:
            </Typography>
            <Grid container spacing={4}>
              {publicProfileAds &&
                publicProfileAds.length > 0 &&
                publicProfileAds.map((card, index) => (
                  <>
                    <Grid item xs={5} md={4} key={index} mr={3}>
                      <FunkoCard publicProfileAd funko={card} />
                    </Grid>
                  </>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PublicProfile;
