import { types } from "../../types/types";

export const addCartRequest = () => {
  return {
    type: types.addCartRequest,
  };
};

export const addCartSuccess = (addCart) => {
  return {
    type: types.addCartSuccess,
    payload: addCart,
  };
};
export const addCartReset = () => {
  return {
    type: types.addCartReset,
  };
};

export const addCartFailure = (error) => {
  return {
    type: types.addCartFailure,
    payload: {
      error,
    },
  };
};

export const removeCartItem = (advertisementNumber) => {
  return {
    type: types.removeCartItem,
    payload: { advertisementNumber },
  };
};

export const emptyCart = () => {
  return {
    type: types.emptyCart,
  };
};
