import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { object, string, ref, boolean } from "yup";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import logo from "../../assets/logos/logo-2.png";
import { useFormik } from "formik";
import { signUp } from "../../services/authServices";
import parsePhoneNumber from "libphonenumber-js";

const RegisterScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formErrorSchema = object().shape({
    email: string()
      .email("Correo electrónico inválido")
      .required("El correo electrónico es obligatorio"),
    nickName: string().required("El campo es obligatorio"),
    reEmail: string()
      .oneOf([ref("email"), null], "Los correos deben coincidir")
      .required("La confirmación de correo es obligatoria"),
    password: string()
      .min(8, "La contraseña debe tener al menos 8 caracteres")
      .required("La contraseña es obligatoria"),
    rePassword: string()
      .oneOf([ref("password"), null], "Las contraseñas deben coincidir")
      .required("La confirmación de contraseña es obligatoria"),
    phoneNumber: string()
      .required("El campo es obligatorio")
      .test(
        "telefono-valido",
        "El número de teléfono no es válido",
        (value) => {
          const phoneNumber = parsePhoneNumber(value, "ES");
          return phoneNumber?.isValid() || false;
        }
      ),
    surName: string().required("El campo es obligatorio"),
    userName: string().required("El campo es obligatorio"),
    terms: boolean().oneOf([true], "Debe aceptar los términos y condiciones"),
    isBusiness: boolean(),

    // businessName: string().required("El campo es obligatorio"),
    // cif: string().required("El campo es obligatorio"),
  });

  const {
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    isValid,
  } = useFormik({
    initialValues: {
      businessName: "",
      cif: "",
      email: "",
      nickName: "",
      password: "",
      phoneNumber: "",
      reEmail: "",
      rePassword: "",
      surName: "",
      userName: "",
      isBusiness: false,
      terms: false,
    },
    validationSchema: formErrorSchema,
    onSubmit: () => handleSubmitForm(),
  });

  const ModalAceptarTerminos = () => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                id="terms"
                name="terms"
                checked={values.terms}
                onBlur={handleBlur}
                onChange={() => {
                  handleClickOpen();
                  handleChange();
                }}
              />
            }
            label={`ACEPTO LAS CONDICIONES DEL SERVICIO \n Y LAS POLÍTICAS  DE PRIVACIDAD`}
          />
          <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"sm"}
            borderRadius={16}
          >
            <DialogContent>
              <DialogContentText>
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      CONDICIONES DEL SERVICIO Y POLÍTICAS DE PRIVACIDAD
                    </h5>
                  </div>
                  <div className="modal-body">
                    <div>
                      Nos tomamos muy en serio el respeto a la privacidad y la
                      protecci&oacute;n de los datos personales de nuestros
                      usuarios. A continuaci&oacute;n, explicamos el tratamiento
                      que realizamos de estos datos.
                    </div>
                    <ol>
                      <li>
                        <div>
                          <strong>
                            Datos del responsable del tratamiento
                            <br />
                          </strong>
                          Raz&oacute;n Social: GEMMA RAMOS MELGAR (en adelante,
                          la &ldquo;Empresa&rdquo; o el
                          &ldquo;Responsable&rdquo;).NIF: 75966027V
                          <br />
                          Domicilio: URB. VISTA HERMOSA, 138, BAJO DERECHA,
                          CADIZ
                          <br />
                          Email de contacto: legal@verypops.com
                        </div>
                      </li>
                      <li>
                        <div>
                          <strong>
                            Normativa aplicable
                            <br />
                          </strong>
                          Nuestra Pol&iacute;tica de Privacidad se ha
                          dise&ntilde;ado de acuerdo con el Reglamento General
                          de Protecci&oacute;n de Datos de la UE 2016/679 del
                          Parlamento Europeo y del Consejo, de 27 de abril de
                          2016, relativo a la protecci&oacute;n de las personas
                          f&iacute;sicas en lo que respecta al tratamiento de
                          datos personales y a la libre circulaci&oacute;n de
                          estos datos y a la Ley Org&aacute;nica 3/2018, de 5 de
                          diciembre, de Protecci&oacute;n de Datos Personales y
                          garant&iacute;a de los derechos digitales.
                          Adem&aacute;s de ser conforme a la Ley 34/2002 de 11
                          de julio de Servicios de la Sociedad de la
                          Informaci&oacute;n y del Comercio Electr&oacute;nico
                          (LSSI-CE).
                          <br />
                          Al utilizar este sitio web y facilitarnos sus datos,
                          Usted declara haber le&iacute;do y conocer la presente
                          Pol&iacute;tica de Privacidad, prestando su
                          consentimiento inequ&iacute;voco y expreso al
                          tratamiento de sus datos personales de acuerdo a las
                          finalidades y t&eacute;rminos aqu&iacute; expresados.
                          <br />
                          La Empresa podr&aacute; modificar la presente
                          pol&iacute;tica de privacidad para adaptarla a las
                          novedades legislativas, jurisprudenciales o de
                          interpretaci&oacute;n de la Agencia Espa&ntilde;ola de
                          Protecci&oacute;n de Datos.
                        </div>
                      </li>
                      <li>
                        <div>
                          <strong>
                            Finalidad del tratamiento de los datos personales
                            <br />
                          </strong>
                          El tratamiento que realizamos de sus datos personales
                          responde a las siguientes finalidades:&bull; &nbsp;
                          Poder ofrecerle productos y promociones de su
                          inter&eacute;s a trav&eacute;s de comunicaciones de
                          car&aacute;cter comercial basadas en sus necesidades,
                          cuando usted nos lo solicite o de su consentimiento
                          expreso y enviarle novedades acerca de nuestra entidad
                          y sus servicios.&bull; &nbsp; En su caso, iniciar el
                          proceso de contrataci&oacute;n de nuestros
                          servicios.&bull; &nbsp; Para el desarrollo,
                          cumplimiento y ejecuci&oacute;n del contrato de
                          servicios.&bull; &nbsp; Para atender las solicitudes o
                          peticiones que nos realices.
                        </div>
                      </li>
                      <li>
                        <div>
                          <strong>
                            &iquest;Por cu&aacute;nto tiempo conservaremos sus
                            datos?
                            <br />
                          </strong>
                          Conservaremos sus datos personales desde que nos
                          d&eacute; su consentimiento hasta que lo revoque o
                          bien solicite la limitaci&oacute;n del
                          tratamiento.&nbsp;
                          <br />
                          Los datos personales proporcionados se
                          conservar&aacute;n durante el tiempo necesario para
                          cumplir con la finalidad para la que se recaban y para
                          determinar las posibles responsabilidades que se
                          pudieran derivar de la finalidad, adem&aacute;s de los
                          per&iacute;odos establecidos en la normativa de
                          archivos y documentaci&oacute;n.
                        </div>
                      </li>
                      <li>
                        <div>
                          <strong>
                            &iquest;C&oacute;mo hemos obtenido sus datos?
                            <br />
                          </strong>
                          Los datos que tratamos han sido obtenidos de usted
                          mismo, a trav&eacute;s del formulario que usted haya
                          cumplimentado durante la navegaci&oacute;n a
                          trav&eacute;s de nuestra p&aacute;gina web, por
                          v&iacute;a telef&oacute;nica o a trav&eacute;s de la
                          contrataci&oacute;n de un servicio.
                        </div>
                      </li>
                      <li>
                        <div>
                          <strong>
                            &iquest;Qu&eacute; tipo de datos tratamos?
                            <br />
                          </strong>
                          Los datos que trataremos son aquellos que usted nos ha
                          proporcionado a trav&eacute;s de los oportunos
                          formularios o locuciones que empleamos para formalizar
                          nuestro contacto con usted. Estos datos pueden ser, a
                          t&iacute;tulo ilustrativo, su nombre, apellidos, DNI,
                          domicilio o mail de contacto.
                        </div>
                      </li>
                      <li>
                        <div>
                          <strong>
                            &iquest;A qui&eacute;n comunicamos sus datos?
                            <br />
                          </strong>
                          El usuario conoce y acepta que sus datos personales
                          podr&aacute;n ser facilitados a terceros cuando sea
                          necesario para cumplir con las finalidades del
                          tratamiento, respet&aacute;ndose en todos los casos
                          las medidas de seguridad exigidas por la
                          legislaci&oacute;n vigente en materia de
                          protecci&oacute;n de datos de car&aacute;cter personal
                          as&iacute; como las pol&iacute;ticas generales de
                          calidad y privacidad de la Empresa.
                          <br />A excepci&oacute;n de las previsiones contenidas
                          en el p&aacute;rrafo anterior, la Empresa no
                          realizar&aacute; comunicaciones de los datos del
                          Cliente a terceros m&aacute;s all&aacute; de las
                          exigidas en la normativa vigente en materia de
                          protecci&oacute;n de datos, y sus disposiciones de
                          desarrollo, que las realizadas a las autoridades
                          competentes cuando le fuera requerida.
                        </div>
                      </li>
                      <li>
                        <div>
                          <strong>
                            Medidas de seguridad
                            <br />
                          </strong>
                          Dentro de nuestro compromiso por garantizar la
                          seguridad y confidencialidad de sus datos de
                          car&aacute;cter personal, le informamos que se han
                          adoptado las medidas de &iacute;ndole t&eacute;cnica y
                          organizativas necesarias para garantizar la seguridad
                          de los datos de car&aacute;cter personal y evitar su
                          alteraci&oacute;n, p&eacute;rdida, tratamiento o
                          acceso no autorizado, habida cuenta del estado de la
                          tecnolog&iacute;a, la naturaleza de los datos
                          almacenados y los riesgos a que est&eacute;n
                          expuestos.
                        </div>
                      </li>
                      <li>
                        <p>
                          <strong style={{ fontWeight: "700" }}>
                            &iquest;Cu&aacute;les son sus derechos?
                            <br />
                          </strong>
                          El Cliente tiene los siguientes derechos en cuanto al
                          tratamiento de sus datos personales:
                        </p>
                        <ul>
                          <li>
                            <div>
                              Derecho a solicitar el acceso a sus datos
                              personales.
                            </div>
                          </li>
                          <li>
                            <div>
                              Derecho a solicitar su rectificaci&oacute;n si son
                              inexactos, o bien solicitar su supresi&oacute;n
                              (por ejemplo, si considera que ya no son
                              necesarios para los fines que fueron recogidos).
                            </div>
                          </li>
                          <li>
                            <div>
                              Derecho a solicitar la limitaci&oacute;n de su
                              tratamiento, siempre que se cumpla alguna de las
                              condiciones previstas en la normativa, en cuyo
                              caso &uacute;nicamente los conservaremos para el
                              ejercicio o la defensa de reclamaciones.
                            </div>
                          </li>
                          <li>
                            <div>
                              Derecho a cancelar sus datos, en cuyo caso
                              dejaremos de tratar sus datos, salvo por motivos
                              leg&iacute;timos imperiosos, o el ejercicio o la
                              defensa de posibles reclamaciones.
                            </div>
                          </li>
                          <li>
                            <div>
                              Derecho a oponerse al tratamiento para el cese en
                              el env&iacute;o de comunicaciones.
                            </div>
                          </li>
                          <li>
                            <div>Derecho a la portabilidad de los datos.</div>
                          </li>
                          <li>
                            <div>
                              Derecho a presentar una reclamaci&oacute;n ante la
                              Agencia Espa&ntilde;ola de Protecci&oacute;n de
                              Datos (autoridad de control en materia de
                              Protecci&oacute;n de Datos competente),
                              especialmente cuando no haya obtenido
                              satisfacci&oacute;n en el ejercicio de sus
                              derechos:{" "}
                              <Link to="https://www.aepd.es/agencia/en-que-podemos-ayudarte.html#section1">
                                https://www.aepd.es/agencia/en-que-podemos-ayudarte.html#section1
                              </Link>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div>
                          <strong>
                            &iquest;C&oacute;mo ejercitar mis derechos?
                            <br />
                          </strong>
                          Usted podr&aacute; ejercitar sus derechos u obtener
                          m&aacute;s informaci&oacute;n sobre los mismos
                          contactando con nosotros en los canales indicados al
                          inicio de la presente Pol&iacute;tica de Privacidad en
                          el apartado de identificaci&oacute;n del Responsable
                          del tratamiento.
                        </div>
                      </li>
                      <li>
                        <div>
                          <strong>
                            Redes sociales
                            <br />
                          </strong>
                          Los perfiles en redes sociales con los que cuente la
                          Empresa no supondr&aacute;n un tratamiento de datos
                          m&aacute;s all&aacute; del que la propia red social
                          permita para perfiles corporativos. La Empresa
                          podr&aacute; utilizar dichos perfiles para informar a
                          los usuarios de cada red social suscritos al perfil de
                          la empresa sobre sus actividades, eventos, seminarios,
                          ofertas, promociones o novedades en sus productos o
                          servicios, as&iacute; como compartir
                          informaci&oacute;n de inter&eacute;s sobre las
                          tem&aacute;ticas habituales de la empresa. La Empresa
                          no extraer&aacute; ning&uacute;n dato directamente de
                          la red social.
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                  setFieldValue("terms", false);
                }}
                color="primary"
              >
                Rechazar
              </Button>
              <Button
                onClick={() => {
                  handleClose();
                  setFieldValue("terms", true);
                }}
                color="primary"
                autoFocus
              >
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  };

  const handleSubmitForm = () => {
    // event.preventDefault();
    if (
      values.isBusiness &&
      (values.businessName.length <= 0 || values.cif <= 0)
    ) {
      console.error("Error");
    } else {
      if (values.isBusiness) {
        const data = {
          businessName: values.businessName,
          cif: values.cif,
          email: values.email,
          nickName: values.nickName,
          password: btoa(values.password),
          phoneNumber: values.phoneNumber,
          reEmail: values.reEmail,
          rePassword: values.rePassword,
          surName: values.surName,
          userName: values.userName,
        };
        signUp(data, dispatch, navigate);
      } else {
        const data = {
          businessName: null,
          cif: null,
          email: values.email,
          nickName: values.nickName,
          password: btoa(values.password),
          phoneNumber: values.phoneNumber,
          reEmail: values.reEmail,
          rePassword: values.rePassword,
          surName: values.surName,
          userName: values.userName,
        };
        signUp(data, dispatch, navigate);   
      }
    }
  };
  return (
    <>
      <div className="login__portada">
        <div className="register__center-div">
          <div class="container-fluid">
            <form onSubmit={handleSubmit} className="register__formBox ">
              <div className="mb-3 text-center">
                <img src={logo} alt="logo" width={300} height={100} />
              </div>
              <div className="container">
                <div className="row d-flex align-items-center">
                  <div className="col-xs-12 col-md-6">
                    <p>Registro:</p>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <FormControlLabel
                      className=""
                      control={
                        <Checkbox
                          id="isBusiness"
                          name="isBusiness"
                          checked={values.isBusiness}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          // onChange={handleChangeCheck}
                        />
                      }
                      label="¿ERES PROFESIONAL?"
                    />
                  </div>
                </div>
              </div>

              <div className="container text-center">
                <h6 className="text-left">Datos de acceso:</h6>
                <div className="row my-3">
                  <div className="col-xs-12 col-md-12">
                    <TextField
                      style={{ width: "100%" }}
                      id="nickName"
                      name="nickName"
                      //className="login__input"

                      label="Nombre de usuario"
                      value={values.nickName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.nickName && Boolean(errors.nickName)}
                      helperText={touched.nickName && errors.nickName}
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-xs-12 col-md-6 mt-2">
                    <TextField
                      style={{ width: "100%" }}
                      type="email"
                      id="email"
                      name="email"
                      //className="login__input"

                      label="Correo electrónico"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </div>
                  <div className="col-xs-12 col-md-6 mt-2">
                    <TextField
                      style={{ width: "100%" }}
                      type="email"
                      id="reEmail"
                      name="reEmail"
                      //className="login__input"

                      label="Repetir correo electrónico"
                      value={values.reEmail}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.reEmail && Boolean(errors.reEmail)}
                      helperText={touched.reEmail && errors.reEmail}
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-xs-12 col-md-6 mt-2">
                    <TextField
                      style={{ width: "100%" }}
                      type="password"
                      id="password"
                      name="password"
                      //className="login__input mt-4 mb-3"

                      label="Contraseña"
                      onBlur={handleBlur}
                      value={values.password}
                      onChange={handleChange}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </div>
                  <div className="col-xs-12 col-md-6 mt-2">
                    <TextField
                      style={{ width: "100%" }}
                      type="password"
                      id="rePassword"
                      name="rePassword"
                      //className="login__input mt-4 mb-3"

                      label="Repetir contraseña"
                      value={values.rePassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.rePassword && Boolean(errors.rePassword)}
                      helperText={touched.rePassword && errors.rePassword}
                    />
                  </div>
                </div>
                <h6 className="text-left">Datos personales:</h6>
                <div className="row my-3">
                  <div className="col-xs-12 col-md-6 mt-2">
                    <TextField
                      style={{ width: "100%" }}
                      id="userName"
                      name="userName"
                      //className="login__input"

                      label="Nombre"
                      value={values.userName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.userName && Boolean(errors.userName)}
                      helperText={touched.userName && errors.userName}
                    />
                  </div>
                  <div className="col-xs-12 col-md-6 mt-2">
                    <TextField
                      style={{ width: "100%" }}
                      id="surName"
                      name="surName"
                      //className="login__input"

                      label="Apellidos"
                      value={values.surName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.surName && Boolean(errors.surName)}
                      helperText={touched.userName && errors.userName}
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-xs-12 col-md-12">
                    <TextField
                      style={{ width: "100%" }}
                      type="tel"
                      id="phoneNumber"
                      name="phoneNumber"
                      //className="login__input mt-4 mb-3"

                      label="Nº de teléfono"
                      value={values.phoneNumber}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                  </div>
                </div>
                {values.isBusiness && (
                  <>
                    <h6 className="text-left">Datos empresariales:</h6>
                    <div className="row my-3">
                      <div className="col-xs-12 col-md-6 mt-2">
                        <TextField
                          style={{ width: "100%" }}
                          id="businessName"
                          name="businessName"
                          //className="login__input"

                          label="Nombre de empresa"
                          value={values.businessName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={
                            values.isBusiness && values.businessName.length <= 0
                          }
                          helperText={
                            values.isBusiness &&
                            values.businessName.length <= 0 &&
                            "Elampo obligatorio"
                          }
                        />
                      </div>
                      <div className="col-xs-12 col-md-6 mt-2">
                        <TextField
                          style={{ width: "100%" }}
                          id="cif"
                          name="cif"
                          //className="login__input"
                          label="CIF"
                          value={values.cif}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={values.isBusiness && values.cif.length <= 0}
                          helperText={
                            values.isBusiness &&
                            values.cif.length <= 0 &&
                            "El campo obligatorio"
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="container">{ModalAceptarTerminos()}</div>
              <div className="d-flex justify-content-center">
                <Button type="submit" variant="contained" disabled={!isValid}>
                  Registrarse <ArrowForwardIcon />
                </Button>
              </div>
              <hr />
              <div className="d-flex justify-content-center text-center mt-3">
                <div>
                  <p>¿Tienes cuenta?</p>
                  <Link className="login__anchor " to="/login">
                    INICIA SESIÓN AQUI
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterScreen;
