import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "../components/auth/Common/Layout";
import LoginScreen from "../components/auth/LoginScreen";
import RegisterScreen from "../components/auth/RegisterScreen";
import AdvancedSearch from "../pages/AdvancedSearch";
import Cart from "../pages/Cart";
import FunkoDetail from "../pages/FunkoDetail";
import Help from "../pages/Help";
import { Home } from "../pages/Home";
import MyAccount from "../pages/MyAccount";
import RequestFunko from "../pages/RequestFunko";
import FondoEU from "../pages/FondoEU";
import PublicProfile from "../pages/PublicProfile";
import { VerifySignUp } from "../components/auth/VerifySignUp";
import { VerifyAccount } from "../components/auth/VerifyAccount";
import { ForgotPassword } from "../components/auth/ForgotPassword";
import { ChangePassword } from "../components/auth/ChangePassword";
import CookieConsent from "../components/auth/Common/CookieConsent";
import { PaymentConfirmation } from "../components/auth/Common/PaymentConfirmation";
import PrivateRoute from "./PrivateRoute";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <CookieConsent />
      <Routes>
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/signup" element={<RegisterScreen />} />

        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/funko/:id"
          element={
            <Layout>
              <FunkoDetail />
            </Layout>
          }
        />
        <Route
          path="/user/:id"
          element={
            <Layout>
              <PublicProfile />
            </Layout>
          }
        />
        <Route
          path="/help"
          element={
            <Layout>
              <Help />
            </Layout>
          }
        />
        <Route
          path="/help/:id"
          element={
            <Layout>
              <Help />
            </Layout>
          }
        />
        <Route
          path="/profile/:id"
          element={
            <PrivateRoute>
            <Layout>
              <MyAccount />
            </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
            <Layout>
              <MyAccount />
            </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/search"
          element={
            <Layout>
              <AdvancedSearch />
            </Layout>
          }
        />
        <Route
          path="/checkout"
          element={
            <PrivateRoute>
            <Layout>
              <Cart />
            </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/checkout/payment-confirmation-page"
          element={
            <PrivateRoute>
            <PaymentConfirmation />
            </PrivateRoute>
          }
        />
        <Route
          path="/request/funko"
          element={
            <Layout>
              <RequestFunko />
            </Layout>
          }
        />
        <Route
          path="/fondo-eu"
          element={
            <Layout>
              <FondoEU />
            </Layout>
          }
        />
        <Route path="/verifysignup" element={<VerifySignUp />} />
        <Route path="/verifyaccount/:code" element={<VerifyAccount />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/changePassword/:code" element={<ChangePassword />} />
        {/* <Route
          path="/help"
          element={
            <PrivateRoute>
              <Layout>
                <Help />
              </Layout>
            </PrivateRoute>
          }
        /> */}
        <Route path="/" element={<Navigate replace to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
