import { types } from "../../types/types";

export const funkoDetailRequest = () => {
    return {
      type: types.funkoDetailRequest,
    };
  };
  
  export const funkoDetailSuccess = (funkoDetail) => {
    return {
      type: types.funkoDetailSuccess,
      payload: {
        funkoDetail,
      },
    };
  };
  
  export const funkoDetailFailure = (error) => {
    return {
      type: types.funkoDetailFailure,
      payload: {
        error,
      },
    };
  };