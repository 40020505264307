import { types } from "../../types/types";

export const advancedSearchRequest = () => {
  return {
    type: types.advancedSearchRequest,
  };
};

export const advancedSearchSuccess = (advancedSearch, totalPages,totalElements) => {
  return {
    type: types.advancedSearchSuccess,
    payload: {
      advancedSearch,
      totalPages,
      totalElements,
    },
  };
};

export const advancedSearchFailure = (error) => {
  return {
    type: types.advancedSearchFailure,
    payload: {
      error,
    },
  };
};

export const advancedSearchReset = () => {
  return {
    type: types.advancedSearchReset,
  };
};


export const advancedSearchTotalRequest = () => {
  return {
    type: types.advancedSearchTotalhRequest,
  };
};

export const advancedSearchTotalSuccess = (advancedSearchTotal) => {
  return {
    type: types.advancedSearchTotalSuccess,
    payload: {
      advancedSearchTotal,
    },
  };
};

export const advancedSearchTotalFailure = (error) => {
  return {
    type: types.advancedSearchTotalFailure,
    payload: {
      error,
    },
  };
};

export const advancedSearchTotalReset = () => {
  return {
    type: types.advancedSearchTotalReset,
  };
};
