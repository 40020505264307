import { Tooltip } from "@mui/material";
import React from "react";
import { AiFillCamera } from "react-icons/ai";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { imgUrl } from "../../../constants/api";
import { Box } from "@mui/system";

const TableSmall = ({ funkosTopSales }) => {
  const navigate = useNavigate();

  const handleFunkoDetail = (code) => {
    navigate(`/funko/${code}`, {
      replace: true,
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const tableRows = funkosTopSales?.map((rowData, index) => (
    <tr
      onClick={() => handleFunkoDetail(rowData?.codeFunko)}
      key={index}
      className={index % 2 === 0 ? "tableSmall even" : "tableSmall odd"}
    >
      <td className="tableSmall tableSmall__td-icon-camera">
        <Tooltip
          arrow
          placement="top"
          variant="plain"
          color="primary"
          title={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: 320,
                width: "100%",
                justifyContent: "center",
                p: 1,
              }}
            >
              <img
                style={{ objectFit: "contain" }}
                src={imgUrl + rowData.image}
                alt="funko"
              />
            </Box>
          }
        >
          {/* <AiFillCamera
            className={
              index !== 9
                ? "tableSmall__icon-camera ml-3"
                : "tableSmall__icon-camera"
            }
          /> */}
          <Link>
            <AiFillCamera className="tableSmall__icon-camera" />
          </Link>
        </Tooltip>
      </td>
      <td className="tableSmall">{index + 1} </td>
      <td
        className="tableSmall pl-4"
        style={{
          maxWidth: "200px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <Tooltip title={rowData.name.toUpperCase()} arrow placement="top-end">
          <span>
            {rowData.name.slice(0, 50).toUpperCase()}
            {rowData.name.length > 50 ? "..." : ""}
          </span>
        </Tooltip>
      </td>
      {/* <td className="tableSmall pl-4">{rowData.name.toUpperCase()}</td> */}
      <td
        className="tableSmall text-right"
        style={{
          paddingRight: "30px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {rowData.price} €{" "}
        <IoIosArrowDroprightCircle className="tableSmall__icon-arrow" />
      </td>
    </tr>
  ));

  return (
    <div>
      <table className="tableSmall__container">
        <thead className="text-center tableSmall__title">
          <tr>
            <th colSpan={4}>Top más vendidos</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    </div>
  );
};

export default TableSmall;
