import axios from "axios";
import { topSalesFailure, topSalesRequest, topSalesSuccess } from "../actions/topSalesActions/topSalesActions";
import { baseURL } from "../constants/api";

export const getTopSales = async (dispatch) => {
  dispatch(topSalesRequest());
  try {
    const response = await axios.get(`${baseURL}/cache/top10sales`);
    dispatch(topSalesSuccess(response.data));
    //return response.data;
  } catch (error) {
    dispatch(topSalesFailure(error.message));
    //return error ;
  }
};
