import { types } from "../../types/types";

export const profileInfoRequest = () => {
  return {
    type: types.profileInfoRequest,
  };
};

export const profileInfoSuccess = (profileInfo) => {
  return {
    type: types.profileInfoSuccess,
    payload: {
      profileInfo,
    },
  };
};

export const profileInfoClear = () => {
  return {
    type: types.profileInfoClear,
  };
};

export const profileInfoFailure = (error) => {
  return {
    type: types.profileInfoFailure,
    payload: {
      error,
    },
  };
};

export const profileUpdateRequest = () => {
  return {
    type: types.profileUpdateRequest,
  };
};

export const profileUpdateSuccess = (profileUpdate) => {
  return {
    type: types.profileUpdateSuccess,
    payload: {
      profileUpdate,
    },
  };
};

export const profileUpdateFailure = (error) => {
  return {
    type: types.profileUpdateFailure,
    payload: {
      error,
    },
  };
};
export const profileUpdatePasswordRequest = () => {
  return {
    type: types.profileUpdatePasswordRequest,
  };
};

export const profileUpdatePasswordSuccess = (profileUpdatePassword) => {
  return {
    type: types.profileUpdatePasswordSuccess,
    payload: {
      profileUpdatePassword,
    },
  };
};

export const profileUpdatePasswordFailure = (error) => {
  return {
    type: types.profileUpdatePasswordFailure,
    payload: {
      error,
    },
  };
};

export const profileHistoryRequest = () => {
  return {
    type: types.profileHistoryRequest,
  };
};

export const profileHistorySuccess = (profileHistory) => {
  return {
    type: types.profileHistorySuccess,
    payload: {
      profileHistory,
    },
  };
};

export const profileHistoryFailure = (error) => {
  return {
    type: types.profileHistoryFailure,
    payload: {
      error,
    },
  };
};

export const profileNotificationsRequest = () => {
  return {
    type: types.profileHistoryRequest,
  };
};

export const profileNotificationsSuccess = (profileNotifications) => {
  return {
    type: types.profileNotificationsSuccess,
    payload: {
      profileNotifications,
    },
  };
};

export const profileNotificationsFailure = (error) => {
  return {
    type: types.profileNotificationsFailure,
    payload: {
      error,
    },
  };
};

export const profileSearchAdsRequest = () => {
  return {
    type: types.profileSearchAdsRequest,
  };
};

export const profileSearchAdsSuccess = (profileSearchAds) => {
  return {
    type: types.profileSearchAdsSuccess,
    payload: {
      profileSearchAds,
    },
  };
};
export const profileSearchAdsClear = () => {
  return {
    type: types.profileSearchAdsClear,
    payload: null,
  };
};

export const profileSearchAdsFailure = (error) => {
  return {
    type: types.profileSearchAdsFailure,
    payload: {
      error,
    },
  };
};

export const profileGetFunkoByCodeRequest = () => {
  return {
    type: types.profileGetFunkoByCodeRequest,
  };
};

export const profileGetFunkoByCodeSuccess = (profileGetFunkoByCode) => {
  return {
    type: types.profileGetFunkoByCodeSuccess,
    payload: {
      profileGetFunkoByCode,
    },
  };
};

export const profileGetFunkoByCodeFailure = (error) => {
  return {
    type: types.profileGetFunkoByCodeFailure,
    payload: {
      error,
    },
  };
};

export const profileAdsRequest = () => {
  return {
    type: types.profileAdsRequest,
  };
};

export const profileAdsSuccess = (profileAds) => {
  return {
    type: types.profileAdsSuccess,
    payload: {
      profileAds,
    },
  };
};

export const profileAdsFailure = (error) => {
  return {
    type: types.profileAdsFailure,
    payload: {
      error,
    },
  };
};

export const profilePublishAdsRequest = () => {
  return {
    type: types.profilePublishAdsRequest,
  };
};

export const profilePublishAdsSuccess = (profilePublishAds) => {
  return {
    type: types.profilePublishAdsSuccess,
    payload: {
      profilePublishAds,
    },
  };
};

export const profilePublishAdsFailure = (error) => {
  return {
    type: types.profilePublishAdsailure,
    payload: {
      error,
    },
  };
};

export const profileUpdateAdsRequest = () => {
  return {
    type: types.profileUpdateAdsRequest,
  };
};

export const profileUpdateAdsSuccess = (profileUpdateAds) => {
  return {
    type: types.profileUpdateAdsSuccess,
    payload: {
      profileUpdateAds,
    },
  };
};

export const profileUpdateAdsFailure = (error) => {
  return {
    type: types.profileUpdateAdsailure,
    payload: {
      error,
    },
  };
};

export const profileDeleteAdsRequest = () => {
  return {
    type: types.profileDeleteAdsRequest,
  };
};

export const profileDeleteAdsSuccess = (profileDeleteAds) => {
  return {
    type: types.profileDeleteAdsSuccess,
    payload: {
      profileDeleteAds,
    },
  };
};

export const profileDeleteAdsFailure = (error) => {
  return {
    type: types.profileDeleteAdsFailure,
    payload: {
      error,
    },
  };
};

export const profileTransactionProcessRequest = () => {
  return {
    type: types.profileTransactionProcessRequest,
  };
};

export const profileTransactionProcessSuccess = (profileTransactionProcess) => {
  return {
    type: types.profileTransactionProcessSuccess,
    payload: {
      profileTransactionProcess,
    },
  };
};

export const profileTransactionProcessFailure = (error) => {
  return {
    type: types.profileTransactionProcessFailure,
    payload: {
      error,
    },
  };
};

export const profileUpdateImgRequest = () => {
  return {
    type: types.profileUpdateImgRequest,
  };
};

export const profileUpdateImgSuccess = (profileUpdateImg) => {
  return {
    type: types.profileUpdateImgSuccess,
    payload: {
      profileUpdateImg,
    },
  };
};

export const profileUpdateImgFailure = (error) => {
  return {
    type: types.profileUpdateImgFailure,
    payload: {
      error,
    },
  };
};

export const profileWalletRequest = () => {
  return {
    type: types.profileWalletRequest,
  };
};

export const profileWalletSuccess = (profileWallet) => {
  return {
    type: types.profileWalletSuccess,
    payload: {
      profileWallet,
    },
  };
};

export const profileWalletFailure = (error) => {
  return {
    type: types.profileWalletFailure,
    payload: {
      error,
    },
  };
};

export const profileActivateStripeRequest = () => {
  return {
    type: types.profileActivateStripeRequest,
  };
};

export const profileActivateStripeSuccess = (profileActivateStripe) => {
  return {
    type: types.profileActivateStripeSuccess,
    payload: {
      profileActivateStripe,
    },
  };
};

export const profileActivateStripeFailure = (error) => {
  return {
    type: types.profileActivateStripeFailure,
    payload: {
      error,
    },
  };
};

export const profileDepositStripeRequest = () => {
  return {
    type: types.profileDepositStripeRequest,
  };
};

export const profileDepositStripeSuccess = (profileDepositStripe) => {
  return {
    type: types.profileDepositStripeSuccess,
    payload: {
      profileDepositStripe,
    },
  };
};

export const profileDepositStripeFailure = (error) => {
  return {
    type: types.profileDepositStripeFailure,
    payload: {
      error,
    },
  };
};

export const profileDepositStripeCheckRequest = () => {
  return {
    type: types.profileDepositStripeCheckRequest,
  };
};

export const profileDepositStripeCheckSuccess = (profileDepositStripe) => {
  return {
    type: types.profileDepositStripeCheckSuccess,
    payload: {
      profileDepositStripe,
    },
  };
};

export const profileDepositStripeCheckFailure = (error) => {
  return {
    type: types.profileDepositStripeCheckFailure,
    payload: {
      error,
    },
  };
};

export const profileWithdrawStripeRequest = () => {
  return {
    type: types.profileWithdrawStripeRequest,
  };
};


export const profileWithdrawStripeSuccess = (profileWithdrawStripe) => {
  return {
    type: types.profileWithdrawStripeSuccess,
    payload: {
      profileWithdrawStripe,
    },
  };
};

export const profileWithdrawStripeFailure = (error) => {
  return {
    type: types.profileWithdrawStripeFailure,
    payload: {
      error,
    },
  };
};
