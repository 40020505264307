import { types } from "../../types/types";

export const funkosOnSaleRequest = () => {
    return {
      type: types.funkosOnSaleRequest,
    };
  };
  
  export const funkosOnSaleSuccess = (funkosOnSale) => {
    return {
      type: types.funkosOnSaleSuccess,
      payload: {
        funkosOnSale,
      },
    };
  };
  
  export const funkosOnSaleFailure = (error) => {
    return {
      type: types.funkosOnSaleFailure,
      payload: {
        error,
      },
    };
  };