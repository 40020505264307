import React from "react";
import ContentWrapper from "../../../containers/ContentWrapper";
import { Footer } from "./Footer";
import Header from "./Header";

const Layout = (props) => {
  return (
    <div className="layout-container">
      <Header />
      <ContentWrapper>{props.children}</ContentWrapper>
      <Footer />
    </div>
  );
};

export default Layout;
