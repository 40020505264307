import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UserProfile from "../components/MyAccount/UserProfile";
import WalletComponent from "../components/MyAccount/WalletComponent";
import History from "../components/MyAccount/History";
import Notifications from "../components/MyAccount/Notifications";
import Advertisement from "../components/MyAccount/Advertisement";
import { useParams } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const MyAccount = () => {
  const [value, setValue] = useState(0);
  const [orientation, setOrientation] = useState("vertical");
  const { id } = useParams();
  const tabStyle = {
    with: "3rem",
    borderColor: "#F6B036",
    "&:focus": { outlineColor: "#F6B036" },
    "&.Mui-selected": { color: "#fff", bgcolor: "#F6B036" },
  };

  useEffect(() => {
    if (id === "notificaciones") {
      setValue(2);
    }
    if (id === "monedero") {
      setValue(4);
    }
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setOrientation("horizontal");
      } else {
        setOrientation("vertical");
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [id]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (orientation === "vertical") {
    return (
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "100%",
          margin: "25px",
          padding: "25px",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            borderRight: 5,
            borderColor: "divider",
            height: "100%",
          }}
        >
          <Tab
            className="myAccount__tabpanel-tab"
            sx={tabStyle}
            label="Perfil"
            {...a11yProps(0)}
          />
          <Tab
            className="myAccount__tabpanel-tab"
            sx={tabStyle}
            label="Mis Anuncios"
            {...a11yProps(1)}
          />
          <Tab
            className="myAccount__tabpanel-tab"
            sx={tabStyle}
            label="Notificaciones"
            {...a11yProps(2)}
          />
          <Tab
            className="myAccount__tabpanel-tab"
            sx={tabStyle}
            label="Mis compras y ventas"
            {...a11yProps(3)}
          />
          <Tab
            className="myAccount__tabpanel-tab"
            sx={tabStyle}
            label="Monedero"
            {...a11yProps(4)}
          />
        </Tabs>
        <div className="myAccount__tabpanel-container">
          <TabPanel value={value} index={0}>
            <UserProfile />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Advertisement />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Notifications />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <History />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <WalletComponent />
          </TabPanel>
        </div>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "1rem",
          maxWidth: "1000px",
          margin: "0 auto",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          aria-label="visible arrows tabs example"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
            width: "100%",
            maxWidth: "1000px",
            flexGrow: 1,
            marginTop: "1rem",
          }}
        >
          <Tab
            className="myAccount__tabpanel-tab"
            sx={tabStyle}
            label="Perfil"
            {...a11yProps(0)}
          />
          <Tab
            className="myAccount__tabpanel-tab"
            sx={tabStyle}
            label="Mis Anuncios"
            {...a11yProps(1)}
          />
          <Tab
            className="myAccount__tabpanel-tab"
            sx={tabStyle}
            label="Notificaciones"
            {...a11yProps(2)}
          />
          <Tab
            className="myAccount__tabpanel-tab"
            sx={tabStyle}
            label="Mis compras y ventas"
            {...a11yProps(3)}
          />
          <Tab
            className="myAccount__tabpanel-tab"
            sx={tabStyle}
            label="Monedero"
            {...a11yProps(4)}
          />
        </Tabs>
        <div className="myAccount__tabpanel-container">
          <TabPanel value={value} index={0}>
            <UserProfile />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Advertisement />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Notifications />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <History />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <WalletComponent />
          </TabPanel>
        </div>
      </Box>
    );
  }
};

export default MyAccount;
