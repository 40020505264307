import axios from "axios";
import { funkosOnSaleFailure, funkosOnSaleRequest, funkosOnSaleSuccess } from "../actions/funkosOnSaleActions/funkosOnSaleActions";

import { baseURL } from "../constants/api";

export const getFunkosOnSale = async (id, dispatch) => {
  dispatch(funkosOnSaleRequest());
  try {
    const response = await axios.get(`${baseURL}/ad/find/code/${id}`);
    dispatch(funkosOnSaleSuccess(response.data));
    //return response.data;
  } catch (error) {
    dispatch(funkosOnSaleFailure(error.message));
    //return error ;
  }
};
