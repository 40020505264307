import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { login } from "../../services/authServices";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import logo from "../../assets/logos/logo-2.png";

const LoginScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (event) => {
    event.preventDefault();
    login(username, password, dispatch, navigate);
    // navigate("/home", {
    //   replace: true,
    // });
  };
  return (
    <>
      <div className="login__portada">
        <div className="login__center-div">
          <form onSubmit={handleLogin} className="login__formBox text-center">
            <div className="mb-3 text-center">
              <img src={logo} alt="logo" width={300} height={100} />
            </div>
            <div className="my-3 text-center">
              <h4>Acceder</h4>
            </div>
            <div className="my-3">
              <TextField
                id="username"
                name="username"
                className="login__input"
                required
                label="Nombre de usuario"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
              />
            </div>
            <div className="my-5">
              <TextField
                type="password"
                id="password"
                name="password"
                className="login__input mt-4 mb-3"
                required
                label="Contraseña"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
            <div className="d-flex justify-content-center">
              <Button type="submit" variant="contained">
                Ingresar <ArrowForwardIcon />
              </Button>
            </div>
            <hr />
            <div className="d-flex justify-content-center">
              <Link to="/forgotPassword">¿Has olvidado tu contraseña? </Link>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <div >
                <p >¿No tienes cuenta?</p>
                <Link className="login__anchor" to="/signup">
                  REGISTRATE AQUI
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
