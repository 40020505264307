import { Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/img/pop-cabecera.png";
import { forgotPassword } from "../../services/authServices";

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleForgotPassword = (event) => {
    event.preventDefault();
    forgotPassword(email, dispatch, navigate);
  };
  
  return (
    <div className="login__portada">
      <Grid item display={"flex"} justifyContent={"center"}>
        <form
          onSubmit={handleForgotPassword}
          className="login__formBox text-center"
        >
          <div className="mb-3 text-center">
            <img src={logo} alt="logo" width={300} height={100} />
          </div>

          <p className="h4">Olvido de contraseña</p>
          <div className="my-3">
            <TextField
              id="username"
              name="username"
              required
              label="Correo electrónico"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>

          <div className="d-flex justify-content-center">
            <Button type="submit" variant="contained">
              Recuperar contraseña
            </Button>
          </div>
          <hr />

          <div className="d-flex justify-content-center mt-3">
            <div>
              <p>¿No tienes cuenta?</p>
              <Link className="login__anchor" to="/signup">
                REGISTRATE AQUI
              </Link>
            </div>
          </div>
        </form>
      </Grid>
    </div>
  );
};
