// src/components/AdvancedSearch.js
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Pagination,
  Alert,
} from "@mui/material";
import styled from "@emotion/styled";
import { searchByFilters } from "../services/advancedSearchService";
import FunkoCard from "../components/funkocard/FunkoCard";
import { useTheme } from "@emotion/react";

//import { updateSearchParams, fetchData } from "../redux/actions";

const StyledFormControl = styled(FormControl)`
  border-radius: 8px;
`;

const AdvancedSearch = () => {
  const searchItems = useSelector(
    (state) => state.advancedSearchReducer?.searchItems
  );
  const totalPages = useSelector(
    (state) => state.advancedSearchReducer?.totalPages
  );
  const totalElements = useSelector(
    (state) => state.advancedSearchReducer?.totalElements
  );
  const dispatch = useDispatch();
  const [funkoName, setFunkoName] = useState("");
  const [license, setLicense] = useState("");
  const [series, setSeries] = useState("");
  const [edition, setEdition] = useState("");
  const [isLicenseChecked, setIsLicenseChecked] = useState(false);
  const [isSeriesChecked, setIsSeriesChecked] = useState(false);
  const [isEditionChecked, setIsEditionChecked] = useState(false);
  const [page, setPage] = useState(1);
  const [funkosPerPage, setFunkosPerPage] = useState(12);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const searchParams = {};

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "name") {
      setFunkoName(value);
    }
    if (name === "license") {
      setLicense(value);
    }
    if (name === "series") {
      setSeries(value);
    }
    if (name === "edition") {
      setEdition(value);
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === "licenseCheckbox") {
      setIsLicenseChecked(checked);
      if (!checked) {
        // Si desmarcamos la casilla de verificación, restablecemos el estado
        setLicense("");
      }
    }
    if (name === "seriesCheckbox") {
      setIsSeriesChecked(checked);
      if (!checked) {
        // Si desmarcamos la casilla de verificación, restablecemos el estado
        setSeries("");
      }
    }
    if (name === "editionCheckbox") {
      setIsEditionChecked(checked);
      if (!checked) {
        // Si desmarcamos la casilla de verificación, restablecemos el estado
        setEdition("");
      }
    }
  };

  const handleFunkosPerPageChange = (event) => {
    setFunkosPerPage(event.target.value);
  };

  const handleSubmit = () => {
    // dispatch(getAdvancedSearchName(funkoName));
    dispatch(
      searchByFilters(
        funkoName,
        series,
        license,
        edition,
        page - 1,
        funkosPerPage
      )
    );
  };
  const handlePageChange = (event, value) => {
    setPage(value);
    dispatch(
      searchByFilters(
        funkoName,
        series,
        license,
        edition,
        value - 1,
        funkosPerPage
      )
    );
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "90%",
          margin: "auto",
          border: "solid #F6B036 3px",
          borderRadius: "25px",
          marginTop: "30px",
          padding: "30px",
        }}
      >
        <Typography
          variant="h5"
          sx={{ marginBottom: 2, textDecoration: "underline solid 2px" }}
        >
          Búsqueda avanzada
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <StyledFormControl fullWidth>
              <TextField
                label="Nombre"
                name="name"
                value={searchParams.name}
                onChange={handleInputChange}
                variant="outlined"
              />
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <StyledFormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    name="licenseCheckbox"
                    checked={isLicenseChecked}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Licencia"
              />
              <TextField
                label="Licencia"
                name="license"
                value={license}
                onChange={handleInputChange}
                variant="outlined"
                disabled={!isLicenseChecked} // Aquí se deshabilita el campo si la casilla no está marcada
              />
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <StyledFormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    name="seriesCheckbox"
                    checked={isSeriesChecked}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Serie"
              />
              <TextField
                label="Serie"
                name="series"
                value={series}
                onChange={handleInputChange}
                variant="outlined"
                disabled={!isSeriesChecked} // Aquí se deshabilita el campo si la casilla no está marcada
              />
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <StyledFormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    name="editionCheckbox"
                    checked={isEditionChecked}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Edición"
              />
              <TextField
                label="Edición"
                name="edition"
                value={edition}
                onChange={handleInputChange}
                variant="outlined"
                disabled={!isEditionChecked} // Aquí se deshabilita el campo si la casilla no está marcada
              />
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ padding: "12px", width: "100%", fontSize: "1.1rem" }}
              >
                Buscar
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <FormControl variant="outlined" sx={{ minWidth: "100%" }}>
                <InputLabel
                  id="funkos-per-page-label"
                  style={{ fontSize: "1rem" }}
                >
                  Funkos por página
                </InputLabel>
                <Select
                  labelId="funkos-per-page-label"
                  id="funkos-per-page-select"
                  value={funkosPerPage}
                  onChange={handleFunkosPerPageChange}
                  label="Funkos por página"
                  style={{ fontSize: "1rem" }}
                >
                  <MenuItem value={12}>12</MenuItem>
                  <MenuItem value={24}>24</MenuItem>
                  <MenuItem value={48}>48</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: isSmallScreen ? "center" : "end",
              }}
            >
              <Typography marginTop={2} color="primary">{`Total Funkos: ${
                totalElements ? totalElements : 0
              }`}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "80%",
          margin: "auto",

          marginTop: "30px",
          //  padding: "30px",
          //  background: '#F6B036'
        }}
      >
        <Grid
          container
          spacing={isExtraSmallScreen ? 4 : isSmallScreen ? 4 : 2}
          display={"flex"}
          justifyContent={"center"}
        >
          {searchItems && searchItems.length > 0 ? (
            searchItems.map((card, index) => (
              <>
                <Grid
                  item
                  display={!isSmallScreen && "flex"}
                  justifyContent={!isSmallScreen && "center"}
                  xs={4}
                  sm={6}
                  md={6}
                  lg={4}
                  key={index}
                  mr={isSmallScreen ? 7 : 0}
                >
                  <FunkoCard advancedSearch funko={card} />
                </Grid>
              </>
            ))
          ) : (
            <Grid item xs={12} sm={12}>
              <Alert
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                icon={false}
                severity="warning"
              >
                {" "}
                No se encontraron Funkos.
              </Alert>
            </Grid>
          )}
        </Grid>
      </Box>
      {totalPages && totalPages > 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          <Pagination
            color="primary"
            count={totalPages}
            page={page}
            onChange={handlePageChange}
          />
        </Box>
      )}
    </>
  );
};

export default AdvancedSearch;
