export const HealthConditions = {
    BOX_DAMAGED:  1,
    BOX_WITH_SCRATCH: 2,
    BOX_DISCOLORED: 3,
    BOX_OPENED: 4,
    FUNKO_DAMAGED: 5,
    FUNKO_DISCOLORED: 6,
    FUNKO_BROKEN: 7,
    PLASTIC_WINDOW_DAMAGED: 8,
}
