import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  Collapse,
  tableCellClasses,
  styled,
  Button,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { BsFillCartDashFill, BsFillCartPlusFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Health from "./Health";

import useItemInCart from "../../../hooks/useItemInCart";
import useCart from "../../../hooks/useCart";

function CustomTable({ columns, rows, collapsibleContent }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(columns[0].id);
  const [openRowId, setOpenRowId] = useState(null);
  const { isItemInCart } = useItemInCart();
  const { addToCart, removeFromCart } = useCart();
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F6B036",
    },
  }));

  const handleSortClick = (columnId) => {
    const isDesc = orderBy === columnId && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(columnId);
  };

  const handleRowClick = (rowId) => {
    setOpenRowId(openRowId === rowId ? null : rowId);
  };
  const handleFunkoCart = (row) => {
    addToCart(row);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const sortedRows = rows.sort(getComparator(order, orderBy));

  return (
    <TableContainer style={{ borderRadius: "20px 20px 20px 20px" }}>
      <Table className="custom-table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell
                className="text-center"
                key={column.id}
                sortDirection={orderBy === column.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : "asc"}
                  onClick={() => handleSortClick(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </StyledTableCell>
            ))}
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((row) => (
            <React.Fragment key={row.id}>
              <TableRow hover onClick={() => handleRowClick(row.id)}>
                {columns.map((column) => (
                  <TableCell
                    className="text-center"
                    key={`${row.id}-${column.id}`}
                  >
                    {column.id === "userNickname" ? (
                      <Link to={`/user/${row[column.id]}`}>
                        {" "}
                        {row[column.id]}
                      </Link>
                    ) : column.id === "health" ? (
                      <Health health={row[column.id]} />
                    ) : column.id === "price" ? (
                      <> <span className="custom-table__price">{row[column.id]} €</span> </>
                    ) : (
                      <> {row[column.id]}</>
                    )}
                  </TableCell>
                ))}
                <TableCell>
                  {isItemInCart(row.advertisementNumber) ? (
                    <Button
                      variant="contained"
                      color="error"
                      className="custom_table-buttonCart"
                      onClick={() => removeFromCart(row.advertisementNumber)}
                    >
                      <BsFillCartDashFill />
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="success"
                      className="custom_table-buttonCart"
                      onClick={() => handleFunkoCart(row)}
                    >
                      <BsFillCartPlusFill />
                    </Button>
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => handleRowClick(row.id)}
                  >
                    {openRowId === row.id ? (
                      <KeyboardArrowUp />
                    ) : (
                      <KeyboardArrowDown />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={columns.length + 1}
                >
                  <Collapse
                    in={openRowId === row.id}
                    timeout="auto"
                    unmountOnExit
                  >
                    {collapsibleContent(row)}
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CustomTable;
