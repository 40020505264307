import React, { useEffect } from "react";

const MondialRelay = ({ selectedDeliveryCode, setSelectedDeliveryCode }) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  const CP = currentUser.shippingAddress.postalCode;

  useEffect(() => {
    const loadDependencies = async () => {
      if (!window.jQuery) {
        await loadScript("/jquery/dist/jquery.min.js");
        window.jQuery = window.$;
      }

      if (!window.jQuery.fn.MR_ParcelShopPicker) {
        await loadScript(
          "https://widget.mondialrelay.com/parcelshop-picker/jquery.plugin.mondialrelay.parcelshoppicker.min.js"
        );
      }

      if (window.jQuery) {
        window.jQuery("#Target_Widget").MR_ParcelShopPicker({
          Target: "#Target_Widget",
          TargetDisplay: "#TargetDisplay_Widget",
          TargetDisplayInfoPR: "#TargetDisplayInfoPR_Widget",
          Brand: "CC22AS2J",
          Country: "ES",
          PostCode: CP,
          Theme: "mondialrelay",
          ShowResultsOnMap: true,
          DisplayMapInfo: true,
          Responsive: true,
          OnParcelShopSelected: function (data) {
            window.jQuery("#cb_ID").html(data.ID);
            window.jQuery("#cb_Nom").html(data.Nom);
            window
              .jQuery("#cb_Adresse")
              .html(data.Adresse1 + " " + data.Adresse2);
            window.jQuery("#cb_CP").html(data.CP);
            window.jQuery("#cb_Ville").html(data.Ville);
            window.jQuery("#cb_Pays").html(data.Pays);
            setSelectedDeliveryCode(`ES-${data.ID}`);
          },
        });
      }
    };

    const loadScript = (url) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    loadDependencies();
  }, [CP, setSelectedDeliveryCode]);

  return (
    <div className="container mobile-map">
      <div id="Target_Widget"></div>
      <div id="TargetDisplay_Widget"></div>
      <div id="TargetDisplayInfoPR_Widget"></div>
      {selectedDeliveryCode && (
        <p>Código de entrega seleccionado: {selectedDeliveryCode}</p>
      )}
    </div>
  );
};

export default MondialRelay;
