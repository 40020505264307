import { types } from "../../types/types";

export const requestFunkoRequest = () => {
    return {
      type: types.requestFunkoRequest,
    };
  };
  
  export const requestFunkoSuccess = (requestFunko) => {
    return {
      type: types.requestFunkoSuccess,
      payload: {
        requestFunko,
      },
    };
  };
  
  export const requestFunkoFailure = (error) => {
    return {
      type: types.requestFunkoFailure,
      payload: {
        error,
      },
    };
  };