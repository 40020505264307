/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { getHelp } from "../services/helpServices";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Help = () => {
  const helpList = useSelector((state) => state?.helpReducer?.help);
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [orientation, setOrientation] = useState("vertical");
  const { id } = useParams();
  const tabStyle = {
    with: "3rem",
    borderColor: "#F6B036",
    "&:focus": { outlineColor: "#F6B036" },
    "&.Mui-selected": { color: "#fff", bgcolor: "#F6B036" },
  };

  useEffect(() => {
    if (id) {
      if (id === "Politica de privacidad") {
        setValue(6);
      } else if (id === "Terminos y condiciones") {
        setValue(8);
      } else if (id === "Ventas") {
        setValue(0);
      } else if (id === "faqs") {
        setValue(8);
      } else {
        setValue(8);
      }
    }
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setOrientation("horizontal");
      } else {
        setOrientation("vertical");
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabName = (name) => {
    if (name === "guia_venta") {
      return "como vender en verypos!";
    } else if (name === "pago") {
      return "pago seguro";
    } else if (name === "guiaVenta") {
      return "como vender en verypos!";
    } else if (name === "enviar") {
      return "como enviar en verypos!";
    } else if (name === "verificacion") {
      return "verificación";
    } else if (name === "problemas") {
      return "gestión de problemas";
    } else if (name === "privacidad") {
      return "política de privacidad";
    } else if (name === "faq") {
      return "preguntas frecuentes";
    } else {
      return name;
    }
  };

  useEffect(() => {
    getHelp(dispatch);
  }, [dispatch]);

  if (orientation === "vertical") {
    return (
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "100%",
          margin: "25px",
          padding: "25px",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            borderRight: 5,
            borderColor: "divider",
            height: "100%",
          }}
        >
          {helpList?.map((h, index) => (
            <Tab
              className="help__tabpanel-tab"
              sx={tabStyle}
              label={tabName(h.key)}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        <div className="help__tabpanel-container">
          {helpList?.map((h, index) => (
            <TabPanel
              //className="help__tabpanel-container"

              value={value}
              index={index}
            >
              {parse(h.value)}
            </TabPanel>
          ))}
        </div>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "1rem",
          maxWidth: "1000px",
          margin: "0 auto",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          aria-label="visible arrows tabs example"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
            width: "100%",
            maxWidth: "1000px",
            flexGrow: 1,
            marginTop: "1rem",
          }}
        >
          {helpList?.map((h, index) => (
            <Tab
              className="help__tabpanel-tab mt-3 mb-2"
              sx={tabStyle}
              label={tabName(h.key)}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        <div
          className="help__tabpanel-container"
          style={{ flexGrow: 1, width: "100%", maxWidth: "1000px" }}
        >
          {helpList?.map((h, index) => (
            <TabPanel
              value={value}
              index={index}
              sx={{ flexGrow: 1, width: "100%" }}
            >
              {parse(h.value)}
            </TabPanel>
          ))}
        </div>
      </Box>
    );
  }
};

export default Help;
