import React, { useEffect } from "react";
import {
  CardContent,
  Typography,
  CardActions,
  Button,
  Box,
  keyframes,
  Alert,
} from "@mui/material";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications, processAds } from "../../services/myAccountService";
import { Link } from "react-router-dom";

const ShadowBox = styled(Box)(({ theme }) => ({
  minWidth: 275,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1], // Cambia el valor numérico para ajustar la profundidad de la sombra
}));

const pulseAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

const GreenDot = styled("span")`
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;

  animation: ${pulseAnimation} 1.5s infinite;
`;
const Notifications = () => {
  const profileNotifications = useSelector(
    (state) => state.myAccountReducer.profileNotifications
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  if (!profileNotifications || profileNotifications.length <= 0) {
    return (
      <Alert
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        icon={false}
        severity="warning"
      >
        {" "}
        No tiene notificaciones
      </Alert>
    );
  } else {
    return (
      <>
        {profileNotifications.length > 0 &&
          profileNotifications.map((notification) => (
            <ShadowBox>
              <CardContent>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    <GreenDot />
                    Estado: {notification.status}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    ID - {notification.idNotification}
                  </Typography>
                </Box>

                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {notification.description}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button
                  component={Link}
                  to={`/transactions/${notification.transactionCode}.pdf`}
                  target="_blank"
                  variant="contained"
                  size="small"
                  color="secondary"
                >
                  Detalle
                </Button>
                <Button
                  sx={{ marginLeft: "10px" }}
                  variant="contained"
                  size="small"
                  onClick={() =>
                    dispatch(processAds(notification.transactionCode))
                  }
                >
                  Procesar Pedido
                </Button>
              </CardActions>
            </ShadowBox>
          ))}
      </>
    );
  }
};

export default Notifications;
