import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Container,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Table,
  Paper,
  TableBody,
  Divider,
  IconButton,
  Fade,
  Tooltip,
  useMediaQuery,
  Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import HelpIcon from "@mui/icons-material/Help";
import FunkoCard from "../funkocard/FunkoCard";
import {
  deleteAds,
  getProfileAds,
  getProfileFunkoByCode,
  getSearchFunkosAds,
  publishAds,
  updateAds,
} from "../../services/myAccountService";
import { profileSearchAdsClear } from "../../actions/myAccountActions/myAccountActions";
import styled from "@emotion/styled";
import ConfirmDialog from "../auth/Common/ConfirmDialog";
import { Link } from "react-router-dom";
import { imgUrl } from "../../constants/api";
import { HealthConditions } from "../../constants/healthConditions";
// Reemplaza esta función con la acción de Redux que deseas despachar

const validationSchema = Yup.object().shape({
  description: Yup.string().required("La descripción es requerido"),
  price: Yup.number().required("El precio es requerido"),
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.black,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6b03669",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Advertisement = () => {
  const options = useSelector(
    (state) => state.myAccountReducer.profileSearchAds
  );
  const funkoSelected = useSelector(
    (state) => state.myAccountReducer.profileGetFunkoByCode
  );
  const profileAds = useSelector((state) => state.myAccountReducer.profileAds);
  const profilePublishAdsSuccess = useSelector(
    (state) => state.myAccountReducer.profilePublishAdsSuccess
  );
  const profileDeleteAdsSuccess = useSelector(
    (state) => state.myAccountReducer.profileDeleteAdsSuccess
  );
  const profileUpdateSuccess = useSelector(
    (state) => state.myAccountReducer.profileUpdateAdsSuccess
  );
  const userName = JSON.parse(sessionStorage.getItem("currentUser")).userName;
  const commission = JSON.parse(
    sessionStorage.getItem("currentUser")
  ).commission;
  const accountType = JSON.parse(
    sessionStorage.getItem("currentUser")
  ).accountType;
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = useState(false);
  const [showCreateAds, setShowCreateAds] = useState(false);
  const [codeToDelete, setCodeToDelete] = useState(null);
  const [codeToUpdate, setCodeToUpdate] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    dispatch(deleteAds(codeToDelete));
    setCodeToDelete(null);
    setEditMode(false);
    setCodeToUpdate(null);
    formik.resetForm();
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getProfileAds());
  }, [
    dispatch,
    profilePublishAdsSuccess,
    profileDeleteAdsSuccess,
    profileUpdateSuccess,
  ]);

  const submitForm = (values) => {
    if (editMode) {
      const newAd = {
        healthConditions: [
          values.cajaDanada && {
            description: null,
            index: "1",
            value: null,
          },

          values.cajaArana && {
            description: null,
            index: "2",
            value: null,
          },
          values.cajaDescolorida && {
            description: null,
            index: "3",
            value: null,
          },
          values.cajaAbierta && {
            description: null,
            index: "4",
            value: null,
          },
          values.funkoDanado && {
            description: null,
            index: "5",
            value: null,
          },
          values.funkoDescolorido && {
            description: null,
            index: "6",
            value: null,
          },
          values.funkoRoto && {
            description: null,
            index: "7",
            value: null,
          },
          values.plasticoCajaDanado && {
            description: null,
            index: "8",
            value: null,
          },
        ]?.filter((item) => item !== false),
        imagesBytes: [
          values.image1?.split("base64,")[1],
          values.image2?.split("base64,")[1],
        ],
        description: values.description,
        advertisementNumber: codeToUpdate,
        price: values.price,
        hasBox: values.tieneCaja,
        hasProtector: values.tieneProtector,
      };

      setCodeToUpdate(null);
      setEditMode(false);
      dispatch(updateAds(newAd));
    } else {
      const newAd = {
        status: "ACTIVE",
        userNickname: userName,
        healthConditions: [
          values.cajaDanada && {
            description: null,
            index: "1",
            value: null,
          },

          values.cajaArana && {
            description: null,
            index: "2",
            value: null,
          },
          values.cajaDescolorida && {
            description: null,
            index: "3",
            value: null,
          },
          values.cajaAbierta && {
            description: null,
            index: "4",
            value: null,
          },
          values.funkoDanado && {
            description: null,
            index: "5",
            value: null,
          },
          values.funkoDescolorido && {
            description: null,
            index: "6",
            value: null,
          },
          values.funkoRoto && {
            description: null,
            index: "7",
            value: null,
          },
          values.plasticoCajaDanado && {
            description: null,
            index: "8",
            value: null,
          },
        ]?.filter((item) => item !== false),
        imagesBytes: [
          values.image1?.split("base64,")[1],
          values.image2?.split("base64,")[1],
        ],
        description: values.description,
        funkoCode: funkoSelected.codeFunko,
        funkoName: funkoSelected.name,
        price: values.price,
        hasBox: values.tieneCaja,
        hasProtector: values.tieneProtector,
      };
      dispatch(publishAds(newAd));
    }
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      description: "",
      price: "",
      tieneCaja: true,
      cajaDanada: false,
      cajaArana: false,
      cajaDescolorida: false,
      cajaAbierta: false,
      tieneProtector: false,
      funkoDanado: false,
      funkoDescolorido: false,
      funkoRoto: false,
      plasticoCajaDanado: false,
      image1: "",
      image2: "",
    },
    validationSchema,
    onSubmit: submitForm,
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      formik.setFieldValue(event.target.name, reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleEdit = (advertisementNumber) => {
    setEditMode(true);
    setCodeToUpdate(advertisementNumber);
    // Encuentra el anuncio en 'profileAds' utilizando el 'advertisementNumber'
    const adToEdit = profileAds.find(
      (ad) => ad.advertisementNumber === advertisementNumber
    );
    dispatch(getProfileFunkoByCode(adToEdit.funkoCode));

    // Comprueba si se ha encontrado el anuncio
    if (adToEdit) {
      // Actualiza los valores del formulario con los datos del anuncio
      formik.setValues({
        description: adToEdit.description,
        price: adToEdit.price,
        tieneCaja: adToEdit.hasBox,
        cajaDanada: adToEdit?.healthConditions?.some(
          (h) => h.index === HealthConditions.BOX_DAMAGED
        ),
        cajaArana: adToEdit?.healthConditions?.some(
          (h) => h.index === HealthConditions.BOX_WITH_SCRATCH
        ),
        cajaDescolorida: adToEdit?.healthConditions?.some(
          (h) => h.index === HealthConditions.BOX_DISCOLORED
        ),
        cajaAbierta: adToEdit?.healthConditions?.some(
          (h) => h.index === HealthConditions.BOX_OPENED
        ),
        tieneProtector: adToEdit.hasProtector,
        funkoDanado: adToEdit?.healthConditions?.some(
          (h) => h.index === HealthConditions.FUNKO_DAMAGED
        ),
        funkoDescolorido: adToEdit?.healthConditions?.some(
          (h) => h.index === HealthConditions.FUNKO_DISCOLORED
        ),
        funkoRoto: adToEdit?.healthConditions?.some(
          (h) => h.index === HealthConditions.FUNKO_BROKEN
        ),
        plasticoCajaDanado: adToEdit?.healthConditions?.some(
          (h) => h.index === HealthConditions.PLASTIC_WINDOW_DAMAGED
        ),
        image1:
          adToEdit?.images?.length > 0
            ? `${imgUrl}${adToEdit.images[0]}`
            : null,
        image2:
          adToEdit?.images?.length > 1
            ? `${imgUrl}${adToEdit.images[1]}`
            : null,
        // Agrega aquí los demás campos del formulario que necesites actualizar
        // ...
      });

      // Muestra el formulario de edición si aún no es visible
      if (!showCreateAds) {
        setShowCreateAds(true);
      }
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        padding: "0",
        marginLeft: "10px",
      }}
    >
      <Grid container spacing={2}>
        <Grid xs={12} marginTop={6}>
          <Typography variant="h4" textAlign={"center"}>
            Mis anuncios
          </Typography>
          <Divider className="my-2" />
          {profileAds && profileAds.length > 0 ? (
            <TableContainer component={Paper} className="table-container">
              <Table>
                <TableHead>
                  <StyledTableRow className="hide-mobile-thead">
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      FUNKO
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      DESCRIPCIÓN
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      PRECIO
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      SALUD
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      PUBLICACIÓN
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      ACCIONES
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {profileAds &&
                    profileAds.length > 0 &&
                    profileAds.map((ads, index) => (
                      <StyledTableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          data-label="FUNKO:"
                          sx={{ textAlign: "center" }}
                          component="th"
                          scope="row"
                        >
                          {ads.funkoName}
                        </TableCell>
                        <TableCell
                          data-label="DESCRIPCIÓN:"
                          sx={{ textAlign: "center" }}
                          component="th"
                          scope="row"
                        >
                          {ads.description}
                        </TableCell>
                        <TableCell
                          data-label="PRECIO:"
                          sx={{ textAlign: "center" }}
                          component="th"
                          scope="row"
                        >
                          {ads.price} €
                        </TableCell>
                        <TableCell
                          data-label="SALUD:"
                          sx={{ textAlign: "center" }}
                          component="th"
                          scope="row"
                        >
                          {ads.health} %
                        </TableCell>
                        <TableCell
                          data-label="PUBLICACIÓN:"
                          sx={{ textAlign: "center" }}
                          component="th"
                          scope="row"
                        >
                          {ads.publishDate}
                        </TableCell>
                        <TableCell
                          data-label="ACCIONES:"
                          sx={{ textAlign: "center" }}
                          component="th"
                          scope="row"
                        >
                          <IconButton
                            aria-label="delete"
                            color="error"
                            onClick={() => {
                              setCodeToDelete(ads.advertisementNumber);
                              handleClickOpen();
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <IconButton
                            aria-label="update"
                            color="info"
                            onClick={() => {
                              handleEdit(ads.advertisementNumber);
                            }}
                          >
                            <ModeEditIcon />
                          </IconButton>
                        </TableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Alert
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              icon={false}
              severity="warning"
            >
              {" "}
              No tiene anuncios publicados
            </Alert>
          )}
        </Grid>
        {editMode ? (
          <Button
            sx={{ marginTop: ".5rem" }}
            color="error"
            variant="contained"
            fullWidth
            onClick={() => {
              setEditMode(false);
              formik.resetForm();
            }}
          >
            Cancelar edición
          </Button>
        ) : (
          <Button
            sx={{ marginTop: ".5rem" }}
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => setShowCreateAds(!showCreateAds)}
          >
            Añadir anuncio
          </Button>
        )}

        <Fade in={showCreateAds}>
          <Grid container spacing={2}>
            <Grid xs={12} md={4} marginTop={6}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Grid container spacing={2}>
                  {!editMode && (
                    <Grid item xs={10} ml={3}>
                      <Autocomplete
                        options={options || []}
                        onChange={(event, value) => {
                          if (!value) {
                            dispatch(profileSearchAdsClear());
                          } else {
                            dispatch(getProfileFunkoByCode(value.value));

                            value.value = null;
                          }
                        }}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Busca tu funko"
                            variant="outlined"
                            size="small"
                            fullWidth
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              //console.log(inputValue)
                              dispatch(getSearchFunkosAds(inputValue));
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}

                  <Grid
                    container
                    justifyContent={"center"}
                    xs={12}
                    sx={{ marginLeft: "12px" }}
                  >
                    <FunkoCard creationAd funko={funkoSelected} />
                    <Link to={`/request/funko`}>
                      ¿No encuentras el funko que buscas?
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ flexGrow: 1, mt: 4 }}>
                  <Grid container spacing={2}>
                    {accountType !== "BUSINESS" && (
                      <>
                        <Grid item xs={12} md={5}>
                          <Typography variant="subtitle1">Imagen 1:</Typography>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="image1"
                            type="file"
                            name="image1"
                            onChange={handleImageChange}
                          />
                          <label htmlFor="image1">
                            <Button variant="contained" component="span">
                              <CameraAltIcon />
                            </Button>
                          </label>
                          {formik.values.image1 && (
                            <Box mt={2}>
                              <img
                                key={`${funkoSelected?.codeFunko}-1`}
                                src={formik.values.image1}
                                alt="Imagen 1"
                                style={{ maxWidth: "100%", maxHeight: "200px" }}
                              />
                            </Box>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="subtitle1">Imagen 2:</Typography>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="image2"
                            type="file"
                            name="image2"
                            onChange={handleImageChange}
                          />
                          <label htmlFor="image2">
                            <Button variant="contained" component="span">
                              <CameraAltIcon />
                            </Button>
                          </label>
                          {formik.values.image2 && (
                            <Box mt={2}>
                              <img
                                key={`${funkoSelected?.codeFunko}-2`}
                                src={formik.values.image2}
                                alt="Imagen 2"
                                style={{ maxWidth: "100%", maxHeight: "200px" }}
                              />
                            </Box>
                          )}
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="description"
                        name="description"
                        label="Descripción"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.description &&
                          Boolean(formik.errors.description)
                        }
                        helperText={
                          formik.touched.description &&
                          formik.errors.description
                        }
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <TextField
                        type={"number"}
                        fullWidth
                        id="price"
                        name="price"
                        label="Precio"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.price && Boolean(formik.errors.price)
                        }
                        helperText={formik.touched.price && formik.errors.price}
                      />
                      {formik.values.price > 0 ? (
                        <small style={{ color: "#F6B036" }}>
                          Precio final de venta:{" "}
                          {(
                            formik.values.price *
                            (1 + commission / 100)
                          ).toFixed(2)}{" "}
                          €
                        </small>
                      ) : (
                        <small style={{ color: "#F6B036" }}>
                          Precio final = Precio + {commission}%
                        </small>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <Tooltip
                        placement="top-start"
                        title="Este precio simboliza el valor de tendecia del producto"
                      >
                        <Typography
                          variant={isMobile ? "h4" : "h3"}
                          color="primary"
                          style={{ marginTop: isMobile ? "10px" : undefined }}
                        >
                          {funkoSelected &&
                          funkoSelected?.funkoExtendedInfo?.avgPrice
                            ? funkoSelected?.funkoExtendedInfo?.avgPrice + " €"
                            : "-"}{" "}
                          <HelpIcon />
                        </Typography>
                      </Tooltip>
                    </Grid>
                    {accountType !== "BUSINESS" && (
                      <>
                        <Grid container item xs={12} md={5}>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formik.values.tieneCaja}
                                  onChange={formik.handleChange}
                                  name="tieneCaja"
                                />
                              }
                              label="Tiene Caja"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formik.values.cajaDanada}
                                  onChange={formik.handleChange}
                                  name="cajaDanada"
                                />
                              }
                              label="Caja dañada"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formik.values.cajaArana}
                                  onChange={formik.handleChange}
                                  name="cajaArana"
                                />
                              }
                              label="Caja arañada"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formik.values.cajaDescolorida}
                                  onChange={formik.handleChange}
                                  name="cajaDescolorida"
                                />
                              }
                              label="Caja descoloreada"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formik.values.cajaAbierta}
                                  onChange={formik.handleChange}
                                  name="cajaAbierta"
                                />
                              }
                              label="Caja abierta"
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formik.values.tieneProtector}
                                  onChange={formik.handleChange}
                                  name="tieneProtector"
                                />
                              }
                              label="Tiene Protector"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formik.values.funkoDanado}
                                  onChange={formik.handleChange}
                                  name="funkoDanado"
                                />
                              }
                              label="Funko dañado"
                            />
                            <Grid item xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={formik.values.funkoDescolorido}
                                    onChange={formik.handleChange}
                                    name="funkoDescolorido"
                                  />
                                }
                                label="Funko descoloreado"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={formik.values.funkoRoto}
                                    onChange={formik.handleChange}
                                    name="funkoRoto"
                                  />
                                }
                                label="Funko roto"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={formik.values.plasticoCajaDanado}
                                    onChange={formik.handleChange}
                                    name="plasticoCajaDanado"
                                  />
                                }
                                label="Plástico de la caja dañado"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {editMode ? (
                      <Grid item xs={12}>
                        <Button
                          color="primary"
                          variant="contained"
                          fullWidth
                          type="submit"
                        >
                          Actualizar anuncio
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Button
                          color="primary"
                          variant="contained"
                          fullWidth
                          type="submit"
                        >
                          Publicar anuncio
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Fade>
      </Grid>
      <ConfirmDialog
        open={open}
        title="Eliminar Anuncio"
        message="¿Estás seguro de que deseas eliminar este anuncio?"
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </Container>
  );
};

export default Advertisement;
