import { types } from "../../types/types";

export const latestReleasesReducer = (state = {}, action) => {
  switch (action.type) {
    case types.latestReleasesSuccess:
      return {
        ...action.payload,
      };
    case types.latestReleasesFailure:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};
