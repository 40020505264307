import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import imgCookie from "../../../assets/img/cookie.png";
import Cookies from "js-cookie";
import styled from "@emotion/styled";
//import { Link } from "react-router-dom";

const StyledCookieMonster = styled.img`
  position: fixed;
  bottom: 10.5em;
  height: 15em;

  @media (max-width: 1208px) {
    bottom: 11.5em;
  }

  @media (max-width: 797px) {
    bottom: 13.5em;
  }

  @media (max-width: 628px) {
    bottom: 14.5em;
  }

  @media (max-width: 512px) {
    bottom: 16.5em;
    height: 11em;
  }

  @media (max-width: 450px) {
    bottom: 17.5em;
    height: 10em;
  }

  @media (max-width: 400px) {
    bottom: 19.5em;
    height: 10em;
  }

  @media (max-width: 360px) {
    bottom: 20.5em;
    height: 10em;
  }

  @media (max-width: 338px) {
    display: none;
  }
`;

const StyledButton = styled.button`
  cursor: pointer;
  text-transform: uppercase;
  color: #fff;
  background-color: #007bff;
  margin-left: 1em;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.3),
    0 8px 10px 1px rgba(0, 0, 0, 0.024), 0 3px 14px 2px rgba(0, 0, 0, 0);

  @media (max-width: 797px) {
    font-size: 12px;
  }
`;

const styles = {
  h1: {
    color: "#369",
    fontFamily: "Arial, Helvetica, sans-serif",
    fontSize: "250%",
  },
  streetView: {
    height: 500,
  },
  grdp: {
    border: "solid 1px #ccc",
    marginBottom: 0,
    borderRadius: '25px',
    paddingTop: "2.5em",
    position: "fixed",
    bottom: 0,
    width: "100%",
    background: "#fff",
    color: "#000",
    zIndex: 99,
    h3: {
      marginBottom: 0,
      fontSize: "1.5em",
    },
  },

  buttons: {
    display: "flex",
    flexFlow: "row-reverse",
  },
  scroll: {
    minHeight: 1500,
  },
  linkAnchor: {
    textDecoration: "none",
    color: "#fff",
  },
};
const CookieConsent = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!Cookies.get("cookieConsent")) {
      setOpen(true);
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    Cookies.set("cookieConsent", "accepted", { expires: 365 }); // Guarda la cookie por 1 año
    setOpen(false);
  };

  return (
    <>
      {open && (
        <div style={styles.grdp} className="alert alert-warning">
          <StyledCookieMonster src={imgCookie} alt="cookie" />
          <h3>Valoramos tu privacidad.</h3>
          <p>
            Utilizamos cookies propias y de terceros para obtener datos
            estadísticos de la navegación de nuestros usuarios y mejorar
            nuestros servicios. páginas web. No se utilizarán las cookies para
            recoger información de carácter personal. Usted puede permitir su
            uso o rechazarlo, también puede cambiar su configuración siempre que
            lo desee. Si acepta o continúa navegando, consideramos que acepta su
            uso. Puede obtener más información{" "}
            <Link to="/help/Politica de privacidad">aquí</Link>.
          </p>
          <div className="dflex" style={styles.buttons}>
            <StyledButton type="submit" className="btn">
              <Link style={styles.linkAnchor} to="/help/Politica de privacidad">
                Política de privacidad
              </Link>
            </StyledButton>
            <StyledButton type="button" className="btn" onClick={handleClose}>
              Aceptar cookies
            </StyledButton>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
