import { types } from "../../types/types";

export const loginRequest = () => {
  return {
    type: types.loginRequest,
  };
};

export const loginSuccess = (user) => {
  return {
    type: types.loginSuccess,
    payload: {
      user,
    },
  };
};

export const loginFailure = (error) => {
  return {
    type: types.loginFailure,
    payload: {
      error,
    },
  };
};
export const logout = () => {
  return {
    type: types.logout,
  };
};

export const signUpRequest = () => {
  return {
    type: types.signUpRequest,
  };
};

export const signUpSuccess = (signUp) => {
  return {
    type: types.signUpSuccess,
    payload: {
      signUp,
    },
  };
};

export const signUpFailure = (error) => {
  return {
    type: types.signUpFailure,
    payload: {
      error,
    },
  };
};
