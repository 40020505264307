import axios from "axios";
import { latestReleasesFailure, latestReleasesRequest, latestReleasesSuccess } from "../actions/lastestReleasesActions/lastestReleasesActions";

import { baseURL } from "../constants/api";

export const getLatestReleases = async (dispatch) => {
 

  dispatch(latestReleasesRequest());
  try {
    const response = await axios.get(`${baseURL}/cache/latestReleases`);
    dispatch(latestReleasesSuccess(response.data));
    //return response.data;
  } catch (error) {
    dispatch(latestReleasesFailure(error.message));
    //return error ;
  }
};
