import { types } from "../../types/types";
const initialState = {
  searchItems: [],
  searchTotalItems: [],
  totalPages: null,
  totalElements: 0,
};
export const advancedSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.advancedSearchSuccess:
      return {
        ...state,
        searchItems: action.payload.advancedSearch,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    case types.advancedSearchFailure:
      return {
        ...state,
        ...action.payload,
      };
    case types.advancedSearchReset:
      return {
        ...state,
        searchItems: [],
        totalPages: null,
        totalElements: null,
      };
    case types.advancedSearchTotalSuccess:
      return {
        ...state,
        searchTotalItems: action.payload.advancedSearchTotal,
      };
    case types.advancedSearchTotalReset:
      return {
        ...state,
        searchTotalItems: [],
      };
    case types.advancedSearchTotalFailure:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
