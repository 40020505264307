import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../actions/authActions/authActions";
import imgPop from "../../assets/img/pop-cabecera.png";
import { getVerify } from "../../services/verifyService";

export const VerifyAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [count, setCount] = useState(10);
  const { code } = useParams();

  useEffect(() => {
    const timer = count > 0 && setInterval(() => setCount(count - 1), 1000);
    if (count === 0) {
      dispatch(logout());

      navigate("/", {
        replace: true,
      });
    }
    return () => {
      clearInterval(timer);
    };
  }, [count, dispatch, navigate]);

  useEffect(() => {
    getVerify(code);
  }, [code]);

  return (
    <div className="login__portada ">
      <div className="formBox container-fluid">
        <form className="text-center border border-light loginForm">
          <div className="loginFormImage">
            <a href="/">
              <img src={imgPop} alt="" className="img-fluid" />
            </a>
          </div>

          <i className="far fa-check-circle verified"></i>
          <h3>La cuenta ha sido verificada correctamente.</h3>
          <p>Seras rediccionado automáticamente en {count} segundos.</p>
        </form>
      </div>
    </div>
  );
};
