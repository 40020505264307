/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import imgPop from "../../../assets/img/pop-cabecera.png";
import {
  checkoutWithApitrue,
  checkoutWithtrue,
} from "../../../services/cartService";

export const PaymentConfirmation = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [count, setCount] = useState(10);
  useEffect(() => {
    const isStripeURL = searchParams.has("stripe");
    // console.log("cartItems", cartItems);
    // console.log(isStripeURL);
    if (isStripeURL && count === 10) {
      if (localStorage.getItem("codeMonday")) {
        dispatch(
          checkoutWithApitrue(cartItems, localStorage.getItem("codeMonday"))
        );
      } else {
        dispatch(checkoutWithtrue(cartItems));
      }
    }
    const timer = count > 0 && setInterval(() => setCount(count - 1), 1000);
    if (count === 0) {
      navigate("/", {
        replace: true,
      });
    }
    return () => {
      clearInterval(timer);
    };
  }, [count]);

  return (
    <div className="login__portada ">
      <div className="formBox container-fluid">
        <form className="text-center border border-light loginForm">
          <div className="loginFormImage">
            <a href="/">
              <img src={imgPop} alt="" className="img-fluid" />
            </a>
          </div>

          <i className="far fa-check-circle verified"></i>
          <h3>El pago se ha realizado correctamente.</h3>
          <p>Seras rediccionado automáticamente en {count} segundos.</p>
        </form>
      </div>
    </div>
  );
};
