import { types } from "../../types/types";

export const topSalesReducer = (state = {}, action) => {
    switch (action.type) {
      case types.topSalesSuccess:
        return {
          ...action.payload,
        };
      case types.topSalesFailure:
        return {
          ...action.payload,
        };
  
      default:
        return state;
    }
  };
  