import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import euImg from "../assets/logos/Fondo_EU_Logo.png";
import euImg2 from "../assets/logos/Fondo_EU_Logo2.jpg";

const FondoEU = () => {
  return (
    <Box m={1} sx={{}}>
      <Grid container justifyContent={"center"}>
        <Grid item xs={5} md={4}>
          <img src={euImg} alt="eu" width={200} height={200} />
        </Grid>
        <Grid item xs={5} md={4} ml={4}>
          <img src={euImg2} alt="eu" width={175} height={175} />
        </Grid>
        <Grid item m={1} xs={12}>
          <Typography variant="h6">
            Gemma Ramos Melgar ha sido beneficiaria del Fondo Europeo de
            Desarrollo Regional cuyo objetivo es mejorar el uso y la calidad de
            las tecnologías de la información y de las comunicaciones y el
            acceso a las mismas y gracias al que ha Presencia web a través de
            página propia y Dinamización de redes sociales para impulsar la
            incorporación de las TIC a la actividad habitual de las pymes. Desde
            Octubre a Diciembre del 2022. Para ello ha contado con el apoyo del
            programa TICCámaras de la Cámara de Comercio del Campo de Gibraltar.
            Una manera de hacer europa.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FondoEU;
