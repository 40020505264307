import React, { useEffect, useState } from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";
import banner1 from "../assets/banners/banner_01.jpeg";
import banner2 from "../assets/banners/banner_02.png";
import imgVideo from "../assets/logos/video.png";
import videoComprar from "../assets/videos/comprar.mp4";
import videoVender from "../assets/videos/vender.mp4";
import portadaVideoComprar from "../assets/videos/comprar.jpeg";
import portadaVideoVender from "../assets/videos/vender.jpeg";
import TableSmall from "../components/auth/Common/TableSmall";
import FunkoCard from "../components/funkocard/FunkoCard";
import { getLatestReleases } from "../services/latestReleasesService";
import { getTopSales } from "../services/topSalesService";
import { getTopSearch } from "../services/topSearchService";

export const Home = () => {
  const funkos = useSelector(
    (state) => state?.latestReleasesReducer?.latestReleases
  );
  const funkosTopSales = useSelector(
    (state) => state?.topSalesReducer?.topSales
  );
  const funkosTopSearch = useSelector(
    (state) => state?.topSearchReducer?.topSearch
  );
  const dispatch = useDispatch();

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const settings = {
    centerMode:
      windowSize?.width < 768
        ? false
        : windowSize?.width <= 1200 && windowSize?.width >= 768
        ? false
        : true,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      windowSize?.width <= 1200 && windowSize?.width >= 768
        ? 2
        : windowSize?.width < 768
        ? 1
        : 3,
    slidesToScroll: 1,
    className: "slides",
  };

  useEffect(() => {
    getLatestReleases(dispatch);
    getTopSales(dispatch);
    getTopSearch(dispatch);

    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);

  return (
    <>
      <div className={windowSize?.width > 768 ? "home__carousel" : ""}>
        <Carousel autoPlay showThumbs={false}>
          <div>
            <img alt="" src={banner1} />
          </div>
          <div>
            <img alt="" src={banner2} />
          </div>
        </Carousel>
      </div>
      <div className="container">
        <div className="delimiter mb-5">
          <div className="delimiterRounder">
            <div className="vDivider"></div>
          </div>
          <div className="textBetweenDelimiter">
            <span>¡NOVEDADES!</span>
          </div>
          <div className="delimiterRounder">
            <div className="vDivider"></div>
          </div>
        </div>
        <Slider {...settings}>
          {funkos &&
            funkos.map((funko, index) => {
              return <FunkoCard key={funko.code + index} funko={funko} />;
            })}
        </Slider>
        <div className="delimiter mt-5">
          <div className="delimiterRounder">
            <div className="vDivider"></div>
          </div>
          <div className="textBetweenDelimiter">
            <span>Los más populares</span>
          </div>
          <div className="delimiterRounder">
            <div className="vDivider"></div>
          </div>
        </div>
        <div className="container d-flex justify-content-center">
          <div className="row ">
            <div className="col">
              <TableSmall funkosTopSales={funkosTopSales} />
            </div>
            <div className="col">
              <TableSmall funkosTopSales={funkosTopSearch} />
            </div>
          </div>
        </div>

        <div className="home__video-container">
          <div className="titleHeader">
            <h1>
              <BsFillInfoCircleFill className="home__video-icon-info" /> ¿Como
              usar Verypops?
            </h1>
            <p>
              {" "}
              Verypops, el mercado de funkos que permite comprar/vender
              productos de una manera fácil{" "}
            </p>
          </div>
          <div className="embeddedBox">
            <div className="v1">
              <p>¿Como comprar?</p>
              <div className="v1Embedded">
                <video
                  src={videoComprar}
                  poster={portadaVideoComprar}
                  controls
                  width="200"
                  height="200"
                >
                  Tu navegador no soporta la reproducción del video.
                </video>
              </div>
            </div>
            <div className="imgEmbedded">
              <p>&nbsp;</p>
              <img src={imgVideo} alt="imgVideo" />
            </div>
            <div className="v2">
              <p>¿Como vender?</p>
              <div className="v2Embedded">
                <video
                  src={videoVender}
                  poster={portadaVideoVender}
                  controls
                  width="200"
                  height="200"
                >
                  Tu navegador no soporta la reproducción del video.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
