export const API = {
  login: "/login",
};

export const METHOD = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
};

export const baseURL = process.env.REACT_APP_API_URL;
export const imgUrl = "https://www.verypops.com/";
