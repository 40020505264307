import React from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import { updatePassword } from "../../services/myAccountService";
// Acciones de Redux (Agrega tus propias acciones aquí)
// import { updatePassword } from '../redux/actions';

const validationSchema = yup.object({
  currentPassword: yup
    .string("Ingresa tu contraseña actual")
    .required("La contraseña actual es obligatoria"),
  newPassword: yup
    .string("Ingresa tu nueva contraseña")
    .required("La nueva contraseña es obligatoria")
    .min(8, "La contraseña debe tener al menos 8 caracteres"),
  repeatPassword: yup
    .string("Repite tu nueva contraseña")
    .oneOf([yup.ref("newPassword"), null], "Las contraseñas no coinciden")
    .required("Repetir contraseña es obligatorio"),
});

const encodeBase64 = (data) => {
  return btoa(data);
};

const ChangePasswordModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      repeatPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const encodedCurrentPassword = encodeBase64(values.currentPassword);
      const encodedNewPassword = encodeBase64(values.newPassword);

      // const encodedValues = {
      //   currentPassword: encodedCurrentPassword,
      //   newPassword: encodedNewPassword,
      // };

      dispatch(updatePassword(encodedNewPassword, encodedCurrentPassword));
      handleClose();
    },
  });

  return (
    <Dialog
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 8,
          border: "solid 2px #F6B036",
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Cambiar contraseña</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            id="currentPassword"
            label="Contraseña actual"
            type="password"
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.currentPassword &&
              Boolean(formik.errors.currentPassword)
            }
            helperText={
              formik.touched.currentPassword && formik.errors.currentPassword
            }
          />
          <TextField
            fullWidth
            margin="normal"
            id="newPassword"
            label="Nueva contraseña"
            type="password"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            helperText={formik.touched.newPassword && formik.errors.newPassword}
          />
          <TextField
            fullWidth
            margin="normal"
            id="repeatPassword"
            label="Repetir contraseña"
            type="password"
            value={formik.values.repeatPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.repeatPassword &&
              Boolean(formik.errors.repeatPassword)
            }
            helperText={
              formik.touched.repeatPassword && formik.errors.repeatPassword
            }
          />
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button type="submit">Guardar cambios</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordModal;
