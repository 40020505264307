import { sendAlert } from "../actions/alertActions/alertActions";
import { requestFunkoFailure, requestFunkoRequest, requestFunkoSuccess } from "../actions/requestFunkoActions/requestFunckoActions";
import { baseURL } from "../constants/api";
import axiosInstance from "../interceptors/axiosInstance";

export const requestFunko = (data) => async (dispatch) => {
  dispatch(requestFunkoRequest());
  try {
    const response = await axiosInstance.post(`${baseURL}/funko/request`, data);
    dispatch(
        sendAlert({
          type: "success",
          message: "Solicitud enviada!",
        })
      );
    dispatch(requestFunkoSuccess(response.data));
    //return response.data;
  } catch (error) {
    dispatch(
        sendAlert({
          type: "error",
          message: error.message,
        })
      );
    dispatch(requestFunkoFailure(error.message));
    //return error ;
  }
};
