import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  InputLabel,
} from "@mui/material";
import imgFunko from "../assets/img/requesFunkoExample.png";
import styled from "@emotion/styled";
import { requestFunko } from "../services/requestFunkoServices";

const FormContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  height: auto;
`;

const validationSchema = Yup.object().shape({
  numero: Yup.number().required("Este campo es obligatorio"),
  nombre: Yup.string().required("Este campo es obligatorio"),
  licencia: Yup.string().required("Este campo es obligatorio"),
  serie: Yup.string().required("Este campo es obligatorio"),
  edicion: Yup.string().required("Este campo es obligatorio"),
  imagen1: Yup.mixed().required("Este campo es obligatorio"),
  imagen2: Yup.mixed().required("Este campo es obligatorio"),
});

const RequestFunko = () => {
  const dispatch = useDispatch();
  const [image1Preview, setImage1Preview] = useState(null);
  const [image2Preview, setImage2Preview] = useState(null);

  const formik = useFormik({
    initialValues: {
      numero: "",
      nombre: "",
      licencia: "",
      serie: "",
      edicion: "",
      imagen1: "",
      imagen2: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const newRequest = {
        number: values.numero,
        name: values.nombre,
        license: values.licencia,
        series: values.serie,
        edition: values.edicion,
        imgFile: [
          values.imagen1.split("base64,")[1],
          values.imagen2.split("base64,")[1],
        ],
      };
      dispatch(requestFunko(newRequest));
    },
  });

  const handleImageChange = (event, setImagePreview) => {
    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
        formik.setFieldValue(event.target.name, e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <Box margin="0 auto" maxWidth="95%">
      <Grid container spacing={3} mt={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5">
            ¿No encuetras el funko que quieres?
          </Typography>
          <Typography variant="h7">
            Rellena el siguiente formulario siguiendo la guía
          </Typography>
          {/* Reemplaza 'imagen_ejemplo.jpg' con la URL de la imagen que quieras mostrar */}
          <img
            src={imgFunko}
            alt="Imagen de ejemplo"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormContainer
            component="form"
            onSubmit={formik.handleSubmit}
            maxWidth="sm"
          >
            <TextField
              fullWidth
              id="numero"
              name="numero"
              label="Nº"
              value={formik.values.numero}
              onChange={formik.handleChange}
              error={formik.touched.numero && Boolean(formik.errors.numero)}
              helperText={formik.touched.numero && formik.errors.numero}
            />
            <TextField
              fullWidth
              id="nombre"
              name="nombre"
              label="Nombre"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={formik.touched.nombre && Boolean(formik.errors.nombre)}
              helperText={formik.touched.nombre && formik.errors.nombre}
            />
            <TextField
              fullWidth
              id="licencia"
              name="licencia"
              label="Licencia"
              value={formik.values.licencia}
              onChange={formik.handleChange}
              error={formik.touched.licencia && Boolean(formik.errors.licencia)}
              helperText={formik.touched.licencia && formik.errors.licencia}
            />
            <TextField
              fullWidth
              id="serie"
              name="serie"
              label="Serie"
              value={formik.values.serie}
              onChange={formik.handleChange}
              error={formik.touched.serie && Boolean(formik.errors.serie)}
              helperText={formik.touched.serie && formik.errors.serie}
            />
            <TextField
              fullWidth
              id="edicion"
              name="edicion"
              label="Edición"
              value={formik.values.edicion}
              onChange={formik.handleChange}
              error={formik.touched.edicion && Boolean(formik.errors.edicion)}
              helperText={formik.touched.edicion && formik.errors.edicion}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box mb={2}>
                  <InputLabel htmlFor="imagen1">Imagen 1</InputLabel>
                  <input
                    id="imagen1"
                    name="imagen1"
                    type="file"
                    onChange={(event) =>
                      handleImageChange(event, setImage1Preview)
                    }
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="contained"
                    component="label"
                    htmlFor="imagen1"
                  >
                    Subir imagen 1
                  </Button>
                  {formik.touched.imagen1 && formik.errors.imagen1 && (
                    <Typography color="error" variant="body2">
                      {formik.errors.imagen1}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mb={2}>
                  <InputLabel htmlFor="imagen2">Imagen 2</InputLabel>
                  <input
                    id="imagen2"
                    name="imagen2"
                    type="file"
                    onChange={(event) =>
                      handleImageChange(event, setImage2Preview)
                    }
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="contained"
                    component="label"
                    htmlFor="imagen2"
                  >
                    Subir imagen 2
                  </Button>
                  {formik.touched.imagen2 && formik.errors.imagen2 && (
                    <Typography color="error" variant="body2">
                      {formik.errors.imagen2}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              {image1Preview && (
                <Grid item xs={12} sm={6}>
                  <Box mb={2}>
                    <ImagePreview
                      src={image1Preview}
                      alt="Previsualización de imagen 1"
                    />
                  </Box>
                </Grid>
              )}
              {image2Preview && (
                <Grid item xs={12} sm={6}>
                  <Box mb={2}>
                    <ImagePreview
                      src={image2Preview}
                      alt="Previsualización de imagen 2"
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Solicitar Funko
            </Button>
          </FormContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RequestFunko;
