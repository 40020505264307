import { types } from "../../types/types";

export const funkosOnSaleReducer = (state = {}, action) => {
    switch (action.type) {
      case types.funkosOnSaleSuccess:
        return {
          ...action.payload,
        };
      case types.funkosOnSaleFailure:
        return {
          ...action.payload,
        };
  
      default:
        return state;
    }
  };
  